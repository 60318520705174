import React, { useEffect } from "react";
import Layout from './Layout'
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getMe } from "../features/authSlice";
import DataPersonListPegawai from "../components/DataPersonListPegawai";

const DataPersonAllPegawai = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isError, pegawai } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getMe());
  }, [dispatch]);

  useEffect(() => {
    if (isError) {
      navigate("/");
    }
    //pages ini hanya admin yang bisa ngebuka nya
    // if (pegawai && pegawai.strRole!=="admin") {
    //   //redirect ke dashboard atau pages baru
    //   navigate("/dashboard")
    // }
  }, [isError, pegawai, navigate]);


  return (
    <Layout>
        <DataPersonListPegawai/>
    </Layout>
  )
}

export default DataPersonAllPegawai