import axios from "axios";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Form } from "react-bootstrap";
import { useEffect } from "react";
import { useSelector } from "react-redux";

import "./Style/globalValidasiEror.scss";
import Url from "../global";

const FormEditRiwayatPendidikan = () => {
  const [tingkatPendidikan, setTingkatPendidikan] = useState("");
  const [nomorIjazah, setNomorIjazah] = useState("");
  const [tanggalLulus, setTglLulus] = useState("");
  const [tempatSekolah, setTempatSekolah] = useState("");
  const [jurusan, setJurusan] = useState("");
  const [status, setStatus] = useState("");
  const [msg, setMsg] = useState("");
  const navigate = useNavigate();
  const [sertifikasi, setSertifikasi] = useState("");
  const [file, setFile] = useState("");
  const [ setFileUrl] = useState("");
  const [fileSertifikasi, setFileSertifikasi] = useState("");
  const [ setFileUrlSertifikasi] = useState("");
  const [setError] = useState(null);
  const [komentar, setKomentar] = useState("");
  const { pegawai } = useSelector((state) => state.auth);
  const { id } = useParams();

  const PdfViewer = ({ file }) => {
    return (
      <div>
        {file ? (
          <embed
            src={file}
            type="application/pdf"
            width="80%"
            height="600px"
          />
        ) : (
          <p>No PDF file uploaded</p>
        )}
      </div>
    );
  };

  const handleChange = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile && selectedFile.type === "application/pdf") {
      setFile(selectedFile);
      setFileUrl(URL.createObjectURL(selectedFile));
    } else {
      setFile(null);
      setError("File harus berupa PDF");
    }
  };

  const handleChangeSertifikasi = (e) => {
    const selectedFileSertifikasi = e.target.files[0];

    if (
      selectedFileSertifikasi &&
      selectedFileSertifikasi.type === "application/pdf"
    ) {
      setFileSertifikasi(selectedFileSertifikasi);
      setFileUrlSertifikasi(URL.createObjectURL(selectedFileSertifikasi));
    } else {
      setFileSertifikasi(null);
      setError("File harus berupa PDF");
    }
  };

  const PdfViewerSertifikasi = ({ fileSertifikasi }) => {
    return (
      <div>
        {fileSertifikasi ? (
          <embed
            src={fileSertifikasi}
            type="application/pdf"
            width="80%"
            height="600px"
          />
        ) : (
          <p>No PDF file uploaded</p>
        )}
      </div>
    );
  };

  useEffect(() => {
    const getRiwayatPendidikanById = async () => {
      try {
        const response = await axios.get(`${Url}/riwayat_pendidikans/${id}`);
        setTingkatPendidikan(response.data.strTingkatPendidikan);
        setNomorIjazah(response.data.strNoIjazah);
        setTglLulus(response.data.dateTglLulus);
        setTempatSekolah(response.data.strTempatSekolah);
        setJurusan(response.data.strJurusan);
        setSertifikasi(response.data.strSertifikasi);
        setStatus(response.data.strStatus);
        setKomentar(response.data.strKomentar);
        setFile(response.data.url);
        setFileSertifikasi(response.data.urlSertifikasi);
      } catch (error) {
        if (error.response) {
          setMsg(error.response.data.msg);
        }
      }
    };
    getRiwayatPendidikanById();
  }, [id]);

  const updateRiwayatPendidikan = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("strTingkatPendidikan", tingkatPendidikan);
      formData.append("strNoIjazah", nomorIjazah);
      formData.append("dateTglLulus", tanggalLulus);
      formData.append("strTempatSekolah", tempatSekolah);
      formData.append("strJurusan", jurusan);
      formData.append("strStatus", status);
      formData.append("url", file);
      formData.append("urlSertifikasi", fileSertifikasi);
      formData.append("strKomentar", komentar);
      formData.append("strSertifikasi", sertifikasi);

      await axios.patch(`${Url}/riwayat_pendidikans/${id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      navigate("/riwayat-pendidikan");
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };

  return (
    <div>
      <h1 className="title">Riwayat Pendidikan</h1>
      <h2 className="subtitle">Edit Riwayat Pendidikan</h2>

      <div className="card is-shadowless">
        <div className="card-content">
          <div className="content">
            <form
              className="box"
              onSubmit={updateRiwayatPendidikan}
              encType="multipart/form-data"
            >
              <p className="has-text-centered">{msg}</p>

              <div className="field">
                <label className="label">Tingkat Pendidikan</label>
                <div className="control">
                  <div className="select is-fullwidth">
                    <select
                      className="dropdown"
                      value={tingkatPendidikan}
                      onChange={(e) => setTingkatPendidikan(e.target.value)}
                      required
                    >
                      <option>------Pilih------</option>
                      <option>SMP</option>
                      <option>SMA</option>
                      <option>D1</option>
                      <option>D2</option>
                      <option>D3</option>
                      <option>D4</option>
                      <option>S1</option>
                      <option>S1/Profesi</option>
                      <option>S1</option>
                      <option>S2</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="field">
                <label className="label">Nomor Ijazah</label>
                <div className="control">
                  <input
                    className="input"
                    placeholder="Nomor Ijazah"
                    value={nomorIjazah}
                    onChange={(e) => setNomorIjazah(e.target.value)}
                  />
                </div>
              </div>

              <div className="field">
                <label className="label">Tanggal Lulus</label>
                <div className="control">
                  <Form.Group controlId="duedate" className="dtp">
                    <Form.Control
                      type="date"
                      name="duedate"
                      placeholder="Tanggal Lahir"
                      value={tanggalLulus}
                      onChange={(e) => setTglLulus(e.target.value)}
                    />
                  </Form.Group>
                </div>
              </div>

              <div className="field">
                <label className="label">Nama Sekolah</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    placeholder="Nama Sekolah"
                    value={tempatSekolah}
                    onChange={(e) => setTempatSekolah(e.target.value)}
                  />
                </div>
              </div>

              <div className="field">
                <label className="label">Jurusan</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    placeholder="Jurusan"
                    value={jurusan}
                    onChange={(e) => setJurusan(e.target.value)}
                  />
                </div>
              </div>

              <div className="field">
                <label className="label">Status Pendidikan</label>
                <div className="control">
                  <div className="select is-fullwidth">
                    {pegawai && pegawai.strRole === "user" && (
                      <select
                        className="dropdown"
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                        disabled
                      >
                        <option>Pengajuan Sertifikat Pendidikan</option>
                        <option>Disetujui Kabid Kepegawaian</option>
                        <option>Ditolak Kabid Kepegawaian</option>
                      </select>
                    )}

                    {pegawai &&
                      (pegawai.strRole === "kabid kepegawaian" ||
                        pegawai.strRole === "admin") && (
                        <select
                          className="dropdown"
                          value={status}
                          onChange={(e) => setStatus(e.target.value)}
                        >
                          <option>Pengajuan Sertifikat Pendidikan</option>
                          <option>Disetujui Kabid Kepegawaian</option>
                          <option>Ditolak Kabid Kepegawaian</option>
                        </select>
                      )}
                  </div>
                </div>
              </div>

              <div className="field">
                <label className="label">Sertifikasi</label>
                <div className="control">
                  <div className="select is-fullwidth">
                    <select
                      className="dropdown"
                      value={sertifikasi}
                      onChange={(e) => setSertifikasi(e.target.value)}
                      required
                    >
                      <option>------Pilih------</option>
                      <option>Sudah Sertifikasi</option>
                      <option>Belum Sertifikasi</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="field">
                <label className="label">Komentar</label>
                <div className="control">
                  {pegawai &&
                    (pegawai.strRole === "kabid kepegawaian" ||
                      pegawai.strRole === "admin") && (
                      <input
                        type="text"
                        className="input"
                        placeholder="Komentar"
                        value={komentar}
                        onChange={(e) => setKomentar(e.target.value)}
                      />
                    )}
                  {pegawai && pegawai.strRole === "user" && (
                    <input
                      type="text"
                      className="input"
                      placeholder="Komentar"
                      value={komentar}
                      onChange={(e) => setKomentar(e.target.value)}
                      disabled
                    />
                  )}
                </div>
              </div>
              <div className="field">
                <label className="label">Ijazah dan Transkrip Nilai</label>
                <div className="control">
                  <div className="file">
                    <label className="file-label">
                      <input
                        type="file"
                        accept="application/pdf"
                        onChange={handleChange}
                        name="file"
                      />
                      {/* <button type="submit">Upload</button> */}
                    </label>
                    {setError && <p className="error">{setError}</p>}
                  </div>
                  <br />
                  <PdfViewer file={file} />
                </div>
              </div>
              {sertifikasi === "Sudah Sertifikasi" && (
              <div className="field">
                <label className="label">Sertifikasi</label>
                <div className="control">
                  <div className="file">
                    <label className="file-label">
                      <input
                        type="file"
                        accept="application/pdf"
                        onChange={handleChangeSertifikasi}
                        name="file"
                      />
                      {/* <button type="submit">Upload</button> */}
                    </label>
                  </div>
                  <br />
                  <PdfViewerSertifikasi
                    fileSertifikasi={fileSertifikasi}
                  />
                </div>
              </div>
              )}
              <br />
              <div className="field">
                <div className="control">
                  <button type="submit" className="button is-success">
                    Update
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormEditRiwayatPendidikan;
