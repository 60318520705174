import axios from "axios";
import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import "./Style/globalValidasiEror.scss";
import Url from "../global";

const FormAddRaport = () => {
  const [raport, setRaport] = useState("");
  const [nilaiRaport, setnilaiRaport] = useState("");
  const [gradeRaport, setGradeRaport] = useState("");
  const [tglSK, setTglSK] = useState("");
  const [status, setStatus] = useState("");
  const [msg, setMsg] = useState("");
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const [fileUrl, setFileUrl] = useState(null);

  const [setError] = useState(null);

  const {
    register,
    reset,
    trigger,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const PdfViewer = ({ fileUrl }) => {
    return (
      <div>
        {fileUrl ? (
          <embed
            src={fileUrl}
            type="application/pdf"
            width="70%"
            height="600px"
          />
        ) : (
          <p>No PDF file uploaded</p>
        )}
      </div>
    );
  };

  const handleChange = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile && selectedFile.type === "application/pdf") {
      setFile(selectedFile);
      setFileUrl(URL.createObjectURL(selectedFile));
    } else {
      setFile(null);
      setError("File harus berupa PDF");
    }
  };

  const saveRiwayatRaport = async (e) => {
    // e.preventDefault();
    if (file) {
      const formData = new FormData();
      formData.append("strSemester", raport);
      formData.append("intNilaiRaport", nilaiRaport);
      formData.append("strGradeRaport", gradeRaport);
      formData.append("dateTglSK", tglSK);
      formData.append("strStatus", status);
      formData.append("pdfFile", file);

      for (let i = 0; i < file.length; i++) {
        formData.append("files", file[i]);
      }

      try {
        await axios.post(`${Url}/riwayat_raports`, formData, {
          headers: {
            "Content-type": "multipart/form-data",
          },
        });
        navigate("/riwayat-raport");
        reset();
      } catch (error) {
        if (error.response) {
          setMsg(error.response.data.msg);
        }
      }
    }
  };
  return (
    <div>
      <h1 className="title">Riwayat Raport per Semester</h1>
      <h2 className="subtitle">Add New Riwayat Raport</h2>

      <div className="card is-shadowless">
        <div className="card-content">
          <div className="content">
            <form className="box" onSubmit={handleSubmit(saveRiwayatRaport)}>
              <p className="has-text-centered">{msg}</p>

              <div className="field">
                <label className="label">Semester</label>
                <div className="control">
                  <div className="select is-fullwidth">
                    <select
                      className="dropdown"
                      value={raport}
                      onChange={(e) => setRaport(e.target.value)}
                      required
                      // disabled="true"
                    >
                      <option>--pilih--</option>
                      <option>Semester 1</option>
                      <option>Semester 2</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="field">
                <label className="label">Nilai Raport</label>
                <div className="control">
                  <input
                    type="number"
                    className={`input ${errors.nilaiRaport && "invalid"}`}
                    {...register("nilaiRaport", {
                      required: {
                        value: true,
                        message: "Nilai Raport Wajib Di isi!",
                      },
                    })}
                    onKeyUp={() => {
                      trigger("nilaiRaport");
                    }}
                    placeholder="Masukan Nilai Raport berdasarkan sertifikat Raport"
                    value={nilaiRaport}
                    onChange={(e) => setnilaiRaport(e.target.value)}
                  />
                  {errors.semester && (
                    <small className="allValidasiError">
                      {errors.semester.message}
                    </small>
                  )}
                </div>
              </div>

              <div className="field">
                <label className="label">GradeRaport</label>
                <div className="control">
                  <div className="select is-fullwidth">
                    <select
                      className="dropdown"
                      value={gradeRaport}
                      onChange={(e) => setGradeRaport(e.target.value)}
                      required
                      // disabled="true"
                    >
                      <option>--pilih--</option>
                      <option>A</option>
                      <option>B</option>
                      <option>C</option>
                      <option>D</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="field">
                <label className="label">Tanggal Raport</label>
                <div className="control">
                  <Form.Group controlId="duedate" className="dtp">
                    <Form.Control
                      type="date"
                      name="duedate"
                      placeholder="Tanggal Raport"
                      value={tglSK}
                      onChange={(e) => setTglSK(e.target.value)}
                    />
                  </Form.Group>
                </div>
              </div>

              <div className="field">
                <label className="label">Status Raport</label>
                <div className="control">
                  <div className="select is-fullwidth">
                    <select
                      className="dropdown"
                      value={status}
                      onChange={(e) => setStatus(e.target.value)}
                      required
                      disabled="true"
                    >
                      <option>Pengajuan Raport</option>
                      <option>Disetujui Kabid Kepegawaian</option>
                      <option>Ditolak Kabid Kepegawaian</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="field">
                <label className="label">Upload Raport</label>
                <div className="control">
                  <div className="file">
                    <label className="file-label">
                      <input
                        type="file"
                        accept="application/pdf"
                        onChange={handleChange}
                      />
                      {/* <button type="submit">Upload</button> */}
                    </label>
                  </div>
                  <br />
                  <PdfViewer fileUrl={fileUrl} />
                </div>
              </div>
              <br />
              <br />
              <div className="field">
                <div className="control">
                  <button type="submit" className="button is-success">
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormAddRaport;
