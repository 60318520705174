import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Url from "../global";

const initialState = {
    pegawai : null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: ""
}




//untuk eror 
export const LoginPegawai = createAsyncThunk("pegawai/LoginPegawai", async(pegawai, thunkAPI) => {
    try {
        const response = await axios.post( `${Url}/`, {
            strEmail : pegawai.strEmail,
            strPassword : pegawai.strPassword
        },{ credentials: "include"} );
        return response.data;
    } catch (error) {
        if(error.response){
            const message = error.response.data.msg;
            return thunkAPI.rejectWithValue(message);
        }
    }
})

export const LogOut = createAsyncThunk("pegawai/LogOut", async() => {
    await axios.delete(`${Url}/logout`);
}, { credentials: "include"});

//untuk tidak bisa akses dashboard
export const getMe = createAsyncThunk("pegawai/getMe", async(_, thunkAPI) => {
    try {
        const response = await axios.get(`${Url}/me`, { credentials: "include"});
        return response.data;
    } catch (error) {
        if(error.response){
            const message = error.response.data.msg;
            return thunkAPI.rejectWithValue(message);
        }
    }
});

export const authSlice = createSlice({
    name : "auth",
    initialState,
    reducers : {
        reset : (state) => initialState
    },
    extraReducers: (builder) =>{
        builder.addCase(LoginPegawai.pending, (state) =>{
            state.isLoading = true;
        });
        builder.addCase(LoginPegawai.fulfilled, (state, action) =>{
            state.isLoading = false;
            state.isSuccess = true;
            state.pegawai = action.payload;
        });
        
        builder.addCase(LoginPegawai.rejected, (state, action) =>{
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
        })

        // Get User Login
        builder.addCase(getMe.pending, (state) =>{
            state.isLoading = true;
        });
        builder.addCase(getMe.fulfilled, (state, action) =>{
            state.isLoading = false;
            state.isSuccess = true;
            state.pegawai = action.payload;
        });
        builder.addCase(getMe.rejected, (state, action) =>{
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
        })
    }
})
 
export const {reset} = authSlice.actions;
export default authSlice.reducer;