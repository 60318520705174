// strDokumen : dokumen
import axios from "axios";
import React, { useState } from "react";
//import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Url from "../global";

const FormAddPegawai = () => {
  const [nama, setNama] = useState("");
  const [msg, setMsg] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confPassword, setConfPassword] = useState("");
  const [role, setRole] = useState("");
  const [unit, setUnit] = useState("");
  

  const navigate = useNavigate();

  
  
  const savePegawai = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${Url}/pegawai `, {
        strNama: nama,
        strEmail: email,
        strPassword: password,
        confPassword: confPassword,
        strRole: role,
        strUnit: unit,
        //strStatus: status,
      }, { credentials: "include"});
      navigate("/pegawais");
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };

  return (
    <div>
      <h1 className="title">Pegawai</h1>
      <h2 className="subtitle">Add New Pegawai</h2>

      <div className="card is-shadowless">
        <div className="card-content">
          <div className="content">
            <form className="box" onSubmit={savePegawai}>
              <p className="has-text-centered">{msg}</p>
              <div className="field">
                <label className="label">Nama Pegawai</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    placeholder="Nama Pegawai"
                    value={nama}
                    onChange={(e) => setNama(e.target.value)}
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Email</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Password</label>
                <div className="control">
                  <input
                    type="password"
                    className="input"
                    placeholder="*****"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Confirm Password</label>
                <div className="control">
                  <input
                    type="password"
                    className="input"
                    placeholder="*****"
                    value={confPassword}
                    onChange={(e) => setConfPassword(e.target.value)}
                  />
                </div>
              </div>
              {/* {pegawai && (pegawai.strRole === "kepsek" || pegawai.strRole === "user")  && ( */}
              <div className="field">
                <label className="label">Unit</label>
                <div className="control">
                  <div className="select is-fullwidth">
                    <select
                      className="dropdown"
                      value={unit}
                      onChange={(e) => setUnit(e.target.value)}
                      required
                    >
                      <option>------Pilih------</option>
                      <option>Manajemen</option>
                      <option>PAUD IT Al Hasanah 1</option>
                      <option>PAUD IT Al Hasanah 2</option>
                      <option>SDIT Al Hasanah 1</option>
                      <option>SDIT Al Hasanah 2</option>
                      <option>SMPI Al Hasanah</option>
                      <option>SMAIT Al Hasanah</option>
                      <option>SMPI Al Hasanah</option>
                      <option>Pondok Pesantren</option>
                      {/* <option>MTS Al Hasanah</option> */}
                    </select>
                  </div>
                </div>
              </div>
              {/* )} */}
              <div className="field">
                <label className="label">Role</label>
                <div className="control">
                  <div className="select is-fullwidth">
                    <select
                      className="dropdown"
                      value={role}
                      onChange={(e) => setRole(e.target.value)}
                      required
                    >
                      <option>--Pilih--</option>
                      <option>admin</option>
                      <option>kabid kepegawaian</option>
                      <option>user</option>
                      <option>staff TU</option>
                      <option>kepsek</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="field">
                <div className="control">
                  <button className="button is-success">Save</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormAddPegawai;
