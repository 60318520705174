import React, { useState, useEffect } from "react";
// import { useSelector } from "react-redux";
import axios from "axios";
import Url from "../global";
import "./future.scss";

export default function FeaturedInfo() {
  const [pegawai1, setPegawais1] = useState([]);
  const [pegawai2, setPegawais2] = useState([]);
  const [pegawai3, setPegawais3] = useState([]);
  const [pegawai4, setPegawais4] = useState([]);
  const [pegawai5, setPegawais5] = useState([]);
  // const [pegawai6, setPegawais6] = useState([]);
  const [pegawai7, setPegawais7] = useState([]);
  const [pegawai8, setPegawais8] = useState([]);
  const [pegawai9, setPegawais9] = useState([]);

  const current = new Date();
  const date = `${current.getDate()}/${
    current.getMonth() + 1
  }/${current.getFullYear()}`;

  useEffect(() => {
    getPegawais1();
    getPegawais2();
    getPegawais3();
    getPegawais4();
    getPegawais5();
    // getPegawais6();
    getPegawais7();
    getPegawais8();
    getPegawais9();
  }, []);

  const getPegawais1 = async () => {
    const response = await axios.get(`${Url}/dashboard/1`, {
      credentials: "include",
    });
    setPegawais1(response.data);
  };

  const getPegawais2 = async () => {
    const response = await axios.get(`${Url}/dashboard/2`, {
      credentials: "include",
    });
    setPegawais2(response.data);
  };

  const getPegawais3 = async () => {
    const response = await axios.get(`${Url}/dashboard/3`, {
      credentials: "include",
    });
    setPegawais3(response.data);
  };

  const getPegawais4 = async () => {
    const response = await axios.get(`${Url}/dashboard/4`, {
      credentials: "include",
    });
    setPegawais4(response.data);
  };

  const getPegawais5 = async () => {
    const response = await axios.get(`${Url}/dashboard/5`, {
      credentials: "include",
    });
    setPegawais5(response.data);
  };

  // const getPegawais6 = async () => {
  //   const response = await axios.get(`${Url}/dashboard/6`, {
  //     credentials: "include",
  //   });
  //   setPegawais6(response.data);
  // };

  const getPegawais7 = async () => {
    const response = await axios.get(`${Url}/dashboard/7`, {
      credentials: "include",
    });
    setPegawais7(response.data);
  };

  const getPegawais8 = async () => {
    const response = await axios.get(`${Url}/dashboard/8`, {
      credentials: "include",
    });
    setPegawais8(response.data);
  };

  const getPegawais9 = async () => {
    const response = await axios.get(`${Url}/dashboard/9`, {
      credentials: "include",
    });
    setPegawais9(response.data);
  };

  return (
    <div className="featured">
      <div className="featuredItem">
        <span className="featuredTitle">Pegawai Managemen</span>
        <div className="featuredMoneyContainer">
          <span className="featuredMoney">{pegawai9}</span>
        </div>
        <span className="featuredSub">Berdasarkan Bulan Terakhir {date}</span>
      </div>

      <div className="featuredItem">
        <span className="featuredTitle">Pegawai PAUD IT Al Hasanah 1</span>
        <div className="featuredMoneyContainer">
          <span className="featuredMoney">{pegawai1}</span>
        </div>
        <span className="featuredSub">Berdasarkan Bulan Terakhir {date}</span>
      </div>

      <div className="featuredItem">
        <span className="featuredTitle">Pegawai PAUD IT Al Hasanah 2</span>
        <div className="featuredMoneyContainer">
          <span className="featuredMoney">{pegawai2}</span>
        </div>
        <span className="featuredSub">Berdasarkan Bulan Terakhir {date}</span>
      </div>

      <div className="featuredItem">
        <span className="featuredTitle">Pegawai SDIT Al Hasanah 1</span>
        <div className="featuredMoneyContainer">
          <span className="featuredMoney">{pegawai3}</span>
        </div>
        <span className="featuredSub">Berdasarkan Bulan Terakhir {date}</span>
      </div>

      <div className="featuredItem">
        <span className="featuredTitle">Pegawai SDIT Al Hasanah 2</span>
        <div className="featuredMoneyContainer">
          <span className="featuredMoney">{pegawai4}</span>
        </div>
        <span className="featuredSub">Berdasarkan Bulan Terakhir {date}</span>
      </div>


      <div className="featuredItem">
        <span className="featuredTitle">Pegawai Pondok Pesantren</span>
        <div className="featuredMoneyContainer">
          <span className="featuredMoney">{pegawai5}</span>
        </div>
        <span className="featuredSub">Berdasarkan Bulan Terakhir {date}</span>
      </div>

      {/* <div className="featuredItem">
        <span className="featuredTitle">Pegawai MTS Al Hasanah</span>
        <div className="featuredMoneyContainer">
          <span className="featuredMoney">{pegawai6}</span>
        </div>
        <span className="featuredSub">Berdasarkan Bulan Terakhir {date}</span>
      </div> */}

      <div className="featuredItem">
        <span className="featuredTitle">Pegawai SMPI Al Hasanah</span>
        <div className="featuredMoneyContainer">
          <span className="featuredMoney">{pegawai7}</span>
        </div>
        <span className="featuredSub">Berdasarkan Bulan Terakhir {date}</span>
      </div>

      <div className="featuredItem">
        <span className="featuredTitle">Pegawai SMAIT Al Hasanah</span>
        <div className="featuredMoneyContainer">
          <span className="featuredMoney">{pegawai8}</span>
        </div>
        <span className="featuredSub">Berdasarkan Bulan Terakhir {date}</span>
      </div>
    </div>
  );
}
