import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import "./globalList.scss";
import Url from "../global";
import * as FaIcons from "react-icons/fa";

const RiwayatPrestasiList = () => {
  const [riwayatPrestasis, setRiwayatPrestasi] = useState([]);
  const { pegawai } = useSelector((state) => state.auth);
  const [query, setQuery] = useState("");
  const [query2, setQuery2] = useState("");
  const [order, setOrder] = useState("ASC");

  const sorting = (col) => {
    if (order === "ASC") {
      const sorted = [...riwayatPrestasis].sort((a, b) =>
        a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
      );
      setRiwayatPrestasi(sorted);
      setOrder("DSC");
    }
    if (order === "DSC") {
      const sorted = [...riwayatPrestasis].sort((a, b) =>
        a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
      );
      setRiwayatPrestasi(sorted);
      setOrder("ASC");
    }
  };

  useEffect(() => {
    getRiwayatPrestasi();
  }, []);

  const getRiwayatPrestasi = async () => {
    const response = await axios.get(`${Url}/riwayat_prestasi`);
    setRiwayatPrestasi(response.data);
  };

  const deleteRiwayatPrestasi = async (riwayatPrestasiId) => {
    await axios.delete(`${Url}/riwayat_prestasi/${riwayatPrestasiId}`);
    getRiwayatPrestasi();
  };



  return (
    <div>
      <h1 className="title">Riwayat Prestasi</h1>

      {pegawai &&
        (pegawai.strRole === "user" ||
          pegawai.strRole === "kepsek" ||
          pegawai.strRole === "staff TU" ||
          pegawai.strRole === "kabid kepegawaian" ||
          pegawai.strRole === "admin") && (
          <Link to="/riwayat-prestasi/add" className="button is-primary mb-2">
            Add New Riwayat Prestasi
          </Link>
        )}

      <ReactHTMLTableToExcel
        id="test-table-xls-button"
        className="download-table-xls-button"
        table="table-to-xls"
        filename="Table Riwayat Prestasi"
        sheet="tablexls"
        buttonText="Download as XLS"
      />

      <br />
      {pegawai &&
        (pegawai.strRole === "admin" ||
          pegawai.strRole === "kepsek" ||
          pegawai.strRole === "kabid kepegawaian" ||
          pegawai.strRole === "staff TU") && <label> Masukan Nama </label>}
      {pegawai &&
        (pegawai.strRole === "admin" ||
          pegawai.strRole === "kepsek" ||
          pegawai.strRole === "kabid kepegawaian" ||
          pegawai.strRole === "staff TU") && (
          <input
            className="search"
            placeholder="Cari Nama Individu"
            onChange={(e) => setQuery(e.target.value.toLowerCase())}
          />
        )}
      {pegawai &&
        (pegawai.strRole === "admin" ||
          pegawai.strRole === "kepsek" ||
          pegawai.strRole === "kabid kepegawaian" ||
          pegawai.strRole === "staff TU") && (
          <label className="label-unit">Masukan Unit </label>
        )}

      {pegawai &&
        (pegawai.strRole === "admin" ||
          pegawai.strRole === "kepsek" ||
          pegawai.strRole === "kabid kepegawaian" ||
          pegawai.strRole === "staff TU") && (
          <input
            className="search"
            placeholder="Cari Berdasarkan Unit"
            onChange={(e) => setQuery2(e.target.value.toLowerCase())}
          />
        )}

      <div className="table__wrapper_Scroll_Log">
        <table
          className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth"
          id="table-to-xls"
        >
          <thead>
            <tr>
              <th className="has-text-centered">No</th>
              <th className="has-text-centered">Juara</th>
              <th className="has-text-centered">Tingkatan</th>
              <th className="has-text-centered">Tahun</th>
              <th className="has-text-centered">Deskripsi Perlombaan</th>
              <th
                className="has-text-centered"
                onClick={() => sorting("strStatus")}
              >
                Status <FaIcons.FaSort />
              </th>
              <th className="has-text-centered">Komentar</th>
              <th className="has-text-centered">Created by</th>
              <th className="has-text-centered">Unit</th>
              <th className="has-text-centered">Action</th>
            </tr>
          </thead>
          {pegawai && pegawai.strRole === "user" && (
            <tbody>
              {riwayatPrestasis.map((riwayatPrestasi, index) =>
                riwayatPrestasi.strStatus ===
                  "Pengajuan Sertifikat Prestasi" ||
                  riwayatPrestasi.strStatus === "Ditolak Kabid Kepegawaian" ? (
                  <tr key={riwayatPrestasi.uuid}>
                    {index === 0}
                    <td>{index + 1}</td>
                    <td>{riwayatPrestasi.strJuara}</td>
                    <td>{riwayatPrestasi.strTingkatan}</td>

                    <td>
                      {riwayatPrestasi.strTahun}
                    </td>

                    <td>{riwayatPrestasi.strDeskripsi}</td>

                    <td>{riwayatPrestasi.strStatus}</td>
                    <td>{riwayatPrestasi.strKomentar}</td>
                    <td>{riwayatPrestasi.pegawai.strNama}</td>
                    <td>{riwayatPrestasi.pegawai.strUnit}</td>
                    <td>
                      <Link
                        to={`/riwayat-prestasi/edit/${riwayatPrestasi.uuid}`}
                        className="button is-small is-info"
                      >
                        Edit
                      </Link>
                      <button
                        onClick={() =>
                          deleteRiwayatPrestasi(riwayatPrestasi.uuid)
                        }
                        className="button is-small is-danger"
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ) : (
                  <tr key={riwayatPrestasi.uuid}>
                    {index === 0}
                    <td>{index + 1}</td>
                    <td>{riwayatPrestasi.strJuara}</td>
                    <td>{riwayatPrestasi.strTingkatan}</td>

                    <td>
                      {riwayatPrestasi.strTahun}
                    </td>

                    <td>{riwayatPrestasi.strDeskripsi}</td>

                    <td>{riwayatPrestasi.strStatus}</td>
                    <td>{riwayatPrestasi.strKomentar}</td>
                    <td>{riwayatPrestasi.pegawai.strNama}</td>
                    <td>{riwayatPrestasi.pegawai.strUnit}</td>
                    <td> - </td>
                  </tr>
                )
              )}
            </tbody>
          )}

          {pegawai &&
            (pegawai.strRole === "kepsek" || pegawai.strRole === "staff TU") &&
            pegawai.strUnit === "PAUD IT Al Hasanah 1" && (
              <tbody>
                {riwayatPrestasis
                  .filter(
                    (riwayatPrestasi) =>
                      riwayatPrestasi.pegawai.strNama
                        .toLowerCase()
                        .includes(query) &&
                      riwayatPrestasi.pegawai.strUnit
                        .toLowerCase()
                        .includes(query2)
                  )
                  .map((riwayatPrestasi, index) =>
                    riwayatPrestasi.pegawai.strUnit ===
                      "PAUD IT Al Hasanah 1" ? (
                      <tr key={riwayatPrestasi.uuid}>
                        {index === 0}
                        <td>{index + 1}</td>
                        <td>{riwayatPrestasi.strJuara}</td>
                        <td>{riwayatPrestasi.strTingkatan}</td>

                        <td>
                          {riwayatPrestasi.strTahun}
                        </td>

                        <td>{riwayatPrestasi.strDeskripsi}</td>

                        <td>{riwayatPrestasi.strStatus}</td>
                        <td>{riwayatPrestasi.strKomentar}</td>
                        <td>{riwayatPrestasi.pegawai.strNama}</td>
                        <td>{riwayatPrestasi.pegawai.strUnit}</td>
                        <td>
                          <Link
                            to={`/riwayat-prestasi/edit/${riwayatPrestasi.uuid}`}
                            className="button is-small is-info"
                          >
                            Edit
                          </Link>
                          <button
                            onClick={() =>
                              deleteRiwayatPrestasi(riwayatPrestasi.uuid)
                            }
                            className="button is-small is-danger"
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ) : null
                  )}
              </tbody>
            )}

          {pegawai &&
            (pegawai.strRole === "kepsek" || pegawai.strRole === "staff TU") &&
            pegawai.strUnit === "PAUD IT Al Hasanah 2" && (
              <tbody>
                {riwayatPrestasis
                  .filter(
                    (riwayatPrestasi) =>
                      riwayatPrestasi.pegawai.strNama
                        .toLowerCase()
                        .includes(query) &&
                      riwayatPrestasi.pegawai.strUnit
                        .toLowerCase()
                        .includes(query2)
                  )
                  .map((riwayatPrestasi, index) =>
                    riwayatPrestasi.pegawai.strUnit ===
                      "PAUD IT Al Hasanah 2" ? (
                      <tr key={riwayatPrestasi.uuid}>
                        {index === 0}
                        <td>{index + 1}</td>
                        <td>{riwayatPrestasi.strJuara}</td>
                        <td>{riwayatPrestasi.strTingkatan}</td>

                        <td>
                          {riwayatPrestasi.strTahun}
                        </td>

                        <td>{riwayatPrestasi.strDeskripsi}</td>

                        <td>{riwayatPrestasi.strStatus}</td>
                        <td>{riwayatPrestasi.strKomentar}</td>
                        <td>{riwayatPrestasi.pegawai.strNama}</td>
                        <td>{riwayatPrestasi.pegawai.strUnit}</td>
                        <td>
                          <Link
                            to={`/riwayat-prestasi/edit/${riwayatPrestasi.uuid}`}
                            className="button is-small is-info"
                          >
                            Edit
                          </Link>
                          <button
                            onClick={() =>
                              deleteRiwayatPrestasi(riwayatPrestasi.uuid)
                            }
                            className="button is-small is-danger"
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ) : null
                  )}
              </tbody>
            )}

          {pegawai &&
            (pegawai.strRole === "kepsek" || pegawai.strRole === "staff TU") &&
            pegawai.strUnit === "SDIT Al Hasanah 1" && (
              <tbody>
                {riwayatPrestasis
                  .filter(
                    (riwayatPrestasi) =>
                      riwayatPrestasi.pegawai.strNama
                        .toLowerCase()
                        .includes(query) &&
                      riwayatPrestasi.pegawai.strUnit
                        .toLowerCase()
                        .includes(query2)
                  )
                  .map((riwayatPrestasi, index) =>
                    riwayatPrestasi.pegawai.strUnit ===
                      "SDIT Al Hasanah 1" ? (
                      <tr key={riwayatPrestasi.uuid}>
                        {index === 0}
                        <td>{index + 1}</td>
                        <td>{riwayatPrestasi.strJuara}</td>
                        <td>{riwayatPrestasi.strTingkatan}</td>

                        <td>
                          {riwayatPrestasi.strTahun}
                        </td>

                        <td>{riwayatPrestasi.strDeskripsi}</td>

                        <td>{riwayatPrestasi.strStatus}</td>
                        <td>{riwayatPrestasi.strKomentar}</td>
                        <td>{riwayatPrestasi.pegawai.strNama}</td>
                        <td>{riwayatPrestasi.pegawai.strUnit}</td>
                        <td>
                          <Link
                            to={`/riwayat-prestasi/edit/${riwayatPrestasi.uuid}`}
                            className="button is-small is-info"
                          >
                            Edit
                          </Link>
                          <button
                            onClick={() =>
                              deleteRiwayatPrestasi(riwayatPrestasi.uuid)
                            }
                            className="button is-small is-danger"
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ) : null
                  )}
              </tbody>
            )}

          {pegawai &&
            (pegawai.strRole === "kepsek" || pegawai.strRole === "staff TU") &&
            pegawai.strUnit === "SDIT Al Hasanah 2" && (
              <tbody>
                {riwayatPrestasis
                  .filter(
                    (riwayatPrestasi) =>
                      riwayatPrestasi.pegawai.strNama
                        .toLowerCase()
                        .includes(query) &&
                      riwayatPrestasi.pegawai.strUnit
                        .toLowerCase()
                        .includes(query2)
                  )
                  .map((riwayatPrestasi, index) =>
                    riwayatPrestasi.pegawai.strUnit ===
                      "SDIT Al Hasanah 2" ? (
                      <tr key={riwayatPrestasi.uuid}>
                        {index === 0}
                        <td>{index + 1}</td>
                        <td>{riwayatPrestasi.strJuara}</td>
                        <td>{riwayatPrestasi.strTingkatan}</td>

                        <td>
                          {riwayatPrestasi.strTahun}
                        </td>

                        <td>{riwayatPrestasi.strDeskripsi}</td>

                        <td>{riwayatPrestasi.strStatus}</td>
                        <td>{riwayatPrestasi.strKomentar}</td>
                        <td>{riwayatPrestasi.pegawai.strNama}</td>
                        <td>{riwayatPrestasi.pegawai.strUnit}</td>
                        <td>
                          <Link
                            to={`/riwayat-prestasi/edit/${riwayatPrestasi.uuid}`}
                            className="button is-small is-info"
                          >
                            Edit
                          </Link>
                          <button
                            onClick={() =>
                              deleteRiwayatPrestasi(riwayatPrestasi.uuid)
                            }
                            className="button is-small is-danger"
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ) : null
                  )}
              </tbody>
            )}

          {pegawai &&
            (pegawai.strRole === "kepsek" || pegawai.strRole === "staff TU") &&
            pegawai.strUnit === "SMPI Al Hasanah" && (
              <tbody>
                {riwayatPrestasis
                  .filter(
                    (riwayatPrestasi) =>
                      riwayatPrestasi.pegawai.strNama
                        .toLowerCase()
                        .includes(query) &&
                      riwayatPrestasi.pegawai.strUnit
                        .toLowerCase()
                        .includes(query2)
                  )
                  .map((riwayatPrestasi, index) =>
                    riwayatPrestasi.pegawai.strUnit ===
                      "SMPI Al Hasanah" ? (
                      <tr key={riwayatPrestasi.uuid}>
                        {index === 0}
                        <td>{index + 1}</td>
                        <td>{riwayatPrestasi.strJuara}</td>
                        <td>{riwayatPrestasi.strTingkatan}</td>

                        <td>
                          {riwayatPrestasi.strTahun}
                        </td>

                        <td>{riwayatPrestasi.strDeskripsi}</td>

                        <td>{riwayatPrestasi.strStatus}</td>
                        <td>{riwayatPrestasi.strKomentar}</td>
                        <td>{riwayatPrestasi.pegawai.strNama}</td>
                        <td>{riwayatPrestasi.pegawai.strUnit}</td>
                        <td>
                          <Link
                            to={`/riwayat-prestasi/edit/${riwayatPrestasi.uuid}`}
                            className="button is-small is-info"
                          >
                            Edit
                          </Link>
                          <button
                            onClick={() =>
                              deleteRiwayatPrestasi(riwayatPrestasi.uuid)
                            }
                            className="button is-small is-danger"
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ) : null
                  )}
              </tbody>
            )}

          {pegawai &&
            (pegawai.strRole === "kepsek" || pegawai.strRole === "staff TU") &&
            pegawai.strUnit === "SMAIT Al Hasanah" && (
              <tbody>
                {riwayatPrestasis
                  .filter(
                    (riwayatPrestasi) =>
                      riwayatPrestasi.pegawai.strNama
                        .toLowerCase()
                        .includes(query) &&
                      riwayatPrestasi.pegawai.strUnit
                        .toLowerCase()
                        .includes(query2)
                  )
                  .map((riwayatPrestasi, index) =>
                    riwayatPrestasi.pegawai.strUnit ===
                      "SMAIT Al Hasanah" ? (
                      <tr key={riwayatPrestasi.uuid}>
                        {index === 0}
                        <td>{index + 1}</td>
                        <td>{riwayatPrestasi.strJuara}</td>
                        <td>{riwayatPrestasi.strTingkatan}</td>

                        <td>
                          {riwayatPrestasi.strTahun}
                        </td>

                        <td>{riwayatPrestasi.strDeskripsi}</td>

                        <td>{riwayatPrestasi.strStatus}</td>
                        <td>{riwayatPrestasi.strKomentar}</td>
                        <td>{riwayatPrestasi.pegawai.strNama}</td>
                        <td>{riwayatPrestasi.pegawai.strUnit}</td>
                        <td>
                          <Link
                            to={`/riwayat-prestasi/edit/${riwayatPrestasi.uuid}`}
                            className="button is-small is-info"
                          >
                            Edit
                          </Link>
                          <button
                            onClick={() =>
                              deleteRiwayatPrestasi(riwayatPrestasi.uuid)
                            }
                            className="button is-small is-danger"
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ) : null
                  )}
              </tbody>
            )}

          {pegawai &&
            (pegawai.strRole === "kepsek" || pegawai.strRole === "staff TU") &&
            pegawai.strUnit === "Pondok Pesantren" && (
              <tbody>
                {riwayatPrestasis
                  .filter(
                    (riwayatPrestasi) =>
                      riwayatPrestasi.pegawai.strNama
                        .toLowerCase()
                        .includes(query) &&
                      riwayatPrestasi.pegawai.strUnit
                        .toLowerCase()
                        .includes(query2)
                  )
                  .map((riwayatPrestasi, index) =>
                    riwayatPrestasi.pegawai.strUnit ===
                      "Pondok Pesantren" ? (
                      <tr key={riwayatPrestasi.uuid}>
                        {index === 0}
                        <td>{index + 1}</td>
                        <td>{riwayatPrestasi.strJuara}</td>
                        <td>{riwayatPrestasi.strTingkatan}</td>

                        <td>
                          {riwayatPrestasi.strTahun}
                        </td>

                        <td>{riwayatPrestasi.strDeskripsi}</td>

                        <td>{riwayatPrestasi.strStatus}</td>
                        <td>{riwayatPrestasi.strKomentar}</td>
                        <td>{riwayatPrestasi.pegawai.strNama}</td>
                        <td>{riwayatPrestasi.pegawai.strUnit}</td>
                        <td>
                          <Link
                            to={`/riwayat-prestasi/edit/${riwayatPrestasi.uuid}`}
                            className="button is-small is-info"
                          >
                            Edit
                          </Link>
                          <button
                            onClick={() =>
                              deleteRiwayatPrestasi(riwayatPrestasi.uuid)
                            }
                            className="button is-small is-danger"
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ) : null
                  )}
              </tbody>
            )}

          {pegawai &&
            (pegawai.strRole === "admin" || pegawai.strRole === "kabid kepegawaian") && (
              <tbody>
                {riwayatPrestasis
                  .filter(
                    (riwayatPrestasi) =>
                      riwayatPrestasi.pegawai.strNama
                        .toLowerCase()
                        .includes(query) &&
                      riwayatPrestasi.pegawai.strUnit
                        .toLowerCase()
                        .includes(query2)
                  ).map((riwayatPrestasi, index) => (
                    <tr key={riwayatPrestasi.uuid}>
                      {index === 0}
                      <td>{index + 1}</td>
                      <td>{riwayatPrestasi.strJuara}</td>
                      <td>{riwayatPrestasi.strTingkatan}</td>

                      <td>
                        {riwayatPrestasi.strTahun}
                      </td>

                      <td>{riwayatPrestasi.strDeskripsi}</td>

                      <td>{riwayatPrestasi.strStatus}</td>
                      <td>{riwayatPrestasi.strKomentar}</td>
                      <td>{riwayatPrestasi.pegawai.strNama}</td>
                      <td>{riwayatPrestasi.pegawai.strUnit}</td>
                      <td>
                        <Link
                          to={`/riwayat-prestasi/edit/${riwayatPrestasi.uuid}`}
                          className="button is-small is-info"
                        >
                          Edit
                        </Link>
                        <button
                          onClick={() =>
                            deleteRiwayatPrestasi(riwayatPrestasi.uuid)
                          }
                          className="button is-small is-danger"
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            )}
        </table>
      </div>
    </div>

  )
}

export default RiwayatPrestasiList