import React from "react";
import * as FaIcons from "react-icons/fa";
import { logoDashboardUser } from "../assets";
import "./DashboardUser.scss";
// import { useSelector } from "react-redux";

const DashboardPAUDIT2 = () => {

  // const { pegawai } = useSelector((state) => state.auth);

  return (
    <div className="wrapperPAUD2">
      <div>
        <img
          src={logoDashboardUser}
          alt="logo-dashboard"
          className="logo-dashboard"
        />
      </div>
      <a
        href="https://alhasanah.or.id/info-ppdb"
        target="_blank"
        rel="noreferrer"
      >
        <button className="btnDashboard">
          {" "}
          <FaIcons.FaCalendar /> Info PPDB 2023{" "}
        </button>
      </a>
      <a
        href="https://siswa.alhasanah.sch.id/#/signin/"
        target="_blank"
        rel="noreferrer"
      >
        <button className="btnDashboard">
          {" "}
          <FaIcons.FaCalculator /> Daftar PPDB 2023{" "}
        </button>
      </a>
      <a
        href="https://www.youtube.com/playlist?list=PLxudJ-_j81Ggu2ocG4kIlLD5lTt6Qhd8E"
        target="_blank"
        rel="noreferrer"
      >
        <button className="btnDashboard">
          {" "}
          <FaIcons.FaVideo /> Lomba Video Kreatif 2023{" "}
        </button>
      </a>
      <a href="https://alhasanah.or.id/karir/"
        target="_blank"
        rel="noreferrer"
      >
        <button className="btnDashboard">
          {" "}
          <FaIcons.FaCrosshairs /> Lowongan Karir{" "}
        </button>
      </a>
      <a href="https://alhasanah.or.id/"
        target="_blank"
        rel="noreferrer"
      >
        <button className="btnDashboard"> Web Yayasan Al Hasanah </button>
      </a>

      <a href="https://ponpes.alhasanah.sch.id/"
        target="_blank"
        rel="noreferrer"
      >
        <button className="btnDashboard">
          {" "}
          Web Pondok Pesantren Al Hasanah{" "}
        </button>
      </a>

      <a href="https://paudit.alhasanah.sch.id/"
        target="_blank"
        rel="noreferrer"
      >
        <button className="btnDashboard"> Web PAUD IT Al Hasanah </button>
      </a>

      <a href="https://sdit.alhasanah.sch.id/"
        target="_blank"
        rel="noreferrer">
        <button className="btnDashboard"> Web SDIT Al Hasanah </button>
      </a>

      <a href="https://smpi.alhasanah.sch.id/"
        target="_blank"
        rel="noreferrer">
        <button className="btnDashboard"> Web SMPI Al Hasanah </button>
      </a>

      <a href="https://smait.alhasanah.sch.id/"
        target="_blank"
        rel="noreferrer"
      >
        <button className="btnDashboard"> Web SMAIT Al Hasanah </button>
      </a>
    </div>
  );
};



export default DashboardPAUDIT2;
