import React from "react";
import { useSelector } from "react-redux";

import "./Pengumuman.scss";

const Pengumuman = () => {
  const { pegawai } = useSelector((state) => state.auth);

  return (
    <div className="all-container">
      <div className="card-container">
        <p className="judul">Pengumuman</p>
        <ul className="a">
          <li>
            Selamat Datang Ustadz/Ustadzah{" "}
            <strong>{pegawai && pegawai.strNama}</strong> di Aplikasi Sistem
            Pegawai Al Hasanah.
          </li>
          <li>
            Menu data pribadi wajib di isi terlebih dahulu ketika aplikasi
            pertama kali digunakan.{" "}
          </li>
          <li>
            {" "}
            Menu data pribadi akan ditutup dalam satu minggu ketika username dan
            password user diberikan.
          </li>
          <li>
            Aturan penulisan di menu - menu yang bersifat text harus menggunakan
            huruf besar di awal kalimat <br /> contoh : Tempat Sekolah :
            Universitas Bengkulu.
          </li>
        </ul>
        <a href="https://simpeg.alhasanah.sch.id/dashboard">
          <button className="btnPengumuman">Menu Utama SIMPEG</button>
        </a>
      </div>
    </div>
  );
};

export default Pengumuman;
