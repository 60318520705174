import axios from "axios";
import React, { useState } from "react";
import Url from "../global";

const MultipleUpload = () => {
  const [image, setImage] = useState([]);
  const [category, setCategory] = useState("");
  console.log(image);

  const handleSubmit = () => {
    let formData = new FormData();
    formData.append('category', category);
    Array.from(image).forEach((item) => {
      formData.append('products', item);
    });

    const url = `${Url}/image`
    axios.post(url, formData).then((result) => {
      console.log(result);
    });
  };

  return (
    <div>
      {Array.from(image).map((item) => {
        return (
          <img
            alt= ""
            style={{ padding: "10px" }}
            width={50}
            height={100}
            src={item ? URL.createObjectURL(item) : null}
          />
        );
      })}
      <input
        onChange={(e) => {
          setImage(e.target.files);
   
        }}
        multiple
        type="file"
   
      />
      <input
        onChange={(e) => setCategory(e.target.value)}
        type="text" value={category}
      />
      <button onClick={handleSubmit}>SUBMIT</button>
    </div>
  );
};

export default MultipleUpload;
