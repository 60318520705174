import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { login } from "../assets";
import { LoginPegawai, reset } from "../features/authSlice";
import "./login.scss";
// import Url from "../global";
// import axios from "axios";

const Login = () => {
  const [strEmail, setEmail] = useState("");
  const [strPassword, setPassword] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { pegawai, isError, isSuccess, isLoading, message } = useSelector(
    (state) => state.auth
  );

  
  useEffect(() => {
    if (pegawai || isSuccess) {
      navigate("/pengumuman");
    }

    dispatch(reset());
  }, [pegawai, isSuccess, dispatch, navigate]);

  // useEffect(() => {
  //   if (pegawai || isSuccess) {
  //     navigate("/data-person");
  //   }

  //   dispatch(reset());
  // }, [pegawai, isSuccess, dispatch, navigate]);

  const Auth = (e) => {
    e.preventDefault();
    dispatch(LoginPegawai({ strEmail, strPassword }));
  };



  return (
      <div className="body">
        <div className="main-page-login">
          <div className="left">
            <img src={login} className="bg-image" alt="imageBG" />
          </div>
          <div className="right">
              <form onSubmit={Auth} className="box">
                {isError && <p className="has-text-centered">{message}</p>}
                <h1 className="title">Sign In</h1>
                
                <div className="field">
                  <label className="label">Email</label>
                  <div className="control">
                    <input
                      type="text"
                      className="input"
                      value={strEmail}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Email"
                    />
                  </div>
                </div>
                <div className="field">
                  <label className="label">Password</label>
                  <div className="control">
                    <input
                      type="password"
                      className="input"
                      value={strPassword}
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder="******"
                    />
                  </div>
                </div>
                <div className="field mt-5">
                  <button
                    type="submit"
                    className="button is-success is-fullwidth"
                  >
                    {isLoading ? "Loading..." : "Login"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
  );
};

export default Login;
