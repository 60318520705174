import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { logo } from "../assets";
import { useDispatch, useSelector } from "react-redux";
import { LogOut, reset } from "../features/authSlice";
import "./login.scss";

const Navbar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pegawai } = useSelector((state) => state.auth);

  const Logout = () => {
    dispatch(LogOut());
    dispatch(reset());
    navigate("/");
  };
  return (
    <div>
      <nav
        className="navbar is-fixed-top has-shadow"
        role="navigation"
        aria-label="main navigation"
      >
        <div className="navbar-brand">
          {pegawai &&
            (pegawai.strRole === "admin" ||
              pegawai.strRole === "kabid kepegawaian") && (
              <NavLink to="/dashboard" className="navbar-item">
                <img src={logo} className="Logo" alt="logo" />
                <h1 className="simpeg">Sistem Pegawai Yayasan Al Hasanah</h1>
              </NavLink>
            )}
   
          {pegawai &&
            (pegawai.strRole === "user" ||
              pegawai.strRole === "kepsek" ||
              pegawai.strRole === "staff TU") && (
              <NavLink to="/dashboard" className="navbar-item">
                <img src={logo} className="Logo" alt="logo" />
                <h1 className="simpeg">Sistem Pegawai Yayasan Al Hasanah</h1>
              </NavLink>
            )}

          <a
            href="!#"
            role="button"
            className="navbar-burger burger"
            aria-label="menu"
            aria-expanded="false"
            data-target="navbarBasicExample"
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>

        <div id="navbarBasicExample" className="navbar-menu">
          <div className="navbar-end">
            <div className="navbar-item">
              <div className="buttons">
                <button onClick={Logout} className="button is-light">
                  Log out
                </button>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
