import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import "./Style/globalValidasiEror.scss";
import Url from "../global";

const FormAddPrestasi = () => {
    const [juara, setJuara] = useState("");
    const [tingkatan, setTingkatan] = useState("");
    const [tahun, setTahun] = useState("");
    const [deskripsi, setDiskripsi] = useState("");
    const [status, setStatus] = useState("");
    const [msg, setMsg] = useState("");
    const navigate = useNavigate();


    const {
        register,
        reset,
        trigger,
        handleSubmit,
        formState: { errors },
    } = useForm();




    const saveRiwayatPrestasi = async (e) => {

        const formData = new FormData();
        formData.append("strJuara", juara);
        formData.append("strTingkatan", tingkatan);
        formData.append("strTahun", tahun);
        formData.append("strDeskripsi", deskripsi);
        formData.append("strStatus", status);

        try {
            await axios.post(`${Url}/riwayat_prestasi`, formData, {
                headers: {
                    "Content-type": "multipart/form-data",
                },
            });
            navigate("/riwayat-prestasi");
            reset();
        } catch (error) {
            if (error.response) {
                setMsg(error.response.data.msg);
            }
        }
    }


    return (
        <div>
            <h1 className="title">Riwayat Prestasi</h1>
            <h2 className="subtitle">Add New Riwayat Prestasi</h2>

            <div className="card is-shadowless">
                <div className="card-content">
                    <div className="content">
                        <form className="box" onSubmit={handleSubmit(saveRiwayatPrestasi)}>
                            <p className="has-text-centered">{msg}</p>

                            <div className="field">
                                <label className="label">Juara</label>
                                <div className="control">
                                    <div className="select is-fullwidth">
                                        <select
                                            className="dropdown"
                                            value={juara}
                                            onChange={(e) => setJuara(e.target.value)}
                                            required
                                        // disabled="true"
                                        >
                                            <option>--pilih--</option>
                                            <option>Juara 1</option>
                                            <option>Juara 2</option>
                                            <option>Juara 3</option>
                                            <option>Harapan 1</option>
                                            <option>Harapan 2</option>
                                            <option>Harapan 3</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div className="field">
                                <label className="label">Tingkatan</label>
                                <div className="control">
                                    <div className="select is-fullwidth">
                                        <select
                                            className="dropdown"
                                            value={tingkatan}
                                            onChange={(e) => setTingkatan(e.target.value)}
                                            required
                                        // disabled="true"
                                        >
                                            <option>--pilih--</option>
                                            <option>Kota / Kabupaten</option>
                                            <option>Provinsi</option>
                                            <option>Nasional</option>
                                            <option>Internasional</option>
                                        </select>
                                    </div>
                                </div>
                            </div>




                            <div className="field">
                                <label className="label">Tahun</label>
                                <div className="control">
                                    <div className="select is-fullwidth">
                                        <select
                                            className="dropdown"
                                            value={tahun}
                                            onChange={(e) => setTahun(e.target.value)}
                                            required
                                        // disabled="true"
                                        >
                                            <option>--- Pilih ---</option>
                                            <option>2023</option>
                                            <option>2024</option>
                                            <option>2025</option>
                                            <option>2026</option>
                                            <option>2027</option>
                                            <option>2028</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div className="field">
                                <label className="label">Deskripsi Lomba</label>
                                <div className="control">
                                    <textarea

                                        className={`textarea ${errors.deskripsi && "invalid"}`}
                                        {...register("deskripsi", {
                                            required: {
                                                value: true,
                                                message: "Deskripsi Wajib Di isi!",
                                            },
                                        })}
                                        onKeyUp={() => {
                                            trigger("deskripsi");
                                        }}
                                        placeholder="Deskripsi Perlombaan"
                                        value={deskripsi}
                                        onChange={(e) => setDiskripsi(e.target.value)}
                                    />
                                    {errors.deskripsi && (
                                        <small className="allValidasiError">
                                            {errors.deskripsi.message}
                                        </small>
                                    )}
                                </div>
                            </div>

                            <div className="field">
                                <label className="label">Status Prestasi</label>
                                <div className="control">
                                    <div className="select is-fullwidth">
                                        <select
                                            className="dropdown"
                                            value={status}
                                            onChange={(e) => setStatus(e.target.value)}
                                            required
                                            disabled="true"
                                        >
                                            <option>Pengajuan Sertifikat Prestasi</option>
                                            <option>Disetujui Kabid Kepegawaian</option>
                                            <option>Ditolak Kabid Kepegawaian</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <br />
                            <br />
                            <div className="field">
                                <div className="control">
                                    <button type="submit" className="button is-success">
                                        Save
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FormAddPrestasi
