import React, { useState, useEffect } from "react";
import "./globalList.scss";
import axios from "axios";
import Url from "../global";

const TableDashboard = () => {
  //pendidikan
  const [PengajuanPendidikan, setPengajuanPendidikan] = useState([]);
  const [DisetujuiPendidikan, setDisetujuiPendidikan] = useState([]);
  const [DitolakPendidikan, setDitolakPendidikan] = useState([]);

  //keluarga
  const [PengajuanK, setPengajuanK] = useState([]);
  const [DisetujuiK, setDisetujuiK] = useState([]);
  const [DitolakK, setDitolakK] = useState([]);

  //pelatihan
  const [PengajuanPelatihan, setPengajuanPelatihan] = useState([]);
  const [DisetujuiPelatihan, setDisetujuiPelatihan] = useState([]);
  const [DitolakPelatihan, setDitolakPelatihan] = useState([]);

  //jabatan
  const [PengajuanJabatan, setPengajuanJabatan] = useState([]);
  const [DisetujuiJabatan, setDisetujuiJabatan] = useState([]);
  const [DitolakJabatan, setDitolakJabatan] = useState([]);

  //jabatan
  const [PengajuanKepangkatan, setPengajuanKepangkatan] = useState([]);
  const [DisetujuiKepangkatan, setDisetujuiKepangkatan] = useState([]);
  const [DitolakKepangkatan, setDitolakKepangkatan] = useState([]);

  useEffect(() => {
    getPengajuanK();
    getDisetujuiK();
    getDitolakK();
    getDiSetujuiPelatihan();
    getDitolakPelatihan();
    getPengajuanPelatihan();
    getDiSetujuiPendidikan();
    getDitolakPendidikan();
    getPengajuanPendidikan();
    getPengajuanJabatan();
    getDiSetujuiJabatan();
    getDitolakJabatan();
    getPengajuanKepangkatan();
    getDisetujuiKepangkatan();
    getDitolakKepangkatan();
  }, []);

  const getPengajuanK = async () => {
    const response = await axios.get(`${Url}/dashboard/KP`, {
      credentials: "include",
    });
    setPengajuanK(response.data);
  };

  const getDisetujuiK = async () => {
    const response = await axios.get(`${Url}/dashboard/ACC`, {
      credentials: "include",
    });
    setDisetujuiK(response.data);
  };

  const getDitolakK = async () => {
    const response = await axios.get(`${Url}/dashboard/Ditolak`, {
      credentials: "include",
    });
    setDitolakK(response.data);
  };

  const getPengajuanPelatihan = async () => {
    const response = await axios.get(`${Url}/dashboard/pPelatihan`, {
      credentials: "include",
    });
    setPengajuanPelatihan(response.data);
  };

  const getDiSetujuiPelatihan = async () => {
    const response = await axios.get(`${Url}/dashboard/ACCP`, {
      credentials: "include",
    });
    setDisetujuiPelatihan(response.data);
  };

  const getDitolakPelatihan = async () => {
    const response = await axios.get(`${Url}/dashboard/DitolakP`, {
      credentials: "include",
    });
    setDitolakPelatihan(response.data);
  };

  const getPengajuanPendidikan = async () => {
    const response = await axios.get(`${Url}/dashboard/pengajuanPendidikan`, {
      credentials: "include",
    });
    setPengajuanPendidikan(response.data);
  };

  const getDiSetujuiPendidikan = async () => {
    const response = await axios.get(`${Url}/dashboard/ACCPendidikan`, {
      credentials: "include",
    });
    setDisetujuiPendidikan(response.data);
  };

  const getDitolakPendidikan = async () => {
    const response = await axios.get(`${Url}/dashboard/DitolakPendidikan`, {
      credentials: "include",
    });
    setDitolakPendidikan(response.data);
  };

  const getPengajuanJabatan = async () => {
    const response = await axios.get(`${Url}/dashboard/pengajuanJabatan`, {
      credentials: "include",
    });
    setPengajuanJabatan(response.data);
  };

  const getDiSetujuiJabatan = async () => {
    const response = await axios.get(`${Url}/dashboard/ACCJabatan`, {
      credentials: "include",
    });
    setDisetujuiJabatan(response.data);
  };

  const getDitolakJabatan = async () => {
    const response = await axios.get(`${Url}/dashboard/DitolakJabatan`, {
      credentials: "include",
    });
    setDitolakJabatan(response.data);
  };

  const getPengajuanKepangkatan = async () => {
    const response = await axios.get(`${Url}/dashboard/pengajuanKepangkatan`, {
      credentials: "include",
    });
    setPengajuanKepangkatan(response.data);
  };

  const getDisetujuiKepangkatan = async () => {
    const response = await axios.get(`${Url}/dashboard/ACCKepangkatan`, {
      credentials: "include",
    });
    setDisetujuiKepangkatan(response.data);
  };

  const getDitolakKepangkatan = async () => {
    const response = await axios.get(`${Url}/dashboard/DitolakKepangkatan`, {
      credentials: "include",
    });
    setDitolakKepangkatan(response.data);
  };
  return (
    <div>
      <div className="table__wrapper">
        <table
          className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth"
          id="table-to-xls"
        >
          <thead className="dashboardtablehead">
            <tr className="has-text-centered">
              <th className="has-text-centered">Menu</th>
              <th className="has-text-centered">Pengajuan</th>
              <th className="has-text-centered">Acc Kabid Kepegawaian</th>
              <th className="has-text-centered">Ditolak Kabid Kepengawaian</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Riwayat Pendidikan</td>
              <td className="has-text-centered">{PengajuanPendidikan}</td>
              <td className="has-text-centered">{DisetujuiPendidikan}</td>
              <td className="has-text-centered">{DitolakPendidikan}</td>
            </tr>
            <tr>
              <td>Riwayat Pelatihan</td>
              <td className="has-text-centered">{PengajuanPelatihan}</td>
              <td className="has-text-centered">{DisetujuiPelatihan}</td>
              <td className="has-text-centered">{DitolakPelatihan}</td>
            </tr>
            <tr>
              <td>Riwayat Jabatan</td>
              <td className="has-text-centered">{PengajuanJabatan}</td>
              <td className="has-text-centered">{DisetujuiJabatan}</td>
              <td className="has-text-centered">{DitolakJabatan}</td>
            </tr>
            <tr>
              <td>Riwayat Kepangkatan</td>
              <td className="has-text-centered">{PengajuanKepangkatan}</td>
              <td className="has-text-centered">{DisetujuiKepangkatan}</td>
              <td className="has-text-centered">{DitolakKepangkatan}</td>
            </tr>
            <tr>
              <td>Riwayat Keluarga</td>
              <td className="has-text-centered">{PengajuanK}</td>
              <td className="has-text-centered">{DisetujuiK}</td>
              <td className="has-text-centered">{DitolakK}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TableDashboard;
