import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./components/Login";
import AddPegawai from "./pages/AddPegawai";
import AddRiwayatPelatihan from "./pages/AddRiwayatPelatihan";
import AddRiwayatPendidikan from "./pages/AddRiwayatPendidikan";
import Dasboard from "./pages/Dasboard";
import DataPerson from "./pages/DataPerson";
import DataPersonAllPegawai from "./pages/DataPersonAllPegawai";
import EditPegawai from "./pages/EditPegawai";
import EditRiwayatPelatihan from "./pages/EditRiwayatPelatihan";
import EditRiwayatPendidikan from "./pages/EditRiwayatPendidikan";
import Pegawai from "./pages/Pegawai";
import RiwayatPelatihan from "./pages/RiwayatPelatihan";
import RiwayatPendidikan from "./pages/RiwayatPendidikan";
import RiwayatKepangkatan from "./pages/RiwayatKepangkatan";
import AddRiwayatKepangkatan from "./pages/AddRiwayatKepangkatan";
import EditRiwayatKepangkatan from "./pages/EditRiwayatKepangkatan";
import RiwayatKeluarga from "./pages/RiwayatKeluarga";
import AddRiwayatKeluarga from "./pages/AddRiwayatKeluarga";
import AddRiwayatPrestasi from "./pages/AddRiwayatPrestasi";
import EditRiwayatKeluarga from "./pages/EditRiwayatKeluarga";
import RiwayatJabatan from "./pages/RiwayatJabatan";
import RiwayatPrestasi from "./pages/RiwayatPrestasi";
import AddRiwayatJabatan from "./pages/AddRiwayatJabatan";
import EditRiwayatJabatan from "./pages/EditRiwayatJabatan";
import LogActivity from "./pages/LogActivity";
import MultipleUpload from "./components/MultipleUpload";
import RiwayatRaport from "./pages/RiwayatRaport";
import AddRiwayatRaport from "./pages/AddRiwayatRaport";
import EditRiwayatRaport from "./pages/EditRiwayatRaport";
import Pengumuman from "./components/Pengumuman";
import EditRiwayatPrestasi from "./pages/EditRiwayatPrestasi";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/pengumuman" element={<Pengumuman />} />
          <Route path="/" exact={true} element={<Login />} />
          <Route path="/multiple" element={<MultipleUpload />} />
          <Route path="/dashboard" element={<Dasboard />} />
          <Route path="/data-person" element={<DataPerson />} />
          <Route
            path="/data-person-all-pegawai"
            element={<DataPersonAllPegawai />}
          />
          <Route path="/pegawais" element={<Pegawai />} />
          <Route path="/pegawais/add" element={<AddPegawai />} />
          <Route path="/pegawais/edit/:id" element={<EditPegawai />} />
          <Route path="/riwayat-pendidikan" element={<RiwayatPendidikan />} />
          <Route
            path="/riwayat-pendidikan/add"
            element={<AddRiwayatPendidikan />}
          />
          <Route
            path="/riwayat-pendidikan/edit/:id"
            element={<EditRiwayatPendidikan />}
          />
          <Route path="/riwayat-pelatihan" element={<RiwayatPelatihan />} />
          <Route
            path="/riwayat-pelatihan/add"
            element={<AddRiwayatPelatihan />}
          />
          <Route
            path="/riwayat-pelatihan/edit/:id"
            element={<EditRiwayatPelatihan />}
          />
          <Route path="/riwayat-kepangkatan" element={<RiwayatKepangkatan />} />
          <Route
            path="/riwayat-kepangkatan/add"
            element={<AddRiwayatKepangkatan />}
          />
          <Route
            path="/riwayat-kepangkatan/edit/:id"
            element={<EditRiwayatKepangkatan />}
          />
          <Route path="/riwayat-jabatan" element={<RiwayatJabatan />} />
          <Route path="/riwayat-jabatan/add" element={<AddRiwayatJabatan />} />
          <Route
            path="/riwayat-jabatan/edit/:id"
            element={<EditRiwayatJabatan />}
          />
          <Route path="/riwayat-keluarga" element={<RiwayatKeluarga />} />
          <Route
            path="/riwayat-keluarga/add"
            element={<AddRiwayatKeluarga />}
          />
          <Route
            path="/riwayat-keluarga/edit/:id"
            element={<EditRiwayatKeluarga />}
          />
          <Route path="/log-activity" element={<LogActivity />} />
          <Route path="/riwayat-raport" element={<RiwayatRaport />} />
          <Route path="/riwayat-raport/add" element={<AddRiwayatRaport />} />
          <Route
            path="/riwayat-raport/edit/:id"
            element={<EditRiwayatRaport />}
          />

          <Route path="/riwayat-prestasi" element={<RiwayatPrestasi />} />
          <Route path="/riwayat-prestasi/add" element={<AddRiwayatPrestasi />} />
          <Route
            path="/riwayat-prestasi/edit/:id"
            element={<EditRiwayatPrestasi />}
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
