import React, { useState, useEffect } from "react";
import "./globalList.scss";
import axios from "axios";
import Url from "../global";

const TableDashboardDetail = () => {
  const [LakiPaud1Guru, setLakiPaud1Guru] = useState([]);
  const [LakiPaud1, setLakiPaud1] = useState([]);
  const [PerempuanPaud1Guru, setPerempuanPaud1Guru] = useState([]);
  const [PerempuanPaud1, setPerempuanPaud1] = useState([]);
  const [LakiPaud2Guru, setLakiPaud2Guru] = useState([]);
  const [LakiPaud2, setLakiPaud2] = useState([]);
  const [PerempuanPaud2Guru, setPerempuanPaud2Guru] = useState([]);
  const [PerempuanPaud2, setPerempuanPaud2] = useState([]);
  const [LakiSDIT1Guru, setLakiSDIT1Guru] = useState([]);
  const [LakiSDIT1, setLakiSDIT1] = useState([]);
  const [PerempuanSDIT1Guru, setPerempuanSDIT1Guru] = useState([]);
  const [PerempuanSDIT1, setPerempuanSDIT1] = useState([]);
  const [LakiSDIT2Guru, setLakiSDIT2Guru] = useState([]);
  const [LakiSDIT2, setLakiSDIT2] = useState([]);
  const [PerempuanSDIT2Guru, setPerempuanSDIT2Guru] = useState([]);
  const [PerempuanSDIT2, setPerempuanSDIT2] = useState([]);
  const [LakiSMPIGuru, setLakiSMPIGuru] = useState([]);
  const [LakiSMPI, setLakiSMPI] = useState([]);
  const [PerempuanSMPIGuru, setPerempuanSMPIGuru] = useState([]);
  const [PerempuanSMPI, setPerempuanSMPI] = useState([]);
  const [LAKISMAITGuru, setLakiSMAITGuru] = useState([]);
  const [LAKISMAIT, setLakiSMAIT] = useState([]);
  const [PerempuanSMAITGuru, setPerempuanSMAITGuru] = useState([]);
  const [PerempuanSMAIT, setPerempuanSMAIT] = useState([]);
  // const [LAKIMTSGuru, setLakiMTSGuru] = useState([]);
  // const [LAKIMTS, setLakiMTS] = useState([]);
  // const [PerempuanMTSGuru, setPerempuanMTSGuru] = useState([]);
  // const [PerempuanMTS, setPerempuanMTS] = useState([]);
  const [LAKIMAGuru, setLakiMAGuru] = useState([]);
  const [LAKIMA, setLakiMA] = useState([]);
  const [PerempuanMAGuru, setPerempuanMAGuru] = useState([]);
  const [PerempuanMA, setPerempuanMA] = useState([]);
  const [LAKIMnj, setLakiMnj] = useState([]);
  const [PerempuanMNJ, setPerempuanMNJ] = useState([]);

  useEffect(() => {
    getLakiPaud1();
    getPerempuanPaud1();
    getLakiPaud2();
    getPerempuanPaud2();
    getLakiSDIT1();
    getPerempuanSDIT1();
    getLakiSDIT2();
    getPerempuanSDIT2();
    getLakiSMPI();
    getPerempuanSMPI();
    getLakiSMAIT();
    getPerempuanSMAIT();
    getLakiMA();
    getPerempuanMA();
    // getLakiMTS();
    // getPerempuanMTS();
    getLakiManajemen();
    getPerempuanManajemen();
    getLakiPaud1Guru();
    getPerempuanPaud1Guru();
    getLakiPaud2Guru();
    getPerempuanPaud2Guru();
    getLakiSDIT1Guru();
    getPerempuanSDIT1Guru();
    getLakiSDIT2Guru();
    getPerempuanSDIT2Guru();
    getLakiSMPIGuru();
    getPerempuanSMPIGuru();
    getLakiMAGuru();
    getPerempuanMAGuru();
    // getLakiMTSGuru();
    // getPerempuanMTSGuru();
    getLakiSMAITGuru();
    getPerempuanSMAITGuru();
  }, []);

  const getLakiPaud1Guru = async () => {
    const response = await axios.get(`${Url}/dashboard/LakiPAUD1Guru`, {
      credentials: "include",
    });
    setLakiPaud1Guru(response.data);
  };

  const getLakiPaud1 = async () => {
    const response = await axios.get(`${Url}/dashboard/LakiPAUD1`, {
      credentials: "include",
    });
    setLakiPaud1(response.data);
  };

  const getPerempuanPaud1Guru = async () => {
    const response = await axios.get(`${Url}/dashboard/PerempuanPAUD1Guru`, {
      credentials: "include",
    });
    setPerempuanPaud1Guru(response.data);
  };

  const getPerempuanPaud1 = async () => {
    const response = await axios.get(`${Url}/dashboard/PerempuanPAUD1`, {
      credentials: "include",
    });
    setPerempuanPaud1(response.data);
  };

  const getLakiPaud2Guru = async () => {
    const response = await axios.get(`${Url}/dashboard/LakiPAUD2Guru`, {
      credentials: "include",
    });
    setLakiPaud2Guru(response.data);
  };

  const getLakiPaud2 = async () => {
    const response = await axios.get(`${Url}/dashboard/LakiPAUD2`, {
      credentials: "include",
    });
    setLakiPaud2(response.data);
  };

  const getPerempuanPaud2Guru = async () => {
    const response = await axios.get(`${Url}/dashboard/PerempuanPAUD2Guru`, {
      credentials: "include",
    });
    setPerempuanPaud2Guru(response.data);
  };

  const getPerempuanPaud2 = async () => {
    const response = await axios.get(`${Url}/dashboard/PerempuanPAUD2`, {
      credentials: "include",
    });
    setPerempuanPaud2(response.data);
  };

  const getLakiSDIT1Guru = async () => {
    const response = await axios.get(`${Url}/dashboard/LakiSDIT1Guru`, {
      credentials: "include",
    });
    setLakiSDIT1Guru(response.data);
  };
  const getLakiSDIT1 = async () => {
    const response = await axios.get(`${Url}/dashboard/LakiSDIT1`, {
      credentials: "include",
    });
    setLakiSDIT1(response.data);
  };

  const getPerempuanSDIT1Guru = async () => {
    const response = await axios.get(`${Url}/dashboard/PerempuanSDIT1Guru`, {
      credentials: "include",
    });
    setPerempuanSDIT1Guru(response.data);
  };
  const getPerempuanSDIT1 = async () => {
    const response = await axios.get(`${Url}/dashboard/PerempuanSDIT1`, {
      credentials: "include",
    });
    setPerempuanSDIT1(response.data);
  };

  const getLakiSDIT2Guru = async () => {
    const response = await axios.get(`${Url}/dashboard/LakiSDIT2Guru`, {
      credentials: "include",
    });
    setLakiSDIT2Guru(response.data);
  };

  const getLakiSDIT2 = async () => {
    const response = await axios.get(`${Url}/dashboard/LakiSDIT2`, {
      credentials: "include",
    });
    setLakiSDIT2(response.data);
  };

  const getPerempuanSDIT2Guru = async () => {
    const response = await axios.get(`${Url}/dashboard/PerempuanSDIT2Guru`, {
      credentials: "include",
    });
    setPerempuanSDIT2Guru(response.data);
  };

  const getPerempuanSDIT2 = async () => {
    const response = await axios.get(`${Url}/dashboard/PerempuanSDIT2`, {
      credentials: "include",
    });
    setPerempuanSDIT2(response.data);
  };

  const getLakiSMPIGuru = async () => {
    const response = await axios.get(`${Url}/dashboard/LakiSMPIGuru`, {
      credentials: "include",
    });
    setLakiSMPIGuru(response.data);
  };

  const getLakiSMPI = async () => {
    const response = await axios.get(`${Url}/dashboard/LakiSMPI`, {
      credentials: "include",
    });
    setLakiSMPI(response.data);
  };

  const getPerempuanSMPIGuru = async () => {
    const response = await axios.get(`${Url}/dashboard/PerempuanSMPIGuru`, {
      credentials: "include",
    });
    setPerempuanSMPIGuru(response.data);
  };

  const getPerempuanSMPI = async () => {
    const response = await axios.get(`${Url}/dashboard/PerempuanSMPI`, {
      credentials: "include",
    });
    setPerempuanSMPI(response.data);
  };

  const getLakiSMAITGuru = async () => {
    const response = await axios.get(`${Url}/dashboard/LakiSMAITGuru`, {
      credentials: "include",
    });
    setLakiSMAITGuru(response.data);
  };

  const getLakiSMAIT = async () => {
    const response = await axios.get(`${Url}/dashboard/LakiSMAIT`, {
      credentials: "include",
    });
    setLakiSMAIT(response.data);
  };

  const getPerempuanSMAITGuru = async () => {
    const response = await axios.get(`${Url}/dashboard/PerempuanSMAITGuru`, {
      credentials: "include",
    });
    setPerempuanSMAITGuru(response.data);
  };

  const getPerempuanSMAIT = async () => {
    const response = await axios.get(`${Url}/dashboard/PerempuanSMAIT`, {
      credentials: "include",
    });
    setPerempuanSMAIT(response.data);
  };

  // const getLakiMTSGuru = async () => {
  //   const response = await axios.get(`${Url}/dashboard/LakiMTSGuru`, {
  //     credentials: "include",
  //   });
  //   setLakiMTSGuru(response.data);
  // };

  // const getLakiMTS = async () => {
  //   const response = await axios.get(`${Url}/dashboard/LakiMTS`, {
  //     credentials: "include",
  //   });
  //   setLakiMTS(response.data);
  // };

  // const getPerempuanMTSGuru = async () => {
  //   const response = await axios.get(`${Url}/dashboard/PerempuanMTSGuru`, {
  //     credentials: "include",
  //   });
  //   setPerempuanMTSGuru(response.data);
  // };

  // const getPerempuanMTS = async () => {
  //   const response = await axios.get(`${Url}/dashboard/PerempuanMTS`, {
  //     credentials: "include",
  //   });
  //   setPerempuanMTS(response.data);
  // };

  const getLakiMAGuru = async () => {
    const response = await axios.get(`${Url}/dashboard/LakiMAGuru`, {
      credentials: "include",
    });
    setLakiMAGuru(response.data);
  };

  const getLakiMA = async () => {
    const response = await axios.get(`${Url}/dashboard/LakiMA`, {
      credentials: "include",
    });
    setLakiMA(response.data);
  };

  const getPerempuanMAGuru = async () => {
    const response = await axios.get(`${Url}/dashboard/PerempuanMAGuru`, {
      credentials: "include",
    });
    setPerempuanMAGuru(response.data);
  };

  const getPerempuanMA = async () => {
    const response = await axios.get(`${Url}/dashboard/PerempuanMA`, {
      credentials: "include",
    });
    setPerempuanMA(response.data);
  };

  const getLakiManajemen = async () => {
    const response = await axios.get(`${Url}/dashboard/LakiManajemen`, {
      credentials: "include",
    });
    setLakiMnj(response.data);
  };

  const getPerempuanManajemen = async () => {
    const response = await axios.get(`${Url}/dashboard/PerempuanManajemen`, {
      credentials: "include",
    });
    setPerempuanMNJ(response.data);
  };

  return (
    <div>
      <div className="table__wrapper">
        <table
          className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth"
          id="table-to-xls"
        >
          <thead className="dashboardtablehead">
            <tr className="has-text-centered">
              <th className="has-text-centered" rowSpan="2" width="10px">
                No
              </th>
              <th className="has-text-centered" rowSpan="2">
                Nama Unit
              </th>
              <th className="has-text-centered" colspan="2">
                Jenis Kelamin Guru
              </th>
              <th className="has-text-centered" colspan="2">
                Jenis Kelamin Karyawan
              </th>
            </tr>
            <tr>
              <th className="has-text-centered">Laki - Laki</th>
              <th className="has-text-centered">Perempuan</th>
              <th className="has-text-centered">Laki - Laki</th>
              <th className="has-text-centered">Perempuan</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1.</td>
              <td>PAUD IT Al Hasanah 1</td>
              <td className="has-text-centered">{LakiPaud1Guru}</td>
              <td className="has-text-centered">{PerempuanPaud1Guru}</td>
              <td className="has-text-centered">{LakiPaud1}</td>
              <td className="has-text-centered">{PerempuanPaud1}</td>
            </tr>
            <tr>
              <td>2.</td>
              <td>PAUD IT Al Hasanah 2</td>
              <td className="has-text-centered">{LakiPaud2Guru}</td>
              <td className="has-text-centered">{PerempuanPaud2Guru}</td>
              <td className="has-text-centered">{LakiPaud2}</td>
              <td className="has-text-centered">{PerempuanPaud2}</td>
            </tr>

            <tr>
              <td>3.</td>
              <td>SDIT Al Hasanah 1</td>
              <td className="has-text-centered">{LakiSDIT1Guru}</td>
              <td className="has-text-centered">{PerempuanSDIT1Guru}</td>
              <td className="has-text-centered">{LakiSDIT1}</td>
              <td className="has-text-centered">{PerempuanSDIT1}</td>
            </tr>
            <tr>
              <td>4.</td>
              <td>SDIT Al Hasanah 2</td>
              <td className="has-text-centered">{LakiSDIT2Guru}</td>
              <td className="has-text-centered">{PerempuanSDIT2Guru}</td>
              <td className="has-text-centered">{LakiSDIT2}</td>
              <td className="has-text-centered">{PerempuanSDIT2}</td>
            </tr>
            <tr>
              <td>5.</td>
              <td>SMPI Al Hasanah</td>
              <td className="has-text-centered">{LakiSMPIGuru}</td>
              <td className="has-text-centered">{PerempuanSMPIGuru}</td>
              <td className="has-text-centered">{LakiSMPI}</td>
              <td className="has-text-centered">{PerempuanSMPI}</td>
            </tr>

            <tr>
              <td>6.</td>
              <td>SMAIT Al Hasanah</td>
              <td className="has-text-centered">{LAKISMAITGuru}</td>
              <td className="has-text-centered">{LAKISMAIT}</td>
              <td className="has-text-centered">{PerempuanSMAITGuru}</td>
              <td className="has-text-centered">{PerempuanSMAIT}</td>
            </tr>

     

            <tr>
              <td>7.</td>
              <td>Pondok Pesantren</td>
              <td className="has-text-centered">{LAKIMAGuru}</td>
              <td className="has-text-centered">{PerempuanMAGuru}</td>
              <td className="has-text-centered">{LAKIMA}</td>
              <td className="has-text-centered">{PerempuanMA}</td>
            </tr>

            <tr>
              <td>8.</td>
              <td>Manajemen Al Hasanah</td>
              <td className="has-text-centered">-</td>
              <td className="has-text-centered">-</td>
              <td className="has-text-centered">{LAKIMnj}</td>
              <td className="has-text-centered">{PerempuanMNJ}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TableDashboardDetail;
