import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Url from "../global";
import "./globalList.scss";

const PegawaiList = () => {
  const [pegawais, setPegawais] = useState([]);
  const [query, setQuery] = useState("");
  const [query2, setQuery2] = useState("");

  useEffect(() => {
    getPegawais();
  }, []);

  const getPegawais = async () => {
    const response = await axios.get(`${Url}/pegawai`, {
      credentials: "include",
    })
    setPegawais(response.data);
  };

  const deletePegawai = async (pegawaiId) => {
    await axios.delete(`${Url}/pegawai/${pegawaiId}`, {
      credentials: "include",
    });
    getPegawais();
  };

  return (
    <div>
      <h1 className="title">Pegawai</h1>
      <h2 className="subtitle">List Of Pegawai</h2>
      {/* <Select options={options} onChange={handleFilterInput} value={selectedValue} placeholder="Select a region"/> */}
      <Link to="/pegawais/add" className="button is-primary mb-2">
        Add New Pegawai
      </Link>

      <br />
      <label> Masukan Nama </label>
      <input
        className="search"
        placeholder="Cari Nama Individu"
        onChange={(e) => setQuery(e.target.value.toLowerCase())}
      />

      <label className="label-unit">Masukan Unit </label>
      <input
        className="search"
        placeholder="Cari Berdasarkan Unit"
        onChange={(e) => setQuery2(e.target.value.toLowerCase())}
      />


      <div className="table__wrapper_Scroll_Log">
        <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
          <thead>
            <tr className="has-text-centered">
              <th className="has-text-centered">No</th>
              <th className="has-text-centered">Nama</th>
              <th className="has-text-centered">Email</th>
              <th className="has-text-centered">Unit</th>
              <th className="has-text-centered">Role</th>
              <th className="has-text-centered">Action</th>
            </tr>
          </thead>
          <tbody>
            {pegawais
            .filter(
              (pegawai) =>
              pegawai.strNama.toLowerCase().includes(query) &&
              pegawai.strUnit.toLowerCase().includes(query2)
            ).map((pegawai, index) => (
              <tr key={pegawai.uuid}>
                <td className="has-text-centered" width="5px">
                  {index + 1}
                </td>
                <td>{pegawai.strNama}</td>
                <td>{pegawai.strEmail}</td>
                <td className="has-text-centered">{pegawai.strUnit}</td>
                <td className="has-text-centered">{pegawai.strRole}</td>
                <td className="has-text-centered">
                  <Link
                    to={`/pegawais/edit/${pegawai.uuid}`}
                    className="button is-small is-info"
                  >
                    Edit
                  </Link>
                  <button
                    onClick={() => deletePegawai(pegawai.uuid)}
                    className="button is-small is-danger"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PegawaiList;
