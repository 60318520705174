import React from "react";
import Layout from './Layout'
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getMe } from "../features/authSlice";
import FormEditRiwayatKeluarga from "../components/FormEditRiwayatKeluarga";
import { useEffect } from "react";

const EditRiwayatKeluarga = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isError } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getMe());
  }, [dispatch]);

  useEffect(() => {
    if (isError) {
      navigate("/");
    }
  }, [isError, navigate]);
  return (
    <Layout>
      <FormEditRiwayatKeluarga />
    </Layout>
  );
};

export default EditRiwayatKeluarga;
