import axios from "axios";
import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import "./Style/globalValidasiEror.scss";
import Url from "../global";

const FormAddRiwayatKeluarga = () => {
  const [namaKeluarga, setNamaKeluarga] = useState("");
  const [statusKeluarga, setStatusKeluarga] = useState("");
  const [tempatLahir, setTempatLahir] = useState("");
  const [tanggalLahir, setTanggalLahir] = useState("");
  const [pekerjaan, setPekerjaan] = useState("");
  const [detailPekerjaan, setDetailPekerjaan] = useState("");
  const [noBPJSKeluarga, setnoBPJSKeluarga] = useState("");
  const [msg, setMsg] = useState("");
  const navigate = useNavigate();

  const [status, setStatus] = useState("");
  const [file, setFile] = useState(null);
  const [fileUrl, setFileUrl] = useState(null);

  const [setError] = useState(null);

  const PdfViewer = ({ fileUrl }) => {
    return (
      <div>
        {fileUrl ? (
          <embed
            src={fileUrl}
            type="application/pdf"
            width="70%"
            height="600px"
          />
        ) : (
          <p>No PDF file uploaded</p>
        )}
      </div>
    );
  };

  const handleChange = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile && selectedFile.type === "application/pdf") {
      setFile(selectedFile);
      setFileUrl(URL.createObjectURL(selectedFile));
    } else {
      setFile(null);
      setError("File harus berupa PDF");
    }
  };

  const {
    register,
    reset,
    trigger,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const saveRiwayatKeluarga = async (e) => {
    //e.preventDefault();
    if (file) {
      const formData = new FormData();
      formData.append("strNamaKeluarga", namaKeluarga);
      formData.append("strStatusKeluarga", statusKeluarga);
      formData.append("dateTanggalLahir", tanggalLahir);
      formData.append("strTempatLahir", tempatLahir);
      formData.append("strPekerjaan", pekerjaan);
      formData.append("strDetailPekerjaan", detailPekerjaan);
      formData.append("strNoBPJSKeluarga", noBPJSKeluarga);
      formData.append("strStatus", status);
      formData.append("pdfFile", file);

      for (let i = 0; i < file.length; i++) {
        formData.append("files", file[i]);
      }

      try {
        await axios.post(`${Url}/riwayat_keluargas`, formData, {
          headers: {
            "Content-type": "multipart/form-data",
          },
        });
        navigate("/riwayat-keluarga");
        reset();
      } catch (error) {
        if (error.response) {
          setMsg(error.response.data.msg);
        }
      }
    }
  }

    return (
      <div>
        <h1 className="title">Riwayat Keluarga</h1>
        <h2 className="subtitle">Add New Riwayat Keluarga</h2>

        <div className="card is-shadowless">
          <div className="card-content">
            <div className="content">
              <form
                className="box"
                onSubmit={handleSubmit(saveRiwayatKeluarga)}
              >
                <p className="has-text-centered">{msg}</p>

                <div className="field">
                  <label className="label">Status Keluarga</label>
                  <div className="control">
                    <div className="select is-fullwidth">
                      <select
                        {...register("statusKeluarga", {
                          required: {
                            value: true,
                            message: "Status Keluarga Wajib Di isi!",
                          },
                        })}
                        className="dropdown"
                        value={statusKeluarga}
                        onChange={(e) => setStatusKeluarga(e.target.value)}
                      >
                        <option>--Pilihan--</option>
                        <option value="Suami">Suami</option>
                        <option value="Istri">Istri</option>
                        <option value="Anak">Anak</option>
                      </select>
                      {errors.statusKeluarga && (
                        <small className="allValidasiError">
                          {errors.statusKeluarga.message}
                        </small>
                      )}
                    </div>
                  </div>
                </div>

                <div className="field">
                  <label className="label">Nama Lengkap</label>
                  <div className="control">
                    <input
                      type="text"
                      className={`input ${errors.namaKeluarga && "invalid"}`}
                      {...register("namaKeluarga", {
                        required: {
                          value: true,
                          message: "Nama Lengkap Wajib Di isi!",
                        },
                        minLength: {
                          value: 3,
                          message: "minimal 3 karakter",
                        },
                      })}
                      onKeyUp={() => {
                        trigger("namaKeluarga");
                      }}
                      placeholder="Nama Lengkap"
                      value={namaKeluarga}
                      onChange={(e) => setNamaKeluarga(e.target.value)}
                    />

                    {errors.namaKeluarga && (
                      <small className="allValidasiError">
                        {errors.namaKeluarga.message}
                      </small>
                    )}
                  </div>
                </div>

                <div className="field">
                  <label className="label">Tempat Lahir</label>
                  <div className="control">
                    <input
                      type="text"
                      className={`input ${errors.tempatLahir && "invalid"}`}
                      {...register("tempatLahir", {
                        required: {
                          value: true,
                          message: "Tempat Lahir Wajib Di isi!",
                        },
                        minLength: {
                          value: 5,
                          message: "minimal 5 karakter",
                        },
                      })}
                      onKeyUp={() => {
                        trigger("tempatLahir");
                      }}
                      placeholder="Tempat Lahir"
                      value={tempatLahir}
                      onChange={(e) => setTempatLahir(e.target.value)}
                    />
                    {errors.tempatLahir && (
                      <small className="allValidasiError">
                        {errors.tempatLahir.message}
                      </small>
                    )}
                  </div>
                </div>

                <div className="field">
                  <label className="label">Tanggal Lahir</label>
                  <div className="control">
                    <Form.Group controlId="duedate" className="dtp">
                      <Form.Control
                        type="date"
                        className={`duedate ${
                          errors.tanggalLahir && "invalid"
                        }`}
                        {...register("tanggalLahir", {
                          required: {
                            value: true,
                            message: "Pilih Sesuai Tanggal Lahir!",
                          },
                        })}
                        onKeyUp={() => {
                          trigger("tanggalLahir");
                        }}
                        placeholder="Tanggal Lahir"
                        value={tanggalLahir}
                        onChange={(e) => setTanggalLahir(e.target.value)}
                      />
                      <br />
                      {errors.tanggalLahir && (
                        <small className="allValidasiError">
                          {errors.tanggalLahir.message}
                        </small>
                      )}
                    </Form.Group>
                  </div>
                </div>

                <div className="field">
                  <label className="label">Pekerjaan</label>
                  <div className="control">
                    <input
                      type="text"
                      className="input"
                      placeholder="pekerjaan"
                      value={pekerjaan}
                      onChange={(e) => setPekerjaan(e.target.value)}
                    />
                  </div>
                </div>

                <div className="field">
                  <label className="label">Detail Pekerjaan / instansi</label>
                  <div className="control">
                    <input
                      type="text"
                      className="input"
                      placeholder="Detail Pekerjaan"
                      value={detailPekerjaan}
                      onChange={(e) => setDetailPekerjaan(e.target.value)}
                    />
                  </div>
                </div>
                <div className="field">
                  <label className="label">Nomor BPJS Keluarga</label>
                  <div className="control">
                    <input
                      type="number"
                      className={`input ${errors.noBPJSKeluarga && "invalid"}`}
                      {...register("noBPJSKeluarga", {
                        required: {
                          value: true,
                          message: "Nomor BPJS Wajib Di isi!",
                        },
                      })}
                      onKeyUp={() => {
                        trigger("noBPJSKeluarga");
                      }}
                      placeholder="Nomor BPJS"
                      value={noBPJSKeluarga}
                      onChange={(e) => setnoBPJSKeluarga(e.target.value)}
                    />

                    {errors.noBPJSKeluarga && (
                      <small className="allValidasiError">
                        {errors.noBPJSKeluarga.message}
                      </small>
                    )}
                  </div>
                </div>
                <div className="field">
                  <label className="label">Status Keluarga</label>
                  <div className="control">
                    <div className="select is-fullwidth">
                      <select
                        className="dropdown"
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                        required
                        disabled="true"
                      >
                        <option>Pengajuan Anggota Keluarga</option>
                        <option>Disetujui Kabid Kepegawaian</option>
                        <option>Ditolak Kabid Kepegawaian</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="field">
                  <label className="label">Akta Kelahiran</label>
                  <div className="control">
                    <div className="file">
                      <label className="file-label">
                        <input
                          type="file"
                          accept="application/pdf"
                          onChange={handleChange}
                        />
                        {/* <button type="submit">Upload</button> */}
                      </label>
                    </div>
                    <br />
                    <PdfViewer fileUrl={fileUrl} />
                  </div>
                </div>
                <br />

                <div className="field">
                  <div className="control">
                    <button type="submit" className="button is-success">
                      Save
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  };


export default FormAddRiwayatKeluarga;
