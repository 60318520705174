import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Url from "../global";
//import { useForm } from "react-hook-form";
import "./Style/globalValidasiEror.scss";

const FormEditRiwayatKeluarga = () => {
  const [namaKeluarga, setNamaKeluarga] = useState("");
  const [statusKeluarga, setStatusKeluarga] = useState("");
  const [tempatLahir, setTempatLahir] = useState("");
  const [tanggalLahir, setTanggalLahir] = useState("");
  const [pekerjaan, setPekerjaan] = useState("");
  const [detailPekerjaan, setDetailPekerjaan] = useState("");
  const [noBPJSKeluarga, setnoBPJSKeluarga] = useState("");
  const [msg, setMsg] = useState("");
  const navigate = useNavigate();
  const [file, setFile] = useState("");
  const [setError] = useState(null);
  const [setFileUrl] = useState("");
  const [status, setStatus] = useState("");
  const [komentar, setKomentar] = useState("");
  const { id } = useParams();
  const { pegawai } = useSelector((state) => state.auth);

  const PdfViewer = ({ file }) => {
    return (
      <div>
        {file ? (
          <embed src={file} type="application/pdf" width="80%" height="600px" />
        ) : (
          <p>No PDF file uploaded</p>
        )}
      </div>
    );
  };

  const handleChange = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile && selectedFile.type === "application/pdf") {
      setFile(selectedFile);
      setFileUrl(URL.createObjectURL(selectedFile));
    } else {
      setFile(null);
      setError("File harus berupa PDF");
    }
  };

  useEffect(() => {
    const getRiwayatKeluargaById = async () => {
      try {
        const response = await axios.get(`${Url}/riwayat_keluargas/${id}`);
        setNamaKeluarga(response.data.strNamaKeluarga);
        setStatusKeluarga(response.data.strStatusKeluarga);
        setTempatLahir(response.data.strTempatLahir);
        setTanggalLahir(response.data.dateTanggalLahir);
        setPekerjaan(response.data.strPekerjaan);
        setDetailPekerjaan(response.data.strDetailPekerjaan);
        setnoBPJSKeluarga(response.data.strNoBPJSKeluarga);
        setStatus(response.data.strStatus);
        setKomentar(response.data.strKomentar);
        setFile(response.data.url);
      } catch (error) {
        if (error.response) {
          setMsg(error.response.data.msg);
        }
      }
    };
    getRiwayatKeluargaById();
  }, [id]);

  const updateRiwayatKeluarga = async (e) => {
    e.preventDefault();
    try {
      await axios.patch(
        `${Url}/riwayat_Keluargas/${id}`,
        {
          strNamaKeluarga: namaKeluarga,
          strStatusKeluarga: statusKeluarga,
          dateTanggalLahir: tanggalLahir,
          strTempatLahir: tempatLahir,
          strPekerjaan: pekerjaan,
          strDetailPekerjaan: detailPekerjaan,
          strNoBPJSKeluarga: noBPJSKeluarga,
          url: file,
          strStatus: status,
          strKomentar: komentar,
        },
        {
          headers: {
            "Content-type": "multipart/form-data",
          },
        }
      );
      navigate("/riwayat-keluarga");
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };

  return (
    <div>
      <h1 className="title">Riwayat Keluarga</h1>
      <h2 className="subtitle">Edit Riwayat Keluarga</h2>

      <div className="card is-shadowless">
        <div className="card-content">
          <div className="content">
            <form className="box" onSubmit={updateRiwayatKeluarga}>
              <p className="has-text-centered">{msg}</p>

              <div className="field">
                <label className="label">Nama Lengkap Keluarga</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    placeholder="Nama Lengkap"
                    value={namaKeluarga}
                    onChange={(e) => setNamaKeluarga(e.target.value)}
                  />

                  {/* {errors.namaKeluarga && (
                    <small className="allValidasiError">
                      {errors.namaKeluarga.message}
                    </small>
                  )} */}
                </div>
              </div>

              <div className="field">
                <label className="label">Status Keluarga</label>
                <div className="control">
                  <div className="select is-fullwidth">
                    <select
                      // {...register("statusKeluarga", {
                      //   required: {
                      //     value: true,
                      //     message: "Status Keluarga Wajib Di isi!",
                      //   },
                      // })}
                      className="dropdown"
                      value={statusKeluarga}
                      onChange={(e) => setStatusKeluarga(e.target.value)}
                    >
                      <option>--Pilihan--</option>
                      <option value="Suami">Suami</option>
                      <option value="Istri">Istri</option>
                      <option value="Anak">Anak</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="field">
                <label className="label">Tempat Lahir</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    placeholder="Tempat Lahir"
                    value={tempatLahir}
                    onChange={(e) => setTempatLahir(e.target.value)}
                  />
                  {/* {errors.tempatLahir && (
                    <small className="allValidasiError">
                      {errors.tempatLahir.message}
                    </small>
                  )} */}
                </div>
              </div>

              <div className="field">
                <label className="label">Tanggal Lahir</label>
                <div className="control">
                  <Form.Group controlId="duedate" className="dtp">
                    <Form.Control
                      type="date"
                      name="duedate"
                      placeholder="Tanggal Lahir"
                      value={tanggalLahir}
                      onChange={(e) => setTanggalLahir(e.target.value)}
                    />
                  </Form.Group>
                </div>
              </div>

              <div className="field">
                <label className="label">Pekerjaan</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    placeholder="Pekerjaan"
                    value={pekerjaan}
                    onChange={(e) => setPekerjaan(e.target.value)}
                  />
                </div>
              </div>

              <div className="field">
                <label className="label">Detail Pekerjaan</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    placeholder="Detail Pekerjaan"
                    value={detailPekerjaan}
                    onChange={(e) => setDetailPekerjaan(e.target.value)}
                  />
                </div>
              </div>

              <div className="field">
                <label className="label">Nomor BPJS Keluarga</label>
                <div className="control">
                  <input
                    type="number"
                    className="input"
                    placeholder="Nomor BPJS"
                    value={noBPJSKeluarga}
                    onChange={(e) => setnoBPJSKeluarga(e.target.value)}
                  />
                </div>
              </div>

              <div className="field">
                <label className="label">Status Keluarga</label>
                <div className="control">
                  <div className="select is-fullwidth">
                    {pegawai && pegawai.strRole === "user" && (
                      <select
                        className="dropdown"
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                        required
                        disabled="true"
                      >
                        <option>Pengajuan Sertifikat Keluarga</option>
                        <option>Disetujui Kabid Kepegawaian</option>
                        <option>Ditolak Kabid Kepegawaian</option>
                      </select>
                    )}
                    {pegawai &&
                      (pegawai.strRole === "kabid kepegawaian" ||
                        pegawai.strRole === "admin") && (
                        <select
                          className="dropdown"
                          value={status}
                          onChange={(e) => setStatus(e.target.value)}
                          required
                        >
                          <option>Pengajuan Anggota Keluarga</option>
                          <option>Disetujui Kabid Kepegawaian</option>
                          <option>Ditolak Kabid Kepegawaian</option>
                        </select>
                      )}
                  </div>
                </div>
              </div>

              <div className="field">
                <label className="label">Komentar</label>
                <div className="control">
                  {pegawai &&
                    (pegawai.strRole === "kabid kepegawaian" ||
                      pegawai.strRole === "admin") && (
                      <input
                        type="text"
                        className="input"
                        placeholder="Komentar"
                        value={komentar}
                        onChange={(e) => setKomentar(e.target.value)}
                      />
                    )}
                  {pegawai && pegawai.strRole === "user" && (
                    <input
                      type="text"
                      className="input"
                      placeholder="Komentar"
                      value={komentar}
                      onChange={(e) => setKomentar(e.target.value)}
                      disabled
                    />
                  )}
                </div>
              </div>
              <div className="field">
                <label className="label">Image</label>
                <div className="control">
                  <div className="file">
                    <label className="file-label">
                      <input
                        type="file"
                        accept="application/pdf"
                        onChange={handleChange}
                        name="file"
                      />
                      {/* <button type="submit">Upload</button> */}
                    </label>
                    {setError && <p className="error">{setError}</p>}
                  </div>
                  <br />
                  <PdfViewer file={file} />
                </div>
              </div>

              <br />

              <div className="field">
                <div className="control">
                  <button type="submit" className="button is-success">
                    Update
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormEditRiwayatKeluarga;
