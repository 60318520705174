import React, { useState, useEffect } from "react";
import axios from "axios";
import Url from "../global";
import "./globalList.scss";

const TableDashboardJumlahGuruPertingkatPendidikan = () => {
  //Paud1
  const [SMPPaud1, setSMPPaud1] = useState([]);
  const [SMAPaud1, setSMAPaud1] = useState([]);
  const [S1Paud1, setS1Paud1] = useState([]);
  const [S2Paud1, setS2Paud1] = useState([]);
  const [S3Paud1, setS3Paud1] = useState([]);

  //Paud2
  const [SMPPaud2, setSMPPaud2] = useState([]);
  const [SMAPaud2, setSMAPaud2] = useState([]);
  const [S1Paud2, setS1Paud2] = useState([]);
  const [S2Paud2, setS2Paud2] = useState([]);
  const [S3Paud2, setS3Paud2] = useState([]);

  //SDIT1
  const [SMPSDIT1, setSMPSDIT1] = useState([]);
  const [SMASDIT1, setSMASDIT1] = useState([]);
  const [S1SDIT1, setS1SDIT1] = useState([]);
  const [S2SDIT1, setS2SDIT1] = useState([]);
  const [S3SDIT1, setS3SDIT1] = useState([]);

  //SDIT2
  const [SMPSDIT2, setSMPSDIT2] = useState([]);
  const [SMASDIT2, setSMASDIT2] = useState([]);
  const [S1SDIT2, setS1SDIT2] = useState([]);
  const [S2SDIT2, setS2SDIT2] = useState([]);
  const [S3SDIT2, setS3SDIT2] = useState([]);

  //SMPI
  const [SMPSMPI, setSMPSMPI] = useState([]);
  const [SMASMPI, setSMASMPI] = useState([]);
  const [S1SMPI, setS1SMPI] = useState([]);
  const [S2SMPI, setS2SMPI] = useState([]);
  const [S3SMPI, setS3SMPI] = useState([]);

  //SMA
  const [SMPSMA, setSMPSMA] = useState([]);
  const [SMASMA, setSMASMA] = useState([]);
  const [S1SMA, setS1SMA] = useState([]);
  const [S2SMA, setS2SMA] = useState([]);
  const [S3SMA, setS3SMA] = useState([]);

  //MA
  const [SMPMA, setSMPMA] = useState([]);
  const [SMAMA, setSMAMA] = useState([]);
  const [S1MA, setS1MA] = useState([]);
  const [S2MA, setS2MA] = useState([]);
  const [S3MA, setS3MA] = useState([]);

  //MTS
  // const [SMPMTS, setSMPMTS] = useState([]);
  // const [SMAMTS, setSMAMTS] = useState([]);
  // const [S1MTS, setS1MTS] = useState([]);
  // const [S2MTS, setS2MTS] = useState([]);
  // const [S3MTS, setS3MTS] = useState([]);

  //Paud1K
  const [SMPPaud1K, setSMPPaud1K] = useState([]);
  const [SMAPaud1K, setSMAPaud1K] = useState([]);
  const [S1Paud1K, setS1Paud1K] = useState([]);
  const [S2Paud1K, setS2Paud1K] = useState([]);
  const [S3Paud1K, setS3Paud1K] = useState([]);

  //Paud2
  const [SMPPaud2K, setSMPPaud2K] = useState([]);
  const [SMAPaud2K, setSMAPaud2K] = useState([]);
  const [S1Paud2K, setS1Paud2K] = useState([]);
  const [S2Paud2K, setS2Paud2K] = useState([]);
  const [S3Paud2K, setS3Paud2K] = useState([]);

  //SDIT1
  const [SMPSDIT1K, setSMPSDIT1K] = useState([]);
  const [SMASDIT1K, setSMASDIT1K] = useState([]);
  const [S1SDIT1K, setS1SDIT1K] = useState([]);
  const [S2SDIT1K, setS2SDIT1K] = useState([]);
  const [S3SDIT1K, setS3SDIT1K] = useState([]);

  //SDIT2
  const [SMPSDIT2K, setSMPSDIT2K] = useState([]);
  const [SMASDIT2K, setSMASDIT2K] = useState([]);
  const [S1SDIT2K, setS1SDIT2K] = useState([]);
  const [S2SDIT2K, setS2SDIT2K] = useState([]);
  const [S3SDIT2K, setS3SDIT2K] = useState([]);

  //SMPI
  const [SMPSMPIK, setSMPSMPIK] = useState([]);
  const [SMASMPIK, setSMASMPIK] = useState([]);
  const [S1SMPIK, setS1SMPIK] = useState([]);
  const [S2SMPIK, setS2SMPIK] = useState([]);
  const [S3SMPIK, setS3SMPIK] = useState([]);

  //SMA
  const [SMPSMAK, setSMPSMAK] = useState([]);
  const [SMASMAK, setSMASMAK] = useState([]);
  const [S1SMAK, setS1SMAK] = useState([]);
  const [S2SMAK, setS2SMAK] = useState([]);
  const [S3SMAK, setS3SMAK] = useState([]);

  //MA
  const [SMPMAK, setSMPMAK] = useState([]);
  const [SMAMAK, setSMAMAK] = useState([]);
  const [S1MAK, setS1MAK] = useState([]);
  const [S2MAK, setS2MAK] = useState([]);
  const [S3MAK, setS3MAK] = useState([]);

  //MTS
  // const [SMPMTSK, setSMPMTSK] = useState([]);
  // const [SMAMTSK, setSMAMTSK] = useState([]);
  // const [S1MTSK, setS1MTSK] = useState([]);
  // const [S2MTSK, setS2MTSK] = useState([]);
  // const [S3MTSK, setS3MTSK] = useState([]);

  //Manajemen
  const [SMPMNJ, setSMPMNJ] = useState([]);
  const [SMAMNJ, setSMAMNJ] = useState([]);
  const [S1MNJ, setS1MNJ] = useState([]);
  const [S2MNJ, setS2MNJ] = useState([]);
  const [S3MNJ, setS3MNJ] = useState([]);

  useEffect(() => {
    getSMPPaud1G();
    getSMAPaud1G();
    getS1Paud1G();
    getS2Paud1G();
    getS3Paud1G();
    getSMPPaud2G();
    getSMAPaud2G();
    getS1Paud2G();
    getS2Paud2G();
    getS3Paud2G();
    getS1Paud2G();
    getS2Paud2G();
    getS3Paud2G();
    getSMPSDIT1G();
    getSMASDIT1G();
    getS1SDIT1G();
    getS2SDIT1G();
    getS3SDIT1G();
    getSMPSDIT2G();
    getSMASDIT2G();
    getS1SDIT2G();
    getS2SDIT2G();
    getS3SDIT2G();
    getSMPSMPIG();
    getSMASMPIG();
    getS1SMPIG();
    getS2SMPIG();
    getS3SMPIG();
    getSMPSMAITG();
    getSMASMAITG();
    getS1SMAITG();
    getS2SMAITG();
    getS3SMAITG();
    getSMPMAG();
    getSMAMAG();
    getS1MAG();
    getS2MAG();
    getS3MAG();
    // getSMPMTSG();
    // getSMAMTSG();
    // getS1MTSG();
    // getS2MTSG();
    // getS3MTSG();

    //karyawan
    getSMPPaud1K();
    getSMAPaud1K();
    getS1Paud1K();
    getS2Paud1K();
    getS3Paud1K();
    getSMPPaud2K();
    getSMAPaud2K();
    getS1Paud2K();
    getS2Paud2K();
    getS3Paud2K();
    getS1Paud2K();
    getS2Paud2K();
    getS3Paud2K();
    getSMPSDIT1K();
    getSMASDIT1K();
    getS1SDIT1K();
    getS2SDIT1K();
    getS3SDIT1K();
    getSMPSDIT2K();
    getSMASDIT2K();
    getS1SDIT2K();
    getS2SDIT2K();
    getS3SDIT2K();
    getSMPSMPIK();
    getSMASMPIK();
    getS1SMPIK();
    getS2SMPIK();
    getS3SMPIK();
    getSMPSMAITK();
    getSMASMAITK();
    getS1SMAITK();
    getS2SMAITK();
    getS3SMAITK();
    getSMPMAK();
    getSMAMAK();
    getS1MAK();
    getS2MAK();
    getS3MAK();
    // getSMPMTSK();
    // getSMAMTSK();
    // getS1MTSK();
    // getS2MTSK();
    // getS3MTSK();
    getSMPMTSMnj();
    getSMAMNJ();
    getS1MNJ();
    getS2MNJ();
    getS3MNJ();
  }, []);

  const getSMPPaud1G = async () => {
    const response = await axios.get(`${Url}/dashboard/SmpPaud1`, {
      credentials: "include",
    });
    setSMPPaud1(response.data);
  };

  const getSMAPaud1G = async () => {
    const response = await axios.get(`${Url}/dashboard/SmaPaud1`, {
      credentials: "include",
    });
    setSMAPaud1(response.data);
  };

  const getS1Paud1G = async () => {
    const response = await axios.get(`${Url}/dashboard/S1Paud1`, {
      credentials: "include",
    });
    setS1Paud1(response.data);
  };

  const getS2Paud1G = async () => {
    const response = await axios.get(`${Url}/dashboard/S2Paud1`, {
      credentials: "include",
    });
    setS2Paud1(response.data);
  };

  const getS3Paud1G = async () => {
    const response = await axios.get(`${Url}/dashboard/S3Paud1`, {
      credentials: "include",
    });
    setS3Paud1(response.data);
  };

  //SDIT1
  const getSMPSDIT1G = async () => {
    const response = await axios.get(`${Url}/dashboard/SmpSDIT1`, {
      credentials: "include",
    });
    setSMPSDIT1(response.data);
  };

  const getSMASDIT1G = async () => {
    const response = await axios.get(`${Url}/dashboard/SmaSDIT1`, {
      credentials: "include",
    });
    setSMASDIT1(response.data);
  };

  const getS1SDIT1G = async () => {
    const response = await axios.get(`${Url}/dashboard/S1SDIT1`, {
      credentials: "include",
    });
    setS1SDIT1(response.data);
  };

  const getS2SDIT1G = async () => {
    const response = await axios.get(`${Url}/dashboard/S2SDIT1`, {
      credentials: "include",
    });
    setS2SDIT1(response.data);
  };

  const getS3SDIT1G = async () => {
    const response = await axios.get(`${Url}/dashboard/S3SDIT1`, {
      credentials: "include",
    });
    setS3SDIT1(response.data);
  };

  //Paud 2
  const getSMPPaud2G = async () => {
    const response = await axios.get(`${Url}/dashboard/SmpPaud2`, {
      credentials: "include",
    });
    setSMPPaud2(response.data);
  };

  const getSMAPaud2G = async () => {
    const response = await axios.get(`${Url}/dashboard/SmaPaud2`, {
      credentials: "include",
    });
    setSMAPaud2(response.data);
  };

  const getS1Paud2G = async () => {
    const response = await axios.get(`${Url}/dashboard/S1Paud2`, {
      credentials: "include",
    });
    setS1Paud2(response.data);
  };

  const getS2Paud2G = async () => {
    const response = await axios.get(`${Url}/dashboard/S2Paud2`, {
      credentials: "include",
    });
    setS2Paud2(response.data);
  };

  const getS3Paud2G = async () => {
    const response = await axios.get(`${Url}/dashboard/S3Paud2`, {
      credentials: "include",
    });
    setS3Paud2(response.data);
  };

  //SDIT2
  const getSMPSDIT2G = async () => {
    const response = await axios.get(`${Url}/dashboard/SmpSDIT2`, {
      credentials: "include",
    });
    setSMPSDIT2(response.data);
  };

  const getSMASDIT2G = async () => {
    const response = await axios.get(`${Url}/dashboard/SmaSDIT2`, {
      credentials: "include",
    });
    setSMASDIT2(response.data);
  };

  const getS1SDIT2G = async () => {
    const response = await axios.get(`${Url}/dashboard/S1SDIT2`, {
      credentials: "include",
    });
    setS1SDIT2(response.data);
  };

  const getS2SDIT2G = async () => {
    const response = await axios.get(`${Url}/dashboard/S2SDIT2`, {
      credentials: "include",
    });
    setS2SDIT2(response.data);
  };

  const getS3SDIT2G = async () => {
    const response = await axios.get(`${Url}/dashboard/S3SDIT2`, {
      credentials: "include",
    });
    setS3SDIT2(response.data);
  };

  //SMPI
  const getSMPSMPIG = async () => {
    const response = await axios.get(`${Url}/dashboard/SmpSMPI`, {
      credentials: "include",
    });
    setSMPSMPI(response.data);
  };

  const getSMASMPIG = async () => {
    const response = await axios.get(`${Url}/dashboard/SmaSMPI`, {
      credentials: "include",
    });
    setSMASMPI(response.data);
  };

  const getS1SMPIG = async () => {
    const response = await axios.get(`${Url}/dashboard/S1SMPI`, {
      credentials: "include",
    });
    setS1SMPI(response.data);
  };

  const getS2SMPIG = async () => {
    const response = await axios.get(`${Url}/dashboard/S2SMPI`, {
      credentials: "include",
    });
    setS2SMPI(response.data);
  };

  const getS3SMPIG = async () => {
    const response = await axios.get(`${Url}/dashboard/S3SMPI`, {
      credentials: "include",
    });
    setS3SMPI(response.data);
  };

  //SMAIT
  const getSMPSMAITG = async () => {
    const response = await axios.get(`${Url}/dashboard/SmpSMAIT`, {
      credentials: "include",
    });
    setSMPSMA(response.data);
  };

  const getSMASMAITG = async () => {
    const response = await axios.get(`${Url}/dashboard/SmaSMAIT`, {
      credentials: "include",
    });
    setSMASMA(response.data);
  };

  const getS1SMAITG = async () => {
    const response = await axios.get(`${Url}/dashboard/S1SMAIT`, {
      credentials: "include",
    });
    setS1SMA(response.data);
  };

  const getS2SMAITG = async () => {
    const response = await axios.get(`${Url}/dashboard/S2SMAIT`, {
      credentials: "include",
    });
    setS2MA(response.data);
  };

  const getS3SMAITG = async () => {
    const response = await axios.get(`${Url}/dashboard/S3SMAIT`, {
      credentials: "include",
    });
    setS3SMA(response.data);
  };

  //MA
  const getSMPMAG = async () => {
    const response = await axios.get(`${Url}/dashboard/SmpMA`, {
      credentials: "include",
    });
    setSMPMA(response.data);
  };

  const getSMAMAG = async () => {
    const response = await axios.get(`${Url}/dashboard/SmaMA`, {
      credentials: "include",
    });
    setSMAMA(response.data);
  };

  const getS1MAG = async () => {
    const response = await axios.get(`${Url}/dashboard/S1MA`, {
      credentials: "include",
    });
    setS1MA(response.data);
  };

  const getS2MAG = async () => {
    const response = await axios.get(`${Url}/dashboard/S2MA`, {
      credentials: "include",
    });
    setS2SMA(response.data);
  };

  const getS3MAG = async () => {
    const response = await axios.get(`${Url}/dashboard/S3MA`, {
      credentials: "include",
    });
    setS3MA(response.data);
  };

  //MTS
  // const getSMPMTSG = async () => {
  //   const response = await axios.get(`${Url}/dashboard/SmpMTS`, {
  //     credentials: "include",
  //   });
  //   setSMPMTS(response.data);
  // };

  // const getSMAMTSG = async () => {
  //   const response = await axios.get(`${Url}/dashboard/SmaMTS`, {
  //     credentials: "include",
  //   });
  //   setSMAMTS(response.data);
  // };

  // const getS1MTSG = async () => {
  //   const response = await axios.get(`${Url}/dashboard/S1MTS`, {
  //     credentials: "include",
  //   });
  //   setS1MTS(response.data);
  // };

  // const getS2MTSG = async () => {
  //   const response = await axios.get(`${Url}/dashboard/S2MTS`, {
  //     credentials: "include",
  //   });
  //   setS2MTS(response.data);
  // };

  // const getS3MTSG = async () => {
  //   const response = await axios.get(`${Url}/dashboard/S3MTS`, {
  //     credentials: "include",
  //   });
  //   setS3MTS(response.data);
  // };

  //Karyawan
  const getSMPPaud1K = async () => {
    const response = await axios.get(`${Url}/dashboard/SmpPaud1K`, {
      credentials: "include",
    });
    setSMPPaud1K(response.data);
  };

  const getSMAPaud1K = async () => {
    const response = await axios.get(`${Url}/dashboard/SmaPaud1K`, {
      credentials: "include",
    });
    setSMAPaud1K(response.data);
  };

  const getS1Paud1K = async () => {
    const response = await axios.get(`${Url}/dashboard/S1Paud1K`, {
      credentials: "include",
    });
    setS1Paud1K(response.data);
  };

  const getS2Paud1K = async () => {
    const response = await axios.get(`${Url}/dashboard/S2Paud1K`, {
      credentials: "include",
    });
    setS2Paud1K(response.data);
  };

  const getS3Paud1K = async () => {
    const response = await axios.get(`${Url}/dashboard/S3Paud1K`, {
      credentials: "include",
    });
    setS3Paud1K(response.data);
  };

  //SDIT1
  const getSMPSDIT1K = async () => {
    const response = await axios.get(`${Url}/dashboard/SmpSDIT1K`, {
      credentials: "include",
    });
    setSMPSDIT1K(response.data);
  };

  const getSMASDIT1K = async () => {
    const response = await axios.get(`${Url}/dashboard/SmaSDIT1K`, {
      credentials: "include",
    });
    setSMASDIT1K(response.data);
  };

  const getS1SDIT1K = async () => {
    const response = await axios.get(`${Url}/dashboard/S1SDIT1K`, {
      credentials: "include",
    });
    setS1SDIT1K(response.data);
  };

  const getS2SDIT1K = async () => {
    const response = await axios.get(`${Url}/dashboard/S2SDIT1K`, {
      credentials: "include",
    });
    setS2SDIT1K(response.data);
  };

  const getS3SDIT1K = async () => {
    const response = await axios.get(`${Url}/dashboard/S3SDIT1K`, {
      credentials: "include",
    });
    setS3SDIT1K(response.data);
  };

  //Paud 2
  const getSMPPaud2K = async () => {
    const response = await axios.get(`${Url}/dashboard/SmpPaud2K`, {
      credentials: "include",
    });
    setSMPPaud2K(response.data);
  };

  const getSMAPaud2K = async () => {
    const response = await axios.get(`${Url}/dashboard/SmaPaud2`, {
      credentials: "include",
    });
    setSMAPaud2K(response.data);
  };

  const getS1Paud2K = async () => {
    const response = await axios.get(`${Url}/dashboard/S1Paud2K`, {
      credentials: "include",
    });
    setS1Paud2K(response.data);
  };

  const getS2Paud2K = async () => {
    const response = await axios.get(`${Url}/dashboard/S2Paud2K`, {
      credentials: "include",
    });
    setS2Paud2K(response.data);
  };

  const getS3Paud2K = async () => {
    const response = await axios.get(`${Url}/dashboard/S3Paud2K`, {
      credentials: "include",
    });
    setS3Paud2K(response.data);
  };

  //SDIT2
  const getSMPSDIT2K = async () => {
    const response = await axios.get(`${Url}/dashboard/SmpSDIT2K`, {
      credentials: "include",
    });
    setSMPSDIT2K(response.data);
  };

  const getSMASDIT2K = async () => {
    const response = await axios.get(`${Url}/dashboard/SmaSDIT2K`, {
      credentials: "include",
    });
    setSMASDIT2K(response.data);
  };

  const getS1SDIT2K = async () => {
    const response = await axios.get(`${Url}/dashboard/S1SDIT2K`, {
      credentials: "include",
    });
    setS1SDIT2K(response.data);
  };

  const getS2SDIT2K = async () => {
    const response = await axios.get(`${Url}/dashboard/S2SDIT2K`, {
      credentials: "include",
    });
    setS2SDIT2K(response.data);
  };

  const getS3SDIT2K = async () => {
    const response = await axios.get(`${Url}/dashboard/S3SDIT2K`, {
      credentials: "include",
    });
    setS3SDIT2K(response.data);
  };

  //SMPI
  const getSMPSMPIK = async () => {
    const response = await axios.get(`${Url}/dashboard/SmpSMPIK`, {
      credentials: "include",
    });
    setSMPSMPIK(response.data);
  };

  const getSMASMPIK = async () => {
    const response = await axios.get(`${Url}/dashboard/SmaSMPIK`, {
      credentials: "include",
    });
    setSMASMPIK(response.data);
  };

  const getS1SMPIK = async () => {
    const response = await axios.get(`${Url}/dashboard/S1SMPIK`, {
      credentials: "include",
    });
    setS1SMPIK(response.data);
  };

  const getS2SMPIK = async () => {
    const response = await axios.get(`${Url}/dashboard/S2SMPIK`, {
      credentials: "include",
    });
    setS2SMPIK(response.data);
  };

  const getS3SMPIK = async () => {
    const response = await axios.get(`${Url}/dashboard/S3SMPIK`, {
      credentials: "include",
    });
    setS3SMPIK(response.data);
  };

  //SMAIT
  const getSMPSMAITK = async () => {
    const response = await axios.get(`${Url}/dashboard/SmpSMAITK`, {
      credentials: "include",
    });
    setSMPSMAK(response.data);
  };

  const getSMASMAITK = async () => {
    const response = await axios.get(`${Url}/dashboard/SmaSMAITK`, {
      credentials: "include",
    });
    setSMASMAK(response.data);
  };

  const getS1SMAITK = async () => {
    const response = await axios.get(`${Url}/dashboard/S1SMAITK`, {
      credentials: "include",
    });
    setS1SMAK(response.data);
  };

  const getS2SMAITK = async () => {
    const response = await axios.get(`${Url}/dashboard/S2SMAITK`, {
      credentials: "include",
    });
    setS2MAK(response.data);
  };

  const getS3SMAITK = async () => {
    const response = await axios.get(`${Url}/dashboard/S3SMAITK`, {
      credentials: "include",
    });
    setS3SMAK(response.data);
  };

  //Pondok Pesantren
  const getSMPMAK = async () => {
    const response = await axios.get(`${Url}/dashboard/SmpMAK`, {
      credentials: "include",
    });
    setSMPMAK(response.data);
  };

  const getSMAMAK = async () => {
    const response = await axios.get(`${Url}/dashboard/SmaMAK`, {
      credentials: "include",
    });
    setSMAMAK(response.data);
  };

  const getS1MAK = async () => {
    const response = await axios.get(`${Url}/dashboard/S1MAK`, {
      credentials: "include",
    });
    setS1MAK(response.data);
  };

  const getS2MAK = async () => {
    const response = await axios.get(`${Url}/dashboard/S2MAK`, {
      credentials: "include",
    });
    setS2SMAK(response.data);
  };

  const getS3MAK = async () => {
    const response = await axios.get(`${Url}/dashboard/S3MAK`, {
      credentials: "include",
    });
    setS3MAK(response.data);
  };

  //MTS
  // const getSMPMTSK = async () => {
  //   const response = await axios.get(`${Url}/dashboard/SmpMTSK`, {
  //     credentials: "include",
  //   });
  //   setSMPMTSK(response.data);
  // };

  // const getSMAMTSK = async () => {
  //   const response = await axios.get(`${Url}/dashboard/SmaMTSK`, {
  //     credentials: "include",
  //   });
  //   setSMAMTSK(response.data);
  // };

  // const getS1MTSK = async () => {
  //   const response = await axios.get(`${Url}/dashboard/S1MTSK`, {
  //     credentials: "include",
  //   });
  //   setS1MTSK(response.data);
  // };

  // const getS2MTSK = async () => {
  //   const response = await axios.get(`${Url}/dashboard/S2MTSK`, {
  //     credentials: "include",
  //   });
  //   setS2MTSK(response.data);
  // };

  // const getS3MTSK = async () => {
  //   const response = await axios.get(`${Url}/dashboard/S3MTSK`, {
  //     credentials: "include",
  //   });
  //   setS3MTSK(response.data);
  // };

  //Manajemen
  const getSMPMTSMnj = async () => {
    const response = await axios.get(`${Url}/dashboard/SmpMNJ`, {
      credentials: "include",
    });
    setSMPMNJ(response.data);
  };

  const getSMAMNJ = async () => {
    const response = await axios.get(`${Url}/dashboard/SmaMNJ`, {
      credentials: "include",
    });
    setSMAMNJ(response.data);
  };

  const getS1MNJ = async () => {
    const response = await axios.get(`${Url}/dashboard/S1MNJ`, {
      credentials: "include",
    });
    setS1MNJ(response.data);
  };

  const getS2MNJ = async () => {
    const response = await axios.get(`${Url}/dashboard/S2MNJ`, {
      credentials: "include",
    });
    setS2MNJ(response.data);
  };

  const getS3MNJ = async () => {
    const response = await axios.get(`${Url}/dashboard/S3MNJ`, {
      credentials: "include",
    });
    setS3MNJ(response.data);
  };

  return (
    <div>
      <div className="table-container">
        <table
          className="table is-bordered is-striped is-narrow is-hoverable"
          id="table-to-xls"
        >
          <thead className="dashboardtablehead">
            <tr className="has-text-centered">
              <th className="has-text-centered" rowSpan="2" width="10px">
                No
              </th>
              <th className="has-text-centered" rowSpan="2">
                Jumlah Guru Per Tingkatan
              </th>
              <th className="has-text-centered" colspan="5">
                PAUDIT1
              </th>
              <th className="has-text-centered" colspan="5">
                PAUDIT2
              </th>
              <th className="has-text-centered" colspan="5">
                SDIT1
              </th>
              <th className="has-text-centered" colspan="5">
                SDIT2
              </th>
              <th className="has-text-centered" colspan="5">
                SMPI
              </th>
              <th className="has-text-centered" colspan="5">
                SMAIT
              </th>
              <th className="has-text-centered" colspan="5">
                Pondok Pesantren
              </th>
              {/* <th className="has-text-centered" colspan="5">
                MTS
              </th> */}
              <th className="has-text-centered" colspan="5">
                Manajemen
              </th>
            </tr>

            <tr>
              <th className="has-text-centered">SMP</th>
              <th className="has-text-centered">SMA</th>
              <th className="has-text-centered">S1</th>
              <th className="has-text-centered">S2</th>
              <th className="has-text-centered">S3</th>

              <th className="has-text-centered">SMP</th>
              <th className="has-text-centered">SMA</th>
              <th className="has-text-centered">S1</th>
              <th className="has-text-centered">S2</th>
              <th className="has-text-centered">S3</th>

              <th className="has-text-centered">SMP</th>
              <th className="has-text-centered">SMA</th>
              <th className="has-text-centered">S1</th>
              <th className="has-text-centered">S2</th>
              <th className="has-text-centered">S3</th>

              <th className="has-text-centered">SMP</th>
              <th className="has-text-centered">SMA</th>
              <th className="has-text-centered">S1</th>
              <th className="has-text-centered">S2</th>
              <th className="has-text-centered">S3</th>

              <th className="has-text-centered">SMP</th>
              <th className="has-text-centered">SMA</th>
              <th className="has-text-centered">S1</th>
              <th className="has-text-centered">S2</th>
              <th className="has-text-centered">S3</th>

              <th className="has-text-centered">SMP</th>
              <th className="has-text-centered">SMA</th>
              <th className="has-text-centered">S1</th>
              <th className="has-text-centered">S2</th>
              <th className="has-text-centered">S3</th>

              <th className="has-text-centered">SMP</th>
              <th className="has-text-centered">SMA</th>
              <th className="has-text-centered">S1</th>
              <th className="has-text-centered">S2</th>
              <th className="has-text-centered">S3</th>

              <th className="has-text-centered">SMP</th>
              <th className="has-text-centered">SMA</th>
              <th className="has-text-centered">S1</th>
              <th className="has-text-centered">S2</th>
              <th className="has-text-centered">S3</th>

              {/* <th className="has-text-centered">SMP</th>
              <th className="has-text-centered">SMA</th>
              <th className="has-text-centered">S1</th>
              <th className="has-text-centered">S2</th>
              <th className="has-text-centered">S3</th> */}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>Guru</td>
              <td>{SMPPaud1}</td>
              <td>{SMAPaud1}</td>
              <td>{S1Paud1}</td>
              <td>{S2Paud1}</td>
              <td>{S3Paud1}</td>

              <td>{SMPPaud2}</td>
              <td>{SMAPaud2}</td>
              <td>{S1Paud2}</td>
              <td>{S2Paud2}</td>
              <td>{S3Paud2}</td>

              <td>{SMPSDIT1}</td>
              <td>{SMASDIT1}</td>
              <td>{S1SDIT1}</td>
              <td>{S2SDIT1}</td>
              <td>{S3SDIT1}</td>

              <td>{SMPSDIT2}</td>
              <td>{SMASDIT2}</td>
              <td>{S1SDIT2}</td>
              <td>{S2SDIT2}</td>
              <td>{S3SDIT2}</td>

              <td>{SMPSMPI}</td>
              <td>{SMASMPI}</td>
              <td>{S1SMPI}</td>
              <td>{S2SMPI}</td>
              <td>{S3SMPI}</td>

              <td>{SMPSMA}</td>
              <td>{SMASMA}</td>
              <td>{S1SMA}</td>
              <td>{S2SMA}</td>
              <td>{S3SMA}</td>

              <td>{SMPMA}</td>
              <td>{SMAMA}</td>
              <td>{S1MA}</td>
              <td>{S2MA}</td>
              <td>{S3MA}</td>

              {/* <td>{SMPMTS}</td>
              <td>{SMAMTS}</td>
              <td>{S1MTS}</td>
              <td>{S2MTS}</td>
              <td>{S3MTS}</td> */}

              <td> 0 </td>
              <td> 0 </td>
              <td> 0 </td>
              <td> 0 </td>
              <td> 0 </td>
            </tr>

            <tr>
              <td>2</td>
              <td>Karyawan</td>
              <td>{SMPPaud1K}</td>
              <td>{SMAPaud1K}</td>
              <td>{S1Paud1K}</td>
              <td>{S2Paud1K}</td>
              <td>{S3Paud1K}</td>

              <td>{SMPPaud2K}</td>
              <td>{SMAPaud2K}</td>
              <td>{S1Paud2K}</td>
              <td>{S2Paud2K}</td>
              <td>{S3Paud2K}</td>

              <td>{SMPSDIT1K}</td>
              <td>{SMASDIT1K}</td>
              <td>{S1SDIT1K}</td>
              <td>{S2SDIT1K}</td>
              <td>{S3SDIT1K}</td>

              <td>{SMPSDIT2K}</td>
              <td>{SMASDIT2K}</td>
              <td>{S1SDIT2K}</td>
              <td>{S2SDIT2K}</td>
              <td>{S3SDIT2K}</td>

              <td>{SMPSMPIK}</td>
              <td>{SMASMPIK}</td>
              <td>{S1SMPIK}</td>
              <td>{S2SMPIK}</td>
              <td>{S3SMPIK}</td>

              <td>{SMPSMAK}</td>
              <td>{SMASMAK}</td>
              <td>{S1SMAK}</td>
              <td>{S2SMAK}</td>
              <td>{S3SMAK}</td>

              <td>{SMPMAK}</td>
              <td>{SMAMAK}</td>
              <td>{S1MAK}</td>
              <td>{S2MAK}</td>
              <td>{S3MAK}</td>

              {/* <td>{SMPMTSK}</td>
              <td>{SMAMTSK}</td>
              <td>{S1MTSK}</td>
              <td>{S2MTSK}</td>
              <td>{S3MTSK}</td> */}

              <td>{SMPMNJ}</td>
              <td>{SMAMNJ}</td>
              <td>{S1MNJ}</td>
              <td>{S2MNJ}</td>
              <td>{S3MNJ}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TableDashboardJumlahGuruPertingkatPendidikan;
