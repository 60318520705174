import React, { useEffect } from "react";
import Layout from "./Layout";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getMe } from "../features/authSlice";
//import Welcome from "../components/welcome"
// import Chart from "../components/Chart";
import FutureInfo from "../components/FutureInfo";
import TableDashboard from "../components/TableDashboard";
import TableDashboardDetail from "../components/TableDashboardDetailJenisKelamin";
import TableDashboardJumlahGuruPertingkatPendidikan from "../components/TableDashboardJumlahGuruPertingkatPendidikan";
import DashboardUser from "../components/DashboardUser";
import DashboardSDIT from "../components/DashboardSDIT";
import DashboardSDIT2 from "../components/DashboardSDIT2";
import DashboardPAUD1 from "../components/DashboardPAUD1";
import DashboardPAUD2 from "../components/DashboardPAUDIT2";
import DashboardPonpes from "../components/DashboardPonpes";
import DashboardSMAIT from "../components/DashboardSMAIT";

const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isError } = useSelector((state) => state.auth);
  const { pegawai } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getMe());
  }, [dispatch]);

  useEffect(() => {
    if (isError) {
      navigate("/login");
    }
  }, [isError, navigate]);

  return (
    <Layout>
      {pegawai &&
        (pegawai.strRole === "kepsek" ||
          pegawai.strRole === "staff TU" ||
          pegawai.strRole === "user") &&
        pegawai.strUnit === "SMPI Al Hasanah" && <DashboardUser />}

      {pegawai &&
        (pegawai.strRole === "kepsek" ||
          pegawai.strRole === "staff TU" ||
          pegawai.strRole === "user") &&
        pegawai.strUnit === "SDIT Al Hasanah 1" && <DashboardSDIT />}

      {pegawai &&
        (pegawai.strRole === "kepsek" ||
          pegawai.strRole === "staff TU" ||
          pegawai.strRole === "user") &&
        pegawai.strUnit === "SDIT Al Hasanah 2" && <DashboardSDIT2 />}

      {pegawai &&
        (pegawai.strRole === "kepsek" ||
          pegawai.strRole === "staff TU" ||
          pegawai.strRole === "user") &&
        pegawai.strUnit === "PAUD IT Al Hasanah 1" && <DashboardPAUD1 />}

{pegawai &&
        (pegawai.strRole === "kepsek" ||
          pegawai.strRole === "staff TU" ||
          pegawai.strRole === "user") &&
        pegawai.strUnit === "PAUD IT Al Hasanah 2" && <DashboardPAUD2 />}

      {pegawai &&
        (pegawai.strRole === "kepsek" ||
          pegawai.strRole === "staff TU" ||
          pegawai.strRole === "user") &&
        pegawai.strUnit === "Pondok Pesantren" && <DashboardPonpes />}

      {pegawai &&
        (pegawai.strRole === "kepsek" ||
          pegawai.strRole === "staff TU" ||
          pegawai.strRole === "user") &&
        pegawai.strUnit === "SMAIT Al Hasanah" && <DashboardSMAIT />}

      {pegawai &&
        (pegawai.strRole === "admin" ||
          pegawai.strRole === "kabid kepegawaian") && (
          <div>
            {/* <Chart /> */}
            <FutureInfo />
            <br />
            <TableDashboard />
            <br />
            <TableDashboardDetail />
            <br />
            <TableDashboardJumlahGuruPertingkatPendidikan />
          </div>
        )}
    </Layout>
  );
};

export default Dashboard;
