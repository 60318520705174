import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Url from "../global";
import "./Style/globalValidasiEror.scss";

const FormEditRiwayatJabatan = () => {
  const [jabatan, setJabatan] = useState("");
  const [tglSK, setTglSK] = useState("");
  // const [masaKerja, setMasaKerja] = useState("");
  const [status, setStatus] = useState("");
  const [komentar, setKomentar] = useState("");
  const [msg, setMsg] = useState("");
  const navigate = useNavigate();
  const { id } = useParams();
  const { pegawai } = useSelector((state) => state.auth);
  const [file, setFile] = useState("");
  const [setError] = useState(null);
  const [setFileUrl] = useState("");

  const PdfViewer = ({ file }) => {
    return (
      <div>
        {file ? (
          <embed src={file} type="application/pdf" width="80%" height="600px" />
        ) : (
          <p>No PDF file uploaded</p>
        )}
      </div>
    );
  };

  const handleChange = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile && selectedFile.type === "application/pdf") {
      setFile(selectedFile);
      setFileUrl(URL.createObjectURL(selectedFile));
    } else {
      setFile(null);
      setError("File harus berupa PDF");
    }
  };

  useEffect(() => {
    const getRiwayatJabatanById = async () => {
      try {
        const response = await axios.get(`${Url}/riwayat_jabatans/${id}`, {});

        setJabatan(response.data.strJabatan);
        setTglSK(response.data.dateTglSK);
        // setMasaKerja(response.data.strMasaKerja);
        setStatus(response.data.strStatus);
        setKomentar(response.data.strKomentar);
        setFile(response.data.url);
      } catch (error) {
        if (error.response) {
          setMsg(error.response.data.msg);
        }
      }
    };
    getRiwayatJabatanById();
  }, [id]);

  const updateRiwayatJabatan = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("strJabatan", jabatan);
      formData.append("dateTglSK", tglSK);
      formData.append("strStatus", status);
      formData.append("url", file);
      formData.append("strKomentar", komentar);

      await axios.patch(`${Url}/riwayat_jabatans/${id}`, formData, {
        headers: {
          "Content-type": "multipart/form-data",
        },
      });
      navigate("/riwayat-jabatan");
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };

  return (
    <div>
      <h1 className="title">Riwayat Jabatan</h1>
      <h2 className="subtitle">Edit Riwayat Jabatan</h2>

      <div className="card is-shadowless">
        <div className="card-content">
          <div className="content">
            <form className="box" onSubmit={updateRiwayatJabatan}>
              <p className="has-text-centered">{msg}</p>

              <div className="field">
                <label className="label">Jabatan</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    placeholder="Masukan Jabatan"
                    value={jabatan}
                    onChange={(e) => setJabatan(e.target.value)}
                  />
                </div>
              </div>

              <div className="field">
                <label className="label">Tanggal SK</label>
                <div className="control">
                  <Form.Group controlId="duedate" className="dtp">
                    <Form.Control
                      type="date"
                      name="duedate"
                      placeholder="Tanggal SK"
                      value={tglSK}
                      onChange={(e) => setTglSK(e.target.value)}
                    />
                  </Form.Group>
                </div>
              </div>
              {/* 
              <div className="field">
                <label className="label">Masa kerja</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    placeholder="Masa Kerja"
                    value={masaKerja}
                    onChange={(e) => setMasaKerja(e.target.value)}
                  />
                </div>
              </div> */}

              <div className="field">
                <label className="label">Status Jabatan</label>
                <div className="control">
                  <div className="select is-fullwidth">
                    {pegawai &&
                      (pegawai.strRole === "kepsek" ||
                        pegawai.strRole === "admin") && (
                        <select
                          className="dropdown"
                          value={status}
                          onChange={(e) => setStatus(e.target.value)}
                          required
                        >
                          <option>Pengajuan Sertifikat Jabatan</option>
                          <option>Disetujui Kabid Kepegawaian</option>
                          <option>Ditolak Kabid Kepegawaian</option>
                        </select>
                      )}

                    {pegawai && pegawai.strRole === "user" && (
                      <select
                        className="dropdown"
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                        required
                        disabled="true"
                      >
                        <option>Pengajuan Sertifikat Jabatan</option>
                        <option>Disetujui Kepala Sekolah atau Staff TU</option>
                        <option>Ditolak Kepala Sekolah atau Staff TU</option>
                      </select>
                    )}
                  </div>
                </div>
              </div>

              <div className="field">
                <label className="label">Komentar</label>
                <div className="control">
                  {pegawai &&
                    (pegawai.strRole === "kabid kepegawaian" ||
                      pegawai.strRole === "admin") && (
                      <input
                        type="text"
                        className="input"
                        placeholder="Komentar"
                        value={komentar}
                        onChange={(e) => setKomentar(e.target.value)}
                      />
                    )}
                  {pegawai && pegawai.strRole === "user" && (
                    <input
                      type="text"
                      className="input"
                      placeholder="Komentar"
                      value={komentar}
                      onChange={(e) => setKomentar(e.target.value)}
                      disabled
                    />
                  )}
                </div>
              </div>

              <div className="field">
                <label className="label">Image</label>
                <div className="control">
                  <div className="file">
                    <label className="file-label">
                      <input
                        type="file"
                        accept="application/pdf"
                        onChange={handleChange}
                        name="file"
                      />
                      {/* <button type="submit">Upload</button> */}
                    </label>
                    {setError && <p className="error">{setError}</p>}
                  </div>
                  <br />
                  <PdfViewer file={file} />
                </div>
              </div>


              <br />
              <div className="field">
                <div className="control">
                  <button type="submit" className="button is-success">
                    Update
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormEditRiwayatJabatan;
