import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Url from "../global";
import "./Style/globalValidasiEror.scss";

const FormEditRaport = () => {
  const [raport, setRaport] = useState("");
  const [nilaiRaport, setnilaiRaport] = useState("");
  const [gradeRaport, setGradeRaport] = useState("");
  const [tglSK, setTglSK] = useState("");
  const [status, setStatus] = useState("");
  const [msg, setMsg] = useState("");
  const [komentar, setKomentar] = useState("");

  const navigate = useNavigate();
  const { id } = useParams();
  const { pegawai } = useSelector((state) => state.auth);

  const [file, setFile] = useState("");
  const [setError] = useState(null);
  const [setFileUrl] = useState("");

  const PdfViewer = ({ file }) => {
    return (
      <div>
        {file ? (
          <embed src={file} type="application/pdf" width="80%" height="600px" />
        ) : (
          <p>No PDF file uploaded</p>
        )}
      </div>
    );
  };

  
  const handleChange = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile && selectedFile.type === "application/pdf") {
      setFile(selectedFile);
      setFileUrl(URL.createObjectURL(selectedFile));
    } else {
      setFile(null);
      setError("File harus berupa PDF");
    }
  };

  useEffect(() => {
    const getRaportById = async () => {
      try {
        const response = await axios.get(`${Url}/riwayat_raports/${id}`);
        setRaport(response.data.strSemester);
        setnilaiRaport(response.data.intNilaiRaport);
        setGradeRaport(response.data.strGradeRaport);
        setTglSK(response.data.dateTglSK);
        setStatus(response.data.strStatus);
        setKomentar(response.data.strKomentar);
        setFile(response.data.url);
      } catch (error) {
        if (error.response) {
          setMsg(error.response.data.msg);
        }
      }
    };
    getRaportById();
  }, [id]);

  const updateRiwayatRaport = async (e) => {
    e.preventDefault();
    try {
      await axios.patch(
        `${Url}/riwayat_raports/${id}`,
        {
          strSemester: raport,
          intNilaiRaport: nilaiRaport,
          strGradeRaport: gradeRaport,
          dateTglSK: tglSK,
          strKomentar: komentar,
          strStatus: status,
          url: file,
        },
        {
          headers: {
            "Content-type": "multipart/form-data",
          },
        }
      );
      navigate("/riwayat-raport");
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };

  return (
    <div>
      <h1 className="title">Riwayat Raport</h1>
      <h2 className="subtitle">Edit Riwayat Raport</h2>

      <div className="card is-shadowless">
        <div className="card-content">
          <div className="content">
            <form className="box" onSubmit={updateRiwayatRaport}>
              <p className="has-text-centered">{msg}</p>

              <div className="field">
                <label className="label">Semester</label>
                <div className="control">
                  <div className="select is-fullwidth">
                    <select
                      className="dropdown"
                      value={raport}
                      onChange={(e) => setRaport(e.target.value)}
                      required
                      // disabled="true"
                    >
                      <option>--pilih--</option>
                      <option>Semester 1</option>
                      <option>Semester 2</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="field">
                <label className="label">Nilai Raport</label>
                <div className="control">
                  <input
                    type="number"
                    className="input"
                    placeholder="Masukan Nilai Raport berdasarkan sertifikat Raport"
                    value={nilaiRaport}
                    onChange={(e) => setnilaiRaport(e.target.value)}
                  />
                </div>
              </div>

              <div className="field">
                <label className="label">GradeRaport</label>
                <div className="control">
                  <div className="select is-fullwidth">
                    <select
                      className="dropdown"
                      value={gradeRaport}
                      onChange={(e) => setGradeRaport(e.target.value)}
                      required
                      // disabled="true"
                    >
                      <option>--pilih--</option>
                      <option>A</option>
                      <option>B</option>
                      <option>C</option>
                      <option>D</option>
                      <option>E</option>
                      <option>F</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="field">
                <label className="label">Tanggal Raport</label>
                <div className="control">
                  <Form.Group controlId="duedate" className="dtp">
                    <Form.Control
                      type="date"
                      name="duedate"
                      placeholder="Tanggal Raport"
                      value={tglSK}
                      onChange={(e) => setTglSK(e.target.value)}
                    />
                  </Form.Group>
                </div>
              </div>

              <div className="field">
                <label className="label">Status Raport</label>
                <div className="control">
                  <div className="select is-fullwidth">
                    {pegawai &&
                      (pegawai.strRole === "kabid kepegawaian" ||
                        pegawai.strRole === "admin") && (
                        <select
                          className="dropdown"
                          value={status}
                          onChange={(e) => setStatus(e.target.value)}
                          required
                        >
                          <option>--Pilih--</option>
                          <option>Pengajuan Raport</option>
                          <option>Disetujui Kabid Kepegawaian</option>
                          <option>Ditolak Kabid Kepegawaian</option>
                        </select>
                      )}

                    {pegawai && pegawai.strRole === "user" && (
                      <select
                        className="dropdown"
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                        required
                        disabled
                      >
                        <option>--Pilih--</option>
                        <option>Pengajuan Raport</option>
                        <option>Disetujui Kabid Kepegawaian</option>
                        <option>Ditolak Kabid Kepegawaian</option>
                      </select>
                    )}
                  </div>
                </div>
              </div>

              <div className="field">
                <label className="label">Komentar</label>
                <div className="control">
                  {pegawai &&
                    (pegawai.strRole === "kabid kepegawaian" ||
                      pegawai.strRole === "admin") && (
                      <input
                        type="text"
                        className="input"
                        placeholder="Komentar"
                        value={komentar}
                        onChange={(e) => setKomentar(e.target.value)}
                      />
                    )}
                  {pegawai && pegawai.strRole === "user" && (
                    <input
                      type="text"
                      className="input"
                      placeholder="Komentar"
                      value={komentar}
                      onChange={(e) => setKomentar(e.target.value)}
                      disabled
                    />
                  )}
                </div>
              </div>

              <div className="field">
                <label className="label">Upload Raport</label>
                <div className="control">
                  <div className="file">
                    <label className="file-label">
                      <input
                        type="file"
                        accept="application/pdf"
                        onChange={handleChange}
                        name="file"
                      />
                      {/* <button type="submit">Upload</button> */}
                    </label>
                    {setError && <p className="error">{setError}</p>}
                  </div>
                  <br />
                  <PdfViewer file={file} />
                </div>
              </div>
             
              <br />
              <div className="field">
                <div className="control">
                  <button type="submit" className="button is-success">
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormEditRaport;
