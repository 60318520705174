import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import * as IoIcons from "react-icons/io";
import { LogOut, reset } from "../features/authSlice";

const Sidebar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pegawai } = useSelector((state) => state.auth);

  const Logout = () => {
    dispatch(LogOut());
    dispatch(reset());
    navigate("/");
  };

  return (
    <div>
      <aside className="menu pl-3 has-shadow">
        <br />
        <p className="menu-label">General</p>

        <ul className="menu-list">
          {pegawai &&
            (pegawai.strRole === "admin" ||
            pegawai.strRole === "kepsek" ||
            pegawai.strRole === "user" ||
              pegawai.strRole === "kabid kepegawaian") && (
              <li>
                <NavLink to={"/dashboard"}>
                  {" "}
                  <AiIcons.AiOutlineDashboard /> Dashboard
                </NavLink>
              </li>
            )}

          {pegawai &&
            (pegawai.strRole === "admin" ||
              pegawai.strRole === "kabid kepegawaian") && (
              <li>
                <NavLink to={"/log-activity"}>
                  {" "}
                  <AiIcons.AiFillAccountBook /> Log Activity Pegawai
                </NavLink>
              </li>
            )}

          <li>
            <NavLink to={"/data-person"}>
              {" "}
              <AiIcons.AiOutlineProfile /> Data Pribadi
            </NavLink>
          </li>

          {pegawai &&
            (pegawai.strRole === "admin" ||
            pegawai.strRole === "kabid kepegawaian" ||
              pegawai.strRole === "kepsek" ||
              pegawai.strRole === "staff TU") && (
              <div>
                <p className="menu-label">Data Pribadi Pegawai List</p>
                <ul className="menu-list">
                  <li>
                    <NavLink to={"/data-person-all-pegawai"}>
                      <IoIcons.IoMdSettings />
                      Data Pribadi Pegawai List
                    </NavLink>
                  </li>
                </ul>
              </div>
            )}

          <li>
            <NavLink to={"/riwayat-pendidikan"}>
              {" "}
              <IoIcons.IoMdSchool /> Riwayat Pendidikan
            </NavLink>
          </li>
          <li>
            <NavLink to={"/riwayat-pelatihan"}>
              {" "}
              <FaIcons.FaUserTie /> Riwayat Pelatihan
            </NavLink>
          </li>
          <li>
            <NavLink to={"/riwayat-jabatan"}>
              {" "}
              <FaIcons.FaChair /> Riwayat Jabatan
            </NavLink>
          </li>
          <li>
            <NavLink to={"/riwayat-kepangkatan"}>
              {" "}
              <FaIcons.FaTrophy /> Riwayat Kepangkatan
            </NavLink>
          </li>
          <li>
            <NavLink to={"/riwayat-keluarga"}>
              {" "}
              <AiIcons.AiFillSmile /> Riwayat Keluarga
            </NavLink>
          </li>

          <li>
            <NavLink to={"/riwayat-raport"}>
              {" "}
              <FaIcons.FaCertificate /> Riwayat Raport
            </NavLink>
          </li>

          <li>
            <NavLink to={"/riwayat-prestasi"}>
              {" "}
              <FaIcons.FaGift /> Riwayat Prestasi
            </NavLink>
          </li>
        </ul>

        {pegawai && pegawai.strRole === "admin" && (
          <div>
            <p className="menu-label">Admin</p>
            <ul className="menu-list">
              <li>
                <NavLink to={"/pegawais"}>
                  <IoIcons.IoMdSettings />
                  Pegawai
                </NavLink>
              </li>
            </ul>
          </div>
        )}
        <p className="menu-label">Settings</p>
        <ul className="menu-list">
          <li>
            <button onClick={Logout} className="button is-white">
              {" "}
              <IoIcons.IoMdLogOut /> Logout
            </button>
          </li>
        </ul>
      </aside>
    </div>
  );
};

export default Sidebar;
