import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Url from "../global";
import "./Style/globalValidasiEror.scss";

const FormEditRiwayatPrestasi = () => {
  const [juara, setJuara] = useState("");
  const [tingkatan, setTingkatan] = useState("");
  const [tahun, setTahun] = useState("");
  const [deskripsi, setDeskripsi] = useState("");
  const [status, setStatus] = useState("");
  const [komentar, setKomentar] = useState("");
  const [msg, setMsg] = useState("");
  const navigate = useNavigate();

  const { id } = useParams();
  const { pegawai } = useSelector((state) => state.auth);

  useEffect(() => {
    const getRiwayatPrestasiById = async () => {
      try {
        const response = await axios.get(`${Url}/riwayat_prestasi/${id}`);
        setJuara(response.data.strJuara);
        setTingkatan(response.data.strTingkatan);
        setTahun(response.data.strTahun);
        setDeskripsi(response.data.strDeskripsi);
        setStatus(response.data.strStatus);
        setKomentar(response.data.strKomentar);
      } catch (error) {
        if (error.response) {
          setMsg(error.response.data.msg);
        }
      }
    };
    getRiwayatPrestasiById();
  }, [id]);

  const updateRiwayatPrestasi = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("strJuara", juara);
      formData.append("strTingkatan", tingkatan);
      formData.append("strTahun", tahun);
      formData.append("strDeskripsi", deskripsi);
      formData.append("strStatus", status);
      formData.append("strKomentar", komentar);

      await axios.patch(`${Url}/riwayat_prestasi/${id}`, formData, {
        headers: {
          "Content-type": "multipart/form-data",
        },
      });
      navigate("/riwayat-prestasi");
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };

  return (
    <div>
      <h1 className="title">Riwayat Prestasi</h1>
      <h2 className="subtitle">Edit Riwayat Prestasi</h2>

      <div className="card is-shadowless">
        <div className="card-content">
          <div className="content">
            <form className="box" onSubmit={updateRiwayatPrestasi}>
              <p className="has-text-centered">{msg}</p>
              <div className="field">
                <label className="label">Juara</label>
                <div className="control">
                  <div className="select is-fullwidth">
                    <select
                      className="dropdown"
                      value={juara}
                      onChange={(e) => setJuara(e.target.value)}
                      required
                    // disabled="true"
                    >
                      <option>--pilih--</option>
                      <option>Juara 1</option>
                      <option>Juara 2</option>
                      <option>Juara 3</option>
                      <option>Harapan 1</option>
                      <option>Harapan 2</option>
                      <option>Harapan 3</option>
                    </select>
                  </div>
                </div>
              </div>


              <div className="field">
                <label className="label">Tingkatan</label>
                <div className="control">
                  <div className="select is-fullwidth">
                    <select
                      className="dropdown"
                      value={tingkatan}
                      onChange={(e) => setTingkatan(e.target.value)}
                      required
                    // disabled="true"
                    >
                      <option>--pilih--</option>
                      <option>Kota / Kabupaten</option>
                      <option>Provinsi</option>
                      <option>Nasional</option>
                      <option>Internasional</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="field">
                <label className="label">Tahun</label>
                <div className="control">
                  <div className="select is-fullwidth">
                    <select
                      className="dropdown"
                      value={tahun}
                      onChange={(e) => setTahun(e.target.value)}
                      required
                    // disabled="true"
                    >
                      <option>--- Pilih ---</option>
                      <option>2023</option>
                      <option>2024</option>
                      <option>2025</option>
                      <option>2026</option>
                      <option>2027</option>
                      <option>2028</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="field">
                <label className="label">Deskripsi Lomba</label>
                <div className="control">
                  <textarea
                    className="textarea"
                    placeholder="Deskripsi Perlombaan"
                    value={deskripsi}
                    onChange={(e) => setDeskripsi(e.target.value)}
                  />

                </div>
              </div>

              <div className="field">
                <label className="label">Komentar</label>
                <div className="control">
                  {pegawai &&
                    (pegawai.strRole === "kabid kepegawaian" ||
                      pegawai.strRole === "admin") && (
                      <input
                        type="text"
                        className="input"
                        placeholder="Komentar"
                        value={komentar}
                        onChange={(e) => setKomentar(e.target.value)}
                      />
                    )}
                  {pegawai && pegawai.strRole === "user" && (
                    <input
                      type="text"
                      className="input"
                      placeholder="Komentar"
                      value={komentar}
                      onChange={(e) => setKomentar(e.target.value)}
                      disabled
                    />
                  )}
                </div>
              </div>


              <div className="field">
                <label className="label">Status Prestasi</label>
                <div className="control">
                  <div className="select is-fullwidth">
                    {pegawai &&
                      (pegawai.strRole === "kepsek" ||
                        pegawai.strRole === "admin") && (
                        <select
                          className="dropdown"
                          value={status}
                          onChange={(e) => setStatus(e.target.value)}
                          required
                        >
                          <option>Pengajuan Sertifikat Prestasi</option>
                          <option>Disetujui Kabid Kepegawaian</option>
                          <option>Ditolak Kabid Kepegawaian</option>
                        </select>
                      )}

                    {pegawai && pegawai.strRole === "user" && (
                      <select
                        className="dropdown"
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                        required
                        disabled="true"
                      >
                        <option>Pengajuan Sertifikat Prestasi</option>
                        <option>Disetujui Kabid Kepegawaian</option>
                        <option>Ditolak Kabid Kepegawaian</option>
                      </select>
                    )}
                  </div>
                </div>
              </div>

              <div className="field">
                <div className="control">
                  <button type="submit" className="button is-success">
                    Update
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormEditRiwayatPrestasi;
