import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { useSelector } from "react-redux";
import Url from "../global";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import "./globalList.scss";

const RiwayatPendidikanList = () => {
  const [riwayatPendidikans, setRiwayatPendidikan] = useState([]);
  const { pegawai } = useSelector((state) => state.auth);
  const [query, setQuery] = useState("");
  const [query2, setQuery2] = useState("");
  const [query3, setQuery3] = useState("");

  useEffect(() => {
    getRiwayatPendidikan();
  }, []);

  const getRiwayatPendidikan = async () => {
    const response = await axios.get(`${Url}/riwayat_pendidikans`, {
      credentials: "include",
    });
    setRiwayatPendidikan(response.data);
  };

  const deleteRiwayatPendidikan = async (riwayatPendidikanId) => {
    await axios.delete(`${Url}/riwayat_pendidikans/${riwayatPendidikanId}`);
    getRiwayatPendidikan();
  };
  const download = (e) => {
    console.log(e.target.href);
    fetch(e.target.href, {
      method: "GET",
      headers: {},
    })
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
          const blob = new Blob([buffer]);
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Ijazah dan Nilai.pdf"); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const download2 = (e) => {
    console.log(e.target.href);
    fetch(e.target.href, {
      method: "GET",
      headers: {},
    })
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
          const blob = new Blob([buffer]);
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Sertifkasi.pdf"); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <h1 className="title">Riwayat Pendidikan</h1>
      {/* <h2 className="subtitle">List Of Riwayat Pendidikan</h2> */}

      <br />
      {pegawai &&
        (pegawai.strRole === "user" ||
          pegawai.strRole === "kepsek" ||
          pegawai.strRole === "staff TU" ||
          pegawai.strRole === "kabid kepegawaian" ||
          pegawai.strRole === "admin") && (
          <Link to="/riwayat-pendidikan/add" className="button is-primary mb-2">
            Add New Riwayat Pendidikan
          </Link>
        )}

      <ReactHTMLTableToExcel
        id="test-table-xls-button"
        className="download-table-xls-button"
        table="table-to-xls"
        filename="Table Riwayat Keluarga"
        sheet="tablexls"
        buttonText="Download as XLS"
      />

      <br />
      <br />
      {pegawai &&
        (pegawai.strRole === "admin" ||
          pegawai.strRole === "kepsek" ||
          pegawai.strRole === "kabid kepegawaian" ||
          pegawai.strRole === "staff TU") && <label> Masukan Nama </label>}

      {pegawai &&
        (pegawai.strRole === "admin" ||
          pegawai.strRole === "kepsek" ||
          pegawai.strRole === "kabid kepegawaian" ||
          pegawai.strRole === "staff TU") && (
          <input
            className="search"
            placeholder="Cari Nama Individu"
            onChange={(e) => setQuery(e.target.value.toLowerCase())}
          />
        )}

      {pegawai &&
        (pegawai.strRole === "admin" ||
          pegawai.strRole === "kepsek" ||
          pegawai.strRole === "kabid kepegawaian" ||
          pegawai.strRole === "staff TU") && (
          <label className="label-unit">Masukan Unit </label>
        )}
        
      {pegawai &&
        (pegawai.strRole === "admin" ||
          pegawai.strRole === "kepsek" ||
          pegawai.strRole === "kabid kepegawaian" ||
          pegawai.strRole === "staff TU") && (
          <input
            className="search"
            placeholder="Cari Berdasarkan Unit"
            onChange={(e) => setQuery2(e.target.value.toLowerCase())}
          />
        )}

      {pegawai &&
        (pegawai.strRole === "admin" ||
          pegawai.strRole === "kepsek" ||
          pegawai.strRole === "kabid kepegawaian" ||
          pegawai.strRole === "staff TU") && (
          <label className="label-unit">Masukan Tingkat Pendidikan </label>
        )}

      {pegawai &&
        (pegawai.strRole === "admin" ||
          pegawai.strRole === "kepsek" ||
          pegawai.strRole === "kabid kepegawaian" ||
          pegawai.strRole === "staff TU") && (
          <input
            className="search"
            placeholder="Cari Berdasarkan Tingkat Pendidikan"
            onChange={(e) => setQuery3(e.target.value.toLowerCase())}
          />
        )}

      <br />
      <br />

      <div className="table__wrapper_Scroll_Log">
        <table
          className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth"
          id="table-to-xls"
        >
          <thead>
            <tr>
              <th>No</th>
              <th>Tingkat Pendidikan</th>
              <th>Nomor Ijazah</th>
              <th>Tanggal Lulus</th>
              <th>Tempat Sekolah</th>
              <th>Jurusan</th>
              <th>Status</th>
              <th>Ijazah dan Transkrip Nilai</th>
              <th>Sertifikasi</th>
              <th>Sertifikat Sertifikasi</th>
              <th>Created by</th>
              <th>Komentar</th>
              <th>Unit</th>

              <th>Action</th>
            </tr>
          </thead>

          {pegawai &&
            (pegawai.strRole === "admin" ||
              pegawai.strRole === "kabid kepegawaian") && (
              <tbody>
                {riwayatPendidikans
                  .filter(
                    (riwayatPendidikan) =>
                      riwayatPendidikan.pegawai.strNama
                        .toLowerCase()
                        .includes(query) &&
                      riwayatPendidikan.pegawai.strUnit
                        .toLowerCase()
                        .includes(query2) &&
                      riwayatPendidikan.strTingkatPendidikan
                        .toLowerCase()
                        .includes(query3)
                  )
                  .map((riwayatPendidikan, index) => (
                    <tr key={riwayatPendidikan.uuid}>
                      {index === 0}
                      <td>{index + 1}</td>
                      <td>{riwayatPendidikan.strTingkatPendidikan}</td>
                      <td>{riwayatPendidikan.strNoIjazah}</td>
                      <td>
                        {moment(riwayatPendidikan.dateTglLulus).format(
                          "DD/MM/YYYY"
                        )}
                      </td>
                      <td>{riwayatPendidikan.strTempatSekolah}</td>
                      <td>{riwayatPendidikan.strJurusan}</td>
                      <td>{riwayatPendidikan.strStatus}</td>
                      <td>
                        <a
                          href={riwayatPendidikan.url}
                          download
                          onClick={(e) => download(e)}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i className="fa fa-download" />
                          Download Ijazah dan Transkrip Nilai
                        </a>
                      </td>
                      <td>{riwayatPendidikan.strSertifikasi}</td>
                      <td>
                        {" "}
                        <a
                          href={riwayatPendidikan.urlSertifikasi}
                          download2
                          onClick={(e) => download2(e)}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i className="fa fa-download" />
                          Download Sertifikasi
                        </a>
                      </td>
                      <td>{riwayatPendidikan.pegawai.strNama}</td>
                      <td>{riwayatPendidikan.strKomentar}</td>
                      <td>{riwayatPendidikan.pegawai.strUnit}</td>

                      <td>
                        <Link
                          to={`/riwayat-pendidikan/edit/${riwayatPendidikan.uuid}`}
                          className="button is-small is-info"
                        >
                          Edit
                        </Link>
                        <button
                          onClick={() =>
                            deleteRiwayatPendidikan(riwayatPendidikan.uuid)
                          }
                          className="button is-small is-danger"
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            )}

          {pegawai && pegawai.strRole === "user" && (
            <tbody>
              {riwayatPendidikans.map((riwayatPendidikan, index) =>
                riwayatPendidikan.strStatus ===
                  "Pengajuan Sertifikat Pendidikan" ||
                riwayatPendidikan.strStatus === "Ditolak Kabid Kepegawaian" ? (
                  <tr key={riwayatPendidikan.uuid}>
                    {index === 0}
                    <td>{index + 1}</td>
                    <td>{riwayatPendidikan.strTingkatPendidikan}</td>
                    <td>{riwayatPendidikan.strNoIjazah}</td>
                    <td>
                      {moment(riwayatPendidikan.dateTglLulus).format(
                        "DD/MM/YYYY"
                      )}
                    </td>
                    <td>{riwayatPendidikan.strTempatSekolah}</td>
                    <td>{riwayatPendidikan.strJurusan}</td>
                    <td>{riwayatPendidikan.strStatus}</td>
                    <td>
                      <a
                        href={riwayatPendidikan.url}
                        download
                        onClick={(e) => download(e)}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fa fa-download" />
                        Download Ijazah dan Transkrip Nilai
                      </a>
                    </td>
                    <td>{riwayatPendidikan.strSertifikasi}</td>
                    <td>
                      {" "}
                      <a
                        href={riwayatPendidikan.urlSertifikasi}
                        download2
                        onClick={(e) => download2(e)}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fa fa-download" />
                        Download Sertifikasi
                      </a>
                    </td>
                    <td>{riwayatPendidikan.pegawai.strNama}</td>
                    <td>{riwayatPendidikan.strKomentar}</td>
                    <td>{riwayatPendidikan.pegawai.strUnit}</td>

                    <td>
                      <Link
                        to={`/riwayat-pendidikan/edit/${riwayatPendidikan.uuid}`}
                        className="button is-small is-info"
                      >
                        Edit
                      </Link>
                      <button
                        onClick={() =>
                          deleteRiwayatPendidikan(riwayatPendidikan.uuid)
                        }
                        className="button is-small is-danger"
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ) : (
                  <tr key={riwayatPendidikan.uuid}>
                    {index === 0}
                    <td>{index + 1}</td>
                    <td>{riwayatPendidikan.strTingkatPendidikan}</td>
                    <td>{riwayatPendidikan.strNoIjazah}</td>
                    <td>
                      {moment(riwayatPendidikan.dateTglLulus).format(
                        "DD/MM/YYYY"
                      )}
                    </td>
                    <td>{riwayatPendidikan.strTempatSekolah}</td>
                    <td>{riwayatPendidikan.strJurusan}</td>
                    <td>{riwayatPendidikan.strStatus}</td>
                    <td>
                      <a
                        href={riwayatPendidikan.url}
                        download
                        onClick={(e) => download(e)}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fa fa-download" />
                        Download Ijazah dan Transkrip Nilai
                      </a>
                    </td>
                    <td>{riwayatPendidikan.strSertifikasi}</td>
                    <td>
                      {" "}
                      <a
                        href={riwayatPendidikan.urlSertifikasi}
                        download2
                        onClick={(e) => download2(e)}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fa fa-download" />
                        Download Sertifikasi
                      </a>
                    </td>
                    <td>{riwayatPendidikan.pegawai.strNama}</td>
                    <td>{riwayatPendidikan.strKomentar}</td>
                    <td>{riwayatPendidikan.pegawai.strUnit}</td>
                  </tr>
                )
              )}
            </tbody>
          )}

          {pegawai &&
            (pegawai.strRole === "kepsek" || pegawai.strRole === "staff TU") &&
            pegawai.strUnit === "PAUD IT Al Hasanah 1" && (
              <tbody>
                {riwayatPendidikans
                  .filter(
                    (riwayatPendidikan) =>
                      riwayatPendidikan.pegawai.strNama
                        .toLowerCase()
                        .includes(query) &&
                      riwayatPendidikan.pegawai.strUnit
                        .toLowerCase()
                        .includes(query2)
                  )
                  .map((riwayatPendidikan, index) =>
                    riwayatPendidikan.pegawai.strUnit ===
                    "PAUD IT Al Hasanah 1" ? (
                      <tr key={riwayatPendidikan.uuid}>
                        {index === 0}
                        <td>{index + 1}</td>
                        <td>{riwayatPendidikan.strTingkatPendidikan}</td>
                        <td>{riwayatPendidikan.strNoIjazah}</td>
                        <td>
                          {moment(riwayatPendidikan.dateTglLulus).format(
                            "DD/MM/YYYY"
                          )}
                        </td>
                        <td>{riwayatPendidikan.strTempatSekolah}</td>
                        <td>{riwayatPendidikan.strJurusan}</td>
                        <td>{riwayatPendidikan.strStatus}</td>
                        <td>
                          <a
                            href={riwayatPendidikan.url}
                            download
                            onClick={(e) => download(e)}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <i className="fa fa-download" />
                            Download Ijazah dan Transkrip Nilai
                          </a>
                        </td>
                        <td>{riwayatPendidikan.strSertifikasi}</td>
                        <td>
                          {" "}
                          <a
                            href={riwayatPendidikan.urlSertifikasi}
                            download2
                            onClick={(e) => download2(e)}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <i className="fa fa-download" />
                            Download Sertifikasi
                          </a>
                        </td>
                        <td>{riwayatPendidikan.pegawai.strNama}</td>
                        <td>{riwayatPendidikan.strKomentar}</td>
                        <td>{riwayatPendidikan.pegawai.strUnit}</td>

                        <td>
                          <Link
                            to={`/riwayat-pendidikan/edit/${riwayatPendidikan.uuid}`}
                            className="button is-small is-info"
                          >
                            Edit
                          </Link>
                          <button
                            onClick={() =>
                              deleteRiwayatPendidikan(riwayatPendidikan.uuid)
                            }
                            className="button is-small is-danger"
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ) : null
                  )}
              </tbody>
            )}
          {pegawai &&
            (pegawai.strRole === "kepsek" || pegawai.strRole === "staff TU") &&
            pegawai.strUnit === "PAUD IT Al Hasanah 2" && (
              <tbody>
                {riwayatPendidikans
                  .filter(
                    (riwayatPendidikan) =>
                      riwayatPendidikan.pegawai.strNama
                        .toLowerCase()
                        .includes(query) &&
                      riwayatPendidikan.pegawai.strUnit
                        .toLowerCase()
                        .includes(query2)
                  )
                  .map((riwayatPendidikan, index) =>
                    riwayatPendidikan.pegawai.strUnit ===
                    "PAUD IT Al Hasanah 2" ? (
                      <tr key={riwayatPendidikan.uuid}>
                        {index === 0}
                        <td>{index + 1}</td>
                        <td>{riwayatPendidikan.strTingkatPendidikan}</td>
                        <td>{riwayatPendidikan.strNoIjazah}</td>
                        <td>
                          {moment(riwayatPendidikan.dateTglLulus).format(
                            "DD/MM/YYYY"
                          )}
                        </td>
                        <td>{riwayatPendidikan.strTempatSekolah}</td>
                        <td>{riwayatPendidikan.strJurusan}</td>
                        <td>{riwayatPendidikan.strStatus}</td>
                        <td>
                          <a
                            href={riwayatPendidikan.url}
                            download
                            onClick={(e) => download(e)}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <i className="fa fa-download" />
                            Download Ijazah dan Transkrip Nilai
                          </a>
                        </td>
                        <td>{riwayatPendidikan.strSertifikasi}</td>
                        <td>
                          {" "}
                          <a
                            href={riwayatPendidikan.urlSertifikasi}
                            download2
                            onClick={(e) => download2(e)}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <i className="fa fa-download" />
                            Download Sertifikasi
                          </a>
                        </td>
                        <td>{riwayatPendidikan.pegawai.strNama}</td>
                        <td>{riwayatPendidikan.strKomentar}</td>
                        <td>{riwayatPendidikan.pegawai.strUnit}</td>

                        <td>
                          <Link
                            to={`/riwayat-pendidikan/edit/${riwayatPendidikan.uuid}`}
                            className="button is-small is-info"
                          >
                            Edit
                          </Link>
                          <button
                            onClick={() =>
                              deleteRiwayatPendidikan(riwayatPendidikan.uuid)
                            }
                            className="button is-small is-danger"
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ) : null
                  )}
              </tbody>
            )}
          {pegawai &&
            (pegawai.strRole === "kepsek" || pegawai.strRole === "staff TU") &&
            pegawai.strUnit === "PAUD IT Al Hasanah 2" && (
              <tbody>
                {riwayatPendidikans
                  .filter(
                    (riwayatPendidikan) =>
                      riwayatPendidikan.pegawai.strNama
                        .toLowerCase()
                        .includes(query) &&
                      riwayatPendidikan.pegawai.strUnit
                        .toLowerCase()
                        .includes(query2)
                  )
                  .map((riwayatPendidikan, index) =>
                    riwayatPendidikan.pegawai.strUnit ===
                    "PAUD IT Al Hasanah 2" ? (
                      <tr key={riwayatPendidikan.uuid}>
                        {index === 0}
                        <td>{index + 1}</td>
                        <td>{riwayatPendidikan.strTingkatPendidikan}</td>
                        <td>{riwayatPendidikan.strNoIjazah}</td>
                        <td>
                          {moment(riwayatPendidikan.dateTglLulus).format(
                            "DD/MM/YYYY"
                          )}
                        </td>
                        <td>{riwayatPendidikan.strTempatSekolah}</td>
                        <td>{riwayatPendidikan.strJurusan}</td>
                        <td>{riwayatPendidikan.strStatus}</td>
                        <td>
                          <a
                            href={riwayatPendidikan.url}
                            download
                            onClick={(e) => download(e)}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <i className="fa fa-download" />
                            Download Ijazah dan Transkrip Nilai
                          </a>
                        </td>
                        <td>{riwayatPendidikan.strSertifikasi}</td>
                        <td>
                          {" "}
                          <a
                            href={riwayatPendidikan.urlSertifikasi}
                            download2
                            onClick={(e) => download2(e)}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <i className="fa fa-download" />
                            Download Sertifikasi
                          </a>
                        </td>
                        <td>{riwayatPendidikan.pegawai.strNama}</td>
                        <td>{riwayatPendidikan.strKomentar}</td>
                        <td>{riwayatPendidikan.pegawai.strUnit}</td>

                        <td>
                          <Link
                            to={`/riwayat-pendidikan/edit/${riwayatPendidikan.uuid}`}
                            className="button is-small is-info"
                          >
                            Edit
                          </Link>
                          <button
                            onClick={() =>
                              deleteRiwayatPendidikan(riwayatPendidikan.uuid)
                            }
                            className="button is-small is-danger"
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ) : null
                  )}
              </tbody>
            )}

          {pegawai &&
            (pegawai.strRole === "kepsek" || pegawai.strRole === "staff TU") &&
            pegawai.strUnit === "SDIT Al Hasanah 1" && (
              <tbody>
                {riwayatPendidikans
                  .filter(
                    (riwayatPendidikan) =>
                      riwayatPendidikan.pegawai.strNama
                        .toLowerCase()
                        .includes(query) &&
                      riwayatPendidikan.pegawai.strUnit
                        .toLowerCase()
                        .includes(query2)
                  )
                  .map((riwayatPendidikan, index) =>
                    riwayatPendidikan.pegawai.strUnit ===
                    "SDIT Al Hasanah 1" ? (
                      <tr key={riwayatPendidikan.uuid}>
                        {index === 0}
                        <td>{index + 1}</td>
                        <td>{riwayatPendidikan.strTingkatPendidikan}</td>
                        <td>{riwayatPendidikan.strNoIjazah}</td>
                        <td>
                          {moment(riwayatPendidikan.dateTglLulus).format(
                            "DD/MM/YYYY"
                          )}
                        </td>
                        <td>{riwayatPendidikan.strTempatSekolah}</td>
                        <td>{riwayatPendidikan.strJurusan}</td>
                        <td>{riwayatPendidikan.strStatus}</td>
                        <td>
                          <a
                            href={riwayatPendidikan.url}
                            download
                            onClick={(e) => download(e)}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <i className="fa fa-download" />
                            Download Ijazah dan Transkrip Nilai
                          </a>
                        </td>
                        <td>{riwayatPendidikan.strSertifikasi}</td>
                        <td>
                          {" "}
                          <a
                            href={riwayatPendidikan.urlSertifikasi}
                            download2
                            onClick={(e) => download2(e)}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <i className="fa fa-download" />
                            Download Sertifikasi
                          </a>
                        </td>
                        <td>{riwayatPendidikan.pegawai.strNama}</td>
                        <td>{riwayatPendidikan.strKomentar}</td>
                        <td>{riwayatPendidikan.pegawai.strUnit}</td>

                        <td>
                          <Link
                            to={`/riwayat-pendidikan/edit/${riwayatPendidikan.uuid}`}
                            className="button is-small is-info"
                          >
                            Edit
                          </Link>
                          <button
                            onClick={() =>
                              deleteRiwayatPendidikan(riwayatPendidikan.uuid)
                            }
                            className="button is-small is-danger"
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ) : null
                  )}
              </tbody>
            )}

          {pegawai &&
            (pegawai.strRole === "kepsek" || pegawai.strRole === "staff TU") &&
            pegawai.strUnit === "SDIT Al Hasanah 2" && (
              <tbody>
                {riwayatPendidikans
                  .filter(
                    (riwayatPendidikan) =>
                      riwayatPendidikan.pegawai.strNama
                        .toLowerCase()
                        .includes(query) &&
                      riwayatPendidikan.pegawai.strUnit
                        .toLowerCase()
                        .includes(query2)
                  )
                  .map((riwayatPendidikan, index) =>
                    riwayatPendidikan.pegawai.strUnit ===
                    "SDIT Al Hasanah 2" ? (
                      <tr key={riwayatPendidikan.uuid}>
                        {index === 0}
                        <td>{index + 1}</td>
                        <td>{riwayatPendidikan.strTingkatPendidikan}</td>
                        <td>{riwayatPendidikan.strNoIjazah}</td>
                        <td>
                          {moment(riwayatPendidikan.dateTglLulus).format(
                            "DD/MM/YYYY"
                          )}
                        </td>
                        <td>{riwayatPendidikan.strTempatSekolah}</td>
                        <td>{riwayatPendidikan.strJurusan}</td>
                        <td>{riwayatPendidikan.strStatus}</td>
                        <td>
                          <a
                            href={riwayatPendidikan.url}
                            download
                            onClick={(e) => download(e)}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <i className="fa fa-download" />
                            Download Ijazah dan Transkrip Nilai
                          </a>
                        </td>
                        <td>{riwayatPendidikan.strSertifikasi}</td>
                        <td>
                          {" "}
                          <a
                            href={riwayatPendidikan.urlSertifikasi}
                            download2
                            onClick={(e) => download2(e)}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <i className="fa fa-download" />
                            Download Sertifikasi
                          </a>
                        </td>
                        <td>{riwayatPendidikan.pegawai.strNama}</td>
                        <td>{riwayatPendidikan.strKomentar}</td>
                        <td>{riwayatPendidikan.pegawai.strUnit}</td>

                        <td>
                          <Link
                            to={`/riwayat-pendidikan/edit/${riwayatPendidikan.uuid}`}
                            className="button is-small is-info"
                          >
                            Edit
                          </Link>
                          <button
                            onClick={() =>
                              deleteRiwayatPendidikan(riwayatPendidikan.uuid)
                            }
                            className="button is-small is-danger"
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ) : null
                  )}
              </tbody>
            )}
          {pegawai &&
            (pegawai.strRole === "kepsek" || pegawai.strRole === "staff TU") &&
            pegawai.strUnit === "SMPI Al Hasanah" && (
              <tbody>
                {riwayatPendidikans
                  .filter(
                    (riwayatPendidikan) =>
                      riwayatPendidikan.pegawai.strNama
                        .toLowerCase()
                        .includes(query) &&
                      riwayatPendidikan.pegawai.strUnit
                        .toLowerCase()
                        .includes(query2)
                  )
                  .map((riwayatPendidikan, index) =>
                    riwayatPendidikan.pegawai.strUnit === "SMPI Al Hasanah" ? (
                      <tr key={riwayatPendidikan.uuid}>
                        {index === 0}
                        <td>{index + 1}</td>
                        <td>{riwayatPendidikan.strTingkatPendidikan}</td>
                        <td>{riwayatPendidikan.strNoIjazah}</td>
                        <td>
                          {moment(riwayatPendidikan.dateTglLulus).format(
                            "DD/MM/YYYY"
                          )}
                        </td>
                        <td>{riwayatPendidikan.strTempatSekolah}</td>
                        <td>{riwayatPendidikan.strJurusan}</td>
                        <td>{riwayatPendidikan.strStatus}</td>
                        <td>
                          <a
                            href={riwayatPendidikan.url}
                            download
                            onClick={(e) => download(e)}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <i className="fa fa-download" />
                            Download Ijazah dan Transkrip Nilai
                          </a>
                        </td>
                        <td>{riwayatPendidikan.strSertifikasi}</td>
                        <td>
                          {" "}
                          <a
                            href={riwayatPendidikan.urlSertifikasi}
                            download2
                            onClick={(e) => download2(e)}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <i className="fa fa-download" />
                            Download Sertifikasi
                          </a>
                        </td>
                        <td>{riwayatPendidikan.pegawai.strNama}</td>
                        <td>{riwayatPendidikan.strKomentar}</td>
                        <td>{riwayatPendidikan.pegawai.strUnit}</td>
                        <td>
                          <Link
                            to={`/riwayat-pendidikan/edit/${riwayatPendidikan.uuid}`}
                            className="button is-small is-info"
                          >
                            Edit
                          </Link>
                          <button
                            onClick={() =>
                              deleteRiwayatPendidikan(riwayatPendidikan.uuid)
                            }
                            className="button is-small is-danger"
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ) : null
                  )}
              </tbody>
            )}

          {pegawai &&
            (pegawai.strRole === "kepsek" || pegawai.strRole === "staff TU") &&
            pegawai.strUnit === "SMAIT Al Hasanah" && (
              <tbody>
                {riwayatPendidikans
                  .filter(
                    (riwayatPendidikan) =>
                      riwayatPendidikan.pegawai.strNama
                        .toLowerCase()
                        .includes(query) &&
                      riwayatPendidikan.pegawai.strUnit
                        .toLowerCase()
                        .includes(query2)
                  )
                  .map((riwayatPendidikan, index) =>
                    riwayatPendidikan.pegawai.strUnit === "SMAIT Al Hasanah" ? (
                      <tr key={riwayatPendidikan.uuid}>
                        {index === 0}
                        <td>{index + 1}</td>
                        <td>{riwayatPendidikan.strTingkatPendidikan}</td>
                        <td>{riwayatPendidikan.strNoIjazah}</td>
                        <td>
                          {moment(riwayatPendidikan.dateTglLulus).format(
                            "DD/MM/YYYY"
                          )}
                        </td>
                        <td>{riwayatPendidikan.strTempatSekolah}</td>
                        <td>{riwayatPendidikan.strJurusan}</td>
                        <td>{riwayatPendidikan.strStatus}</td>
                        <td>
                          <a
                            href={riwayatPendidikan.url}
                            download
                            onClick={(e) => download(e)}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <i className="fa fa-download" />
                            Download Ijazah dan Transkrip Nilai
                          </a>
                        </td>
                        <td>{riwayatPendidikan.strSertifikasi}</td>
                        <td>
                          {" "}
                          <a
                            href={riwayatPendidikan.urlSertifikasi}
                            download2
                            onClick={(e) => download2(e)}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <i className="fa fa-download" />
                            Download Sertifikasi
                          </a>
                        </td>
                        <td>{riwayatPendidikan.pegawai.strNama}</td>
                        <td>{riwayatPendidikan.strKomentar}</td>
                        <td>{riwayatPendidikan.pegawai.strUnit}</td>
                        <td>
                          <Link
                            to={`/riwayat-pendidikan/edit/${riwayatPendidikan.uuid}`}
                            className="button is-small is-info"
                          >
                            Edit
                          </Link>
                          <button
                            onClick={() =>
                              deleteRiwayatPendidikan(riwayatPendidikan.uuid)
                            }
                            className="button is-small is-danger"
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ) : null
                  )}
              </tbody>
            )}

          {pegawai &&
            (pegawai.strRole === "kepsek" || pegawai.strRole === "staff TU") &&
            pegawai.strUnit === "Pondok Pesantren" && (
              <tbody>
                {riwayatPendidikans
                  .filter(
                    (riwayatPendidikan) =>
                      riwayatPendidikan.pegawai.strNama
                        .toLowerCase()
                        .includes(query) &&
                      riwayatPendidikan.pegawai.strUnit
                        .toLowerCase()
                        .includes(query2)
                  )
                  .map((riwayatPendidikan, index) =>
                    riwayatPendidikan.pegawai.strUnit === "Pondok Pesantren" ? (
                      <tr key={riwayatPendidikan.uuid}>
                        {index === 0}
                        <td>{index + 1}</td>
                        <td>{riwayatPendidikan.strTingkatPendidikan}</td>
                        <td>{riwayatPendidikan.strNoIjazah}</td>
                        <td>
                          {moment(riwayatPendidikan.dateTglLulus).format(
                            "DD/MM/YYYY"
                          )}
                        </td>
                        <td>{riwayatPendidikan.strTempatSekolah}</td>
                        <td>{riwayatPendidikan.strJurusan}</td>
                        <td>{riwayatPendidikan.strStatus}</td>
                        <td>
                          <a
                            href={riwayatPendidikan.url}
                            download
                            onClick={(e) => download(e)}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <i className="fa fa-download" />
                            Download Ijazah dan Transkrip Nilai
                          </a>
                        </td>
                        <td>{riwayatPendidikan.strSertifikasi}</td>
                        <td>
                          {" "}
                          <a
                            href={riwayatPendidikan.urlSertifikasi}
                            download2
                            onClick={(e) => download2(e)}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <i className="fa fa-download" />
                            Download Sertifikasi
                          </a>
                        </td>
                        <td>{riwayatPendidikan.pegawai.strNama}</td>
                        <td>{riwayatPendidikan.strKomentar}</td>
                        <td>{riwayatPendidikan.pegawai.strUnit}</td>
                        <td>
                          <Link
                            to={`/riwayat-pendidikan/edit/${riwayatPendidikan.uuid}`}
                            className="button is-small is-info"
                          >
                            Edit
                          </Link>
                          <button
                            onClick={() =>
                              deleteRiwayatPendidikan(riwayatPendidikan.uuid)
                            }
                            className="button is-small is-danger"
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ) : null
                  )}
              </tbody>
            )}
        </table>
      </div>
    </div>
  );
};

export default RiwayatPendidikanList;
