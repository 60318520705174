import axios from "axios";
import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import "./Style/globalValidasiEror.scss";
import Url from "../global";

//import { useEffect } from "react";

const FormAddRiwayatPelatihan = () => {
  const [namaPelatihan, setNamaPelatihan] = useState("");
  const [tglMulai, setTglMulai] = useState("");
  const [tglSelesai, setTglSelesai] = useState("");
  const [noSertifikat, setNoSertifikat] = useState("");
  const [status, setStatus] = useState("");
  //const [komentar, setKomentar] = useState("");
  const [penyelenggara, setPenyelenggara] = useState("");
  const [msg, setMsg] = useState("");
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const [fileUrl, setFileUrl] = useState(null);

  const [setError] = useState(null);

  const PdfViewer = ({ fileUrl }) => {
    return (
      <div>
        {fileUrl ? (
          <embed
            src={fileUrl}
            type="application/pdf"
            width="70%"
            height="600px"
          />
        ) : (
          <p>No PDF file uploaded</p>
        )}
      </div>
    );
  };
  const {
    register,
    reset,
    trigger,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleChange = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile && selectedFile.type === "application/pdf") {
      setFile(selectedFile);
      setFileUrl(URL.createObjectURL(selectedFile));
    } else {
      setFile(null);
      setError("File harus berupa PDF");
    }
  };

  const saveRiwayatPelatihan = async (e) => {
    if (file) {
      const formData = new FormData();
      formData.append("strNamaPelatihan", namaPelatihan);
      formData.append("dateTglMulai", tglMulai);
      formData.append("dateTglSelesai", tglSelesai);
      formData.append("strNoSertifikat", noSertifikat);
      formData.append("strStatus", status);
      formData.append("strPenyelenggara", penyelenggara);
      formData.append("pdfFile", file);
    

      for (let i = 0; i < file.length; i++) {
        formData.append("files", file[i]);
      }

      // for (let i = 0; i < fileSertifikasi.length; i++) {
      //   formData.append("files", fileSertifikasi[i]);
      // }

      try {
        await axios.post(`${Url}/riwayat_pelatihans`, formData, {
          headers: {
            "Content-type": "multipart/form-data",
          },
        });
        navigate("/riwayat-pelatihan");
        reset();
      } catch (error) {
        if (error.response) {
          setMsg(error.response.data.msg);
        }
      }
    }
  };

  return (
    <div>
      <h1 className="title">Riwayat Pelatihan</h1>
      <h2 className="subtitle">Add New Riwayat Pelatihan</h2>

      <div className="card is-shadowless">
        <div className="card-content">
          <div className="content">
            <form className="box" onSubmit={handleSubmit(saveRiwayatPelatihan)}>
              <p className="has-text-centered">{msg}</p>
              <div className="field">
                <label className="label">Nama Pelatihan</label>
                <div className="control">
                  <input
                    type="text"
                    className={`input ${errors.namaPelatihan && "invalid"}`}
                    {...register("namaPelatihan", {
                      required: {
                        value: true,
                        message: "Nama Pelatihan Wajib Di isi!",
                      },
                      minLength: {
                        value: 5,
                        message: "minimal 5 karakter",
                      },
                    })}
                    onKeyUp={() => {
                      trigger("namaPelatihan");
                    }}
                    placeholder="Nama Pelatihan"
                    value={namaPelatihan}
                    onChange={(e) => setNamaPelatihan(e.target.value)}
                  />
                  {errors.namaPelatihan && (
                    <small className="allValidasiError">
                      {errors.namaPelatihan.message}
                    </small>
                  )}
                </div>
              </div>

              <div className="field">
                <label className="label">Tanggal Mulai</label>
                <div className="control">
                  <Form.Group controlId="duedate" className="dtp">
                    <Form.Control
                      type="date"
                      className={`duedate ${errors.tglMulai && "invalid"}`}
                      {...register("tglMulai", {
                        required: {
                          value: true,
                          message: "Pilih Sesuai Tanggal Mulai Pelatihan!",
                        },
                      })}
                      onKeyUp={() => {
                        trigger("tglMulai");
                      }}
                      placeholder="Tanggal Mulai"
                      value={tglMulai}
                      onChange={(e) => setTglMulai(e.target.value)}
                    />
                    {errors.tglMulai && (
                      <small className="allValidasiError">
                        {errors.tglMulai.message}
                      </small>
                    )}
                  </Form.Group>
                </div>
              </div>
              <div className="field">
                <label className="label">Tanggal Selesai</label>
                <div className="control">
                  <Form.Group controlId="duedate" className="dtp">
                    <Form.Control
                      type="date"
                      className={`duedate ${errors.tglSelesai && "invalid"}`}
                      {...register("tglSelesai", {
                        required: {
                          value: true,
                          message: "Pilih Sesuai Tanggal Selesai Pelatihan!",
                        },
                      })}
                      onKeyUp={() => {
                        trigger("tglSelesai");
                      }}
                      placeholder="Tanggal Selesai"
                      value={tglSelesai}
                      onChange={(e) => setTglSelesai(e.target.value)}
                    />
                    <br />
                    {errors.tglSelesai && (
                      <small className="allValidasiError">
                        {errors.tglSelesai.message}
                      </small>
                    )}
                  </Form.Group>
                </div>
              </div>

              <div className="field">
                <label className="label">Nomor Sertifikat</label>
                <div className="control">
                  <input
                    type="text"
                    className={`input ${errors.noSertifikat && "invalid"}`}
                    {...register("noSertifikat", {
                      required: {
                        value: true,
                        message: "Nomor Sertifikat Pelatihan Wajib Di isi!",
                      },
                    })}
                    onKeyUp={() => {
                      trigger("noSertifikat");
                    }}
                    placeholder="Nomor Sertifikat Pelatihan"
                    value={noSertifikat}
                    onChange={(e) => setNoSertifikat(e.target.value)}
                  />
                  {errors.noSertifikat && (
                    <small className="allValidasiError">
                      {errors.noSertifikat.message}
                    </small>
                  )}
                </div>
              </div>

              <div className="field">
                <label className="label">Penyelenggara</label>
                <div className="control">
                  <input
                    type="text"
                    className={`input ${errors.penyelenggara && "invalid"}`}
                    {...register("penyelenggara", {
                      required: {
                        value: true,
                        message: "Penyelenggara Pelatihan Wajib Di isi!",
                      },
                    })}
                    onKeyUp={() => {
                      trigger("penyelenggara");
                    }}
                    placeholder="Penyelenggara"
                    value={penyelenggara}
                    onChange={(e) => setPenyelenggara(e.target.value)}
                  />

                  {errors.penyelenggara && (
                    <small className="allValidasiError">
                      {errors.penyelenggara.message}
                    </small>
                  )}
                </div>
              </div>

              <div className="field">
                <label className="label">Status Pelatihan</label>
                <div className="control">
                  <div className="select is-fullwidth">
                    <select
                      className="dropdown"
                      value={status}
                      onChange={(e) => setStatus(e.target.value)}
                      required
                      disabled="true"
                    >
                      <option>Pengajuan Sertifikat Pelatihan</option>
                      <option>Disetujui Kabid Kepegawaian</option>
                      <option>Ditolak Kabid Kepegawaian</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="field">
                <label className="label">Sertifikat Pelatihan</label>
                <div className="control">
                  <div className="file">
                    <label className="file-label">
                      <input
                        type="file"
                        accept="application/pdf"
                        onChange={handleChange}
                      />
                      {/* <button type="submit">Upload</button> */}
                    </label>
                  </div>
                  <br />
                  <PdfViewer fileUrl={fileUrl} />
                </div>
              </div>

         
              <br />
       

              <div className="field">
                <div className="control">
                  <button type="submit" className="button is-success">
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormAddRiwayatPelatihan;
