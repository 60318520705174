import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { useSelector } from "react-redux";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import "./globalList.scss";
import Url from "../global";
import * as FaIcons from "react-icons/fa";

const RiwayatRaportList = () => {
  const [riwayatRaports, setRiwayatRaport] = useState([]);
  const { pegawai } = useSelector((state) => state.auth);
  const [query, setQuery] = useState("");
  const [query2, setQuery2] = useState("");
  const [order, setOrder] = useState("ASC");

  const sorting = (col) => {
    if (order === "ASC") {
      const sorted = [...riwayatRaports].sort((a, b) =>
        a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
      );
      setRiwayatRaport(sorted);
      setOrder("DSC");
    }
    if (order === "DSC") {
      const sorted = [...riwayatRaports].sort((a, b) =>
        a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
      );
      setRiwayatRaport(sorted);
      setOrder("ASC");
    }
  };

  useEffect(() => {
    getRiwayatRaport();
  }, []);

  const getRiwayatRaport = async () => {
    const response = await axios.get(`${Url}/riwayat_raports`);
    setRiwayatRaport(response.data);
  };

  const deleteRiwayatRaport = async (riwayatRaportId) => {
    await axios.delete(`${Url}/riwayat_raports/${riwayatRaportId}`);
    getRiwayatRaport();
  };

  const download = (e) => {
    console.log(e.target.href);
    fetch(e.target.href, {
      method: "GET",
      headers: {},
    })
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
          const blob = new Blob([buffer]);
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Raport.pdf"); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <h1 className="title">Riwayat Raport</h1>

      {pegawai &&
        (pegawai.strRole === "user" ||
          pegawai.strRole === "kepsek" ||
          pegawai.strRole === "staff TU" ||
          pegawai.strRole === "kabid kepegawaian" ||
          pegawai.strRole === "admin") && (
          <Link to="/riwayat-raport/add" className="button is-primary mb-2">
            Add New Riwayat Raport
          </Link>
        )}

      <ReactHTMLTableToExcel
        id="test-table-xls-button"
        className="download-table-xls-button"
        table="table-to-xls"
        filename="Table Riwayat Raport"
        sheet="tablexls"
        buttonText="Download as XLS"
      />
      <br />
      {pegawai &&
        (pegawai.strRole === "admin" ||
          pegawai.strRole === "kepsek" ||
          pegawai.strRole === "kabid kepegawaian" ||
          pegawai.strRole === "staff TU") && (
          <label> Semester Ke Berapa </label>
        )}
      {pegawai &&
        (pegawai.strRole === "admin" ||
          pegawai.strRole === "kepsek" ||
          pegawai.strRole === "kabid kepegawaian" ||
          pegawai.strRole === "staff TU") && (
          <input
            className="search"
            placeholder="Cari Nama Individu"
            onChange={(e) => setQuery(e.target.value.toLowerCase())}
          />
        )}

      {pegawai &&
        (pegawai.strRole === "admin" ||
          pegawai.strRole === "kepsek" ||
          pegawai.strRole === "kabid kepegawaian" ||
          pegawai.strRole === "staff TU") && (
          <label className="label-unit">Masukan Unit </label>
        )}

      {pegawai &&
        (pegawai.strRole === "admin" ||
          pegawai.strRole === "kepsek" ||
          pegawai.strRole === "kabid kepegawaian" ||
          pegawai.strRole === "staff TU") && (
          <input
            className="search"
            placeholder="Cari Berdasarkan Unit"
            onChange={(e) => setQuery2(e.target.value.toLowerCase())}
          />
        )}

      <div className="table__wrapper_Scroll_Log">
        <table
          className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth"
          id="table-to-xls"
        >
          <thead>
            <tr>
              <th className="has-text-centered">No</th>
              <th className="has-text-centered">Semester</th>
              <th className="has-text-centered">Nilai Raport</th>
              <th className="has-text-centered">Grade Raport</th>
              <th className="has-text-centered">Tanggal SK Raport</th>
              <th
                className="has-text-centered"
                onClick={() => sorting("strStatus")}
              >
                Status <FaIcons.FaSort />
              </th>
              <th className="has-text-centered">Raport</th>
              <th className="has-text-centered">Komentar</th>
              <th className="has-text-centered">Unit</th>
              <th className="has-text-centered">Created by</th>
              <th className="has-text-centered">Action</th>
            </tr>
          </thead>
          {pegawai && pegawai.strRole === "user" && (
            <tbody>
              {riwayatRaports.map((riwayatRaport, index) =>
                riwayatRaport.strStatus === "Pengajuan Raport" ||
                riwayatRaport.strStatus === "Ditolak Kabid Kepegawaian" ? (
                  <tr key={riwayatRaport.uuid}>
                    {index === 0}
                    <td>{index + 1}</td>
                    <td>{riwayatRaport.strSemester}</td>
                    <td>{riwayatRaport.intNilaiRaport}</td>
                    <td>{riwayatRaport.strGradeRaport}</td>
                    <td>
                      {moment(riwayatRaport.dateTglSK).format("DD/MM/YYYY")}
                    </td>

                    <td>{riwayatRaport.strStatus}</td>
                    <td>
                      <a
                        href={riwayatRaport.url}
                        download
                        onClick={(e) => download(e)}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fa fa-download" />
                        Download Raport
                      </a>
                    </td>

                    <td>{riwayatRaport.strKomentar}</td>
                    <td>{riwayatRaport.pegawai.strUnit}</td>
                    <td>{riwayatRaport.pegawai.strNama}</td>
                 
                    <td>
                      <Link
                        to={`/riwayat-raport/edit/${riwayatRaport.uuid}`}
                        className="button is-small is-info"
                      >
                        Edit
                      </Link>
                      <button
                        onClick={() => deleteRiwayatRaport(riwayatRaport.uuid)}
                        className="button is-small is-danger"
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ) : (
                  <tr key={riwayatRaport.uuid}>
                    {index === 0}
                    <td>{index + 1}</td>
                    <td>{riwayatRaport.strSemester}</td>
                    <td>{riwayatRaport.intNilaiRaport}</td>
                    <td>{riwayatRaport.strGradeRaport}</td>
                    <td>
                      {moment(riwayatRaport.dateTglSK).format("DD/MM/YYYY")}
                    </td>

                    <td>{riwayatRaport.strStatus}</td>
                    <td>
                      <a
                        href={riwayatRaport.url}
                        download
                        onClick={(e) => download(e)}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fa fa-download" />
                        Download Raport
                      </a>
                    </td>
                    <td>{riwayatRaport.strKomentar}</td>
                    <td>{riwayatRaport.pegawai.strUnit}</td>
                    <td>{riwayatRaport.pegawai.strNama}</td>
                  </tr>
                )
              )}
            </tbody>
          )}

          {pegawai &&
            (pegawai.strRole === "kepsek" || pegawai.strRole === "staff TU") &&
            pegawai.strUnit === "PAUD IT Al Hasanah 1" && (
              <tbody>
                {riwayatRaports
                  .filter(
                    (riwayatRaport) =>
                      riwayatRaport.pegawai.strNama
                        .toLowerCase()
                        .includes(query) &&
                      riwayatRaport.pegawai.strUnit
                        .toLowerCase()
                        .includes(query2)
                  )
                  .map((riwayatRaport, index) =>
                    riwayatRaport.pegawai.strUnit === "PAUD IT Al Hasanah 1" ? (
                      <tr key={riwayatRaport.uuid}>
                        {index === 0}
                        <td>{index + 1}</td>
                        <td>{riwayatRaport.strSemester}</td>
                        <td>{riwayatRaport.intNilaiRaport}</td>
                        <td>{riwayatRaport.strGradeRaport}</td>
                        <td>
                          {moment(riwayatRaport.dateTglSK).format("DD/MM/YYYY")}
                        </td>

                        <td>{riwayatRaport.strStatus}</td>
                        <td>
                          <a
                            href={riwayatRaport.url}
                            download
                            onClick={(e) => download(e)}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <i className="fa fa-download" />
                            Download Raport
                          </a>
                        </td>

                        <td>{riwayatRaport.strKomentar}</td>

                        <td>{riwayatRaport.pegawai.strUnit}</td>
                        <td>{riwayatRaport.pegawai.strNama}</td>
                        <td>
                        <Link
                          to={`/riwayat-raport/edit/${riwayatRaport.uuid}`}
                          className="button is-small is-info"
                        >
                          Edit
                        </Link>

                        <button
                          onClick={() =>
                            deleteRiwayatRaport(riwayatRaport.uuid)
                          }
                          className="button is-small is-danger"
                        >
                          Delete
                        </button>
                      </td>
                      </tr>
                    ) : null
                  )}
              </tbody>
            )}

          {pegawai &&
            (pegawai.strRole === "kepsek" || pegawai.strRole === "staff TU") &&
            pegawai.strUnit === "PAUD IT Al Hasanah 2" && (
              <tbody>
                {riwayatRaports
                  .filter(
                    (riwayatRaport) =>
                      riwayatRaport.pegawai.strNama
                        .toLowerCase()
                        .includes(query) &&
                      riwayatRaport.pegawai.strUnit
                        .toLowerCase()
                        .includes(query2)
                  )
                  .map((riwayatRaport, index) =>
                    riwayatRaport.pegawai.strUnit === "PAUD IT Al Hasanah 2" ? (
                      <tr key={riwayatRaport.uuid}>
                        {index === 0}
                        <td>{index + 1}</td>
                        <td>{riwayatRaport.strSemester}</td>
                        <td>{riwayatRaport.intNilaiRaport}</td>
                        <td>{riwayatRaport.strGradeRaport}</td>
                        <td>
                          {moment(riwayatRaport.dateTglSK).format("DD/MM/YYYY")}
                        </td>

                        <td>{riwayatRaport.strStatus}</td>
                        <td>
                          <a
                            href={riwayatRaport.url}
                            download
                            onClick={(e) => download(e)}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <i className="fa fa-download" />
                            Download Raport
                          </a>
                        </td>

                        <td>{riwayatRaport.strKomentar}</td>
                        <td>{riwayatRaport.pegawai.strUnit}</td>
                        <td>{riwayatRaport.pegawai.strNama}</td>
                        <td>
                        <Link
                          to={`/riwayat-raport/edit/${riwayatRaport.uuid}`}
                          className="button is-small is-info"
                        >
                          Edit
                        </Link>

                        <button
                          onClick={() =>
                            deleteRiwayatRaport(riwayatRaport.uuid)
                          }
                          className="button is-small is-danger"
                        >
                          Delete
                        </button>
                      </td>
                      </tr>
                    ) : null
                  )}
              </tbody>
            )}

          {pegawai &&
            (pegawai.strRole === "kepsek" || pegawai.strRole === "staff TU") &&
            pegawai.strUnit === "SDIT Al Hasanah 1" && (
              <tbody>
                {riwayatRaports
                  .filter(
                    (riwayatRaport) =>
                      riwayatRaport.pegawai.strNama
                        .toLowerCase()
                        .includes(query) &&
                      riwayatRaport.pegawai.strUnit
                        .toLowerCase()
                        .includes(query2)
                  )
                  .map((riwayatRaport, index) =>
                    riwayatRaport.pegawai.strUnit === "SDIT Al Hasanah 1" ? (
                      <tr key={riwayatRaport.uuid}>
                        {index === 0}
                        {index === 0}
                        <td>{index + 1}</td>
                        <td>{riwayatRaport.strSemester}</td>
                        <td>{riwayatRaport.intNilaiRaport}</td>
                        <td>{riwayatRaport.strGradeRaport}</td>
                        <td>
                          {moment(riwayatRaport.dateTglSK).format("DD/MM/YYYY")}
                        </td>

                        <td>{riwayatRaport.strStatus}</td>
                        <td>
                          <a
                            href={riwayatRaport.url}
                            download
                            onClick={(e) => download(e)}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <i className="fa fa-download" />
                            Download Raport
                          </a>
                        </td>

                        <td>{riwayatRaport.strKomentar}</td>
                        <td>{riwayatRaport.pegawai.strUnit}</td>
                        <td>{riwayatRaport.pegawai.strNama}</td>
                        <td>
                        <Link
                          to={`/riwayat-raport/edit/${riwayatRaport.uuid}`}
                          className="button is-small is-info"
                        >
                          Edit
                        </Link>

                        <button
                          onClick={() =>
                            deleteRiwayatRaport(riwayatRaport.uuid)
                          }
                          className="button is-small is-danger"
                        >
                          Delete
                        </button>
                      </td>
                      </tr>
                    ) : null
                  )}
              </tbody>
            )}

          {pegawai &&
            (pegawai.strRole === "kepsek" || pegawai.strRole === "staff TU") &&
            pegawai.strUnit === "SDIT Al Hasanah 2" && (
              <tbody>
                {riwayatRaports
                  .filter(
                    (riwayatRaport) =>
                      riwayatRaport.pegawai.strNama
                        .toLowerCase()
                        .includes(query) &&
                      riwayatRaport.pegawai.strUnit
                        .toLowerCase()
                        .includes(query2)
                  )
                  .map((riwayatRaport, index) =>
                    riwayatRaport.pegawai.strUnit === "SDIT Al Hasanah 2" ? (
                      <tr key={riwayatRaport.uuid}>
                        {index === 0}
                        <td>{index + 1}</td>
                        <td>{riwayatRaport.strSemester}</td>
                        <td>{riwayatRaport.intNilaiRaport}</td>
                        <td>{riwayatRaport.strGradeRaport}</td>
                        <td>
                          {moment(riwayatRaport.dateTglSK).format("DD/MM/YYYY")}
                        </td>

                        <td>{riwayatRaport.strStatus}</td>
                        <td>
                          <a
                            href={riwayatRaport.url}
                            download
                            onClick={(e) => download(e)}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <i className="fa fa-download" />
                            Download Raport
                          </a>
                        </td>

                        <td>{riwayatRaport.strKomentar}</td>
                        <td>{riwayatRaport.pegawai.strUnit}</td>
                        <td>{riwayatRaport.pegawai.strNama}</td>
                        <td>
                        <Link
                          to={`/riwayat-raport/edit/${riwayatRaport.uuid}`}
                          className="button is-small is-info"
                        >
                          Edit
                        </Link>

                        <button
                          onClick={() =>
                            deleteRiwayatRaport(riwayatRaport.uuid)
                          }
                          className="button is-small is-danger"
                        >
                          Delete
                        </button>
                      </td>
                      </tr>
                    ) : null
                  )}
              </tbody>
            )}

          {pegawai &&
            (pegawai.strRole === "kepsek" || pegawai.strRole === "staff TU") &&
            pegawai.strUnit === "SMPI Al Hasanah" && (
              <tbody>
                {riwayatRaports
                  .filter(
                    (riwayatRaport) =>
                      riwayatRaport.pegawai.strNama
                        .toLowerCase()
                        .includes(query) &&
                      riwayatRaport.pegawai.strUnit
                        .toLowerCase()
                        .includes(query2)
                  )
                  .map((riwayatRaport, index) =>
                    riwayatRaport.pegawai.strUnit === "SMPI Al Hasanah" ? (
                      <tr key={riwayatRaport.uuid}>
                        {index === 0}
                        <td>{index + 1}</td>
                        <td>{riwayatRaport.strSemester}</td>
                        <td>{riwayatRaport.intNilaiRaport}</td>
                        <td>{riwayatRaport.strGradeRaport}</td>
                        <td>
                          {moment(riwayatRaport.dateTglSK).format("DD/MM/YYYY")}
                        </td>

                        <td>{riwayatRaport.strStatus}</td>
                        <td>
                          <a
                            href={riwayatRaport.url}
                            download
                            onClick={(e) => download(e)}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <i className="fa fa-download" />
                            Download Raport
                          </a>
                        </td>

                        <td>{riwayatRaport.strKomentar}</td>
                        <td>{riwayatRaport.pegawai.strUnit}</td>
                        <td>{riwayatRaport.pegawai.strNama}</td>
                        <td>
                        <Link
                          to={`/riwayat-raport/edit/${riwayatRaport.uuid}`}
                          className="button is-small is-info"
                        >
                          Edit
                        </Link>

                        <button
                          onClick={() =>
                            deleteRiwayatRaport(riwayatRaport.uuid)
                          }
                          className="button is-small is-danger"
                        >
                          Delete
                        </button>
                      </td>
                      </tr>
                    ) : null
                  )}
              </tbody>
            )}

          {pegawai &&
            (pegawai.strRole === "kepsek" || pegawai.strRole === "staff TU") &&
            pegawai.strUnit === "SMAIT Al Hasanah" && (
              <tbody>
                {riwayatRaports
                  .filter(
                    (riwayatRaport) =>
                      riwayatRaport.pegawai.strNama
                        .toLowerCase()
                        .includes(query) &&
                      riwayatRaport.pegawai.strUnit
                        .toLowerCase()
                        .includes(query2)
                  )
                  .map((riwayatRaport, index) =>
                    riwayatRaport.pegawai.strUnit === "SMAIT Al Hasanah" ? (
                      <tr key={riwayatRaport.uuid}>
                        {index === 0}
                        <td>{index + 1}</td>
                        <td>{riwayatRaport.strSemester}</td>
                        <td>{riwayatRaport.intNilaiRaport}</td>
                        <td>{riwayatRaport.strGradeRaport}</td>
                        <td>
                          {moment(riwayatRaport.dateTglSK).format("DD/MM/YYYY")}
                        </td>

                        <td>{riwayatRaport.strStatus}</td>
                        <td>
                          <a
                            href={riwayatRaport.url}
                            download
                            onClick={(e) => download(e)}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <i className="fa fa-download" />
                            Download Raport
                          </a>
                        </td>

                        <td>{riwayatRaport.strKomentar}</td>
                        <td>{riwayatRaport.pegawai.strUnit}</td>
                        <td>{riwayatRaport.pegawai.strNama}</td>
                        <td>
                        <Link
                          to={`/riwayat-raport/edit/${riwayatRaport.uuid}`}
                          className="button is-small is-info"
                        >
                          Edit
                        </Link>

                        <button
                          onClick={() =>
                            deleteRiwayatRaport(riwayatRaport.uuid)
                          }
                          className="button is-small is-danger"
                        >
                          Delete
                        </button>
                      </td>
                      </tr>
                    ) : null
                  )}
              </tbody>
            )}

          {pegawai &&
            (pegawai.strRole === "kepsek" || pegawai.strRole === "staff TU") &&
            pegawai.strUnit === "Pondok Pesantren" && (
              <tbody>
                {riwayatRaports
                  .filter(
                    (riwayatRaport) =>
                      riwayatRaport.pegawai.strNama
                        .toLowerCase()
                        .includes(query) &&
                      riwayatRaport.pegawai.strUnit
                        .toLowerCase()
                        .includes(query2)
                  )
                  .map((riwayatRaport, index) =>
                    riwayatRaport.pegawai.strUnit === "Pondok Pesantren" ? (
                      <tr key={riwayatRaport.uuid}>
                        {index === 0}
                        {index === 0}
                        <td>{index + 1}</td>
                        <td>{riwayatRaport.strSemester}</td>
                        <td>{riwayatRaport.intNilaiRaport}</td>
                        <td>{riwayatRaport.strGradeRaport}</td>
                        <td>
                          {moment(riwayatRaport.dateTglSK).format("DD/MM/YYYY")}
                        </td>

                        <td>{riwayatRaport.strStatus}</td>
                        <td>
                          <a
                            href={riwayatRaport.url}
                            download
                            onClick={(e) => download(e)}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <i className="fa fa-download" />
                            Download Raport
                          </a>
                        </td>

                        <td>{riwayatRaport.strKomentar}</td>
                        <td>{riwayatRaport.pegawai.strUnit}</td>
                        <td>{riwayatRaport.pegawai.strNama}</td>
                        <td>
                        <Link
                          to={`/riwayat-raport/edit/${riwayatRaport.uuid}`}
                          className="button is-small is-info"
                        >
                          Edit
                        </Link>

                        <button
                          onClick={() =>
                            deleteRiwayatRaport(riwayatRaport.uuid)
                          }
                          className="button is-small is-danger"
                        >
                          Delete
                        </button>
                      </td>
                      </tr>
                    ) : null
                  )}
              </tbody>
            )}

          {pegawai &&
            (pegawai.strRole === "admin" ||
              pegawai.strRole === "kabid kepegawaian") && (
              <tbody>
                {riwayatRaports
                  .filter(
                    (riwayatRaport) =>
                      riwayatRaport.pegawai.strNama
                        .toLowerCase()
                        .includes(query) &&
                      riwayatRaport.pegawai.strUnit
                        .toLowerCase()
                        .includes(query2)
                  )
                  .map((riwayatRaport, index) => (
                    <tr key={riwayatRaport.uuid}>
                      {index === 0}
                      <td>{index + 1}</td>
                      <td>{riwayatRaport.strSemester}</td>
                      <td>{riwayatRaport.intNilaiRaport}</td>
                      <td>{riwayatRaport.strGradeRaport}</td>
                      <td>
                        {moment(riwayatRaport.dateTglSK).format("DD/MM/YYYY")}
                      </td>

                      <td>{riwayatRaport.strStatus}</td>
                      <td>
                        <a
                          href={riwayatRaport.url}
                          download
                          onClick={(e) => download(e)}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i className="fa fa-download" />
                          Download Raport
                        </a>
                      </td>

                      <td>{riwayatRaport.strKomentar}</td>
                      <td>{riwayatRaport.pegawai.strUnit}</td>
                      <td>{riwayatRaport.pegawai.strNama}</td>

                      <td>
                        <Link
                          to={`/riwayat-raport/edit/${riwayatRaport.uuid}`}
                          className="button is-small is-info"
                        >
                          Edit
                        </Link>

                        <button
                          onClick={() =>
                            deleteRiwayatRaport(riwayatRaport.uuid)
                          }
                          className="button is-small is-danger"
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            )}
        </table>
      </div>
    </div>
  );
};

export default RiwayatRaportList;
