import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { useSelector } from "react-redux";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import "./globalList.scss";
import Url from "../global";
import * as FaIcons from "react-icons/fa";

const RiwayatJabatanList = () => {
  const [riwayatJabatans, setRiwayatJabatan] = useState([]);
  const { pegawai } = useSelector((state) => state.auth);
  const [query, setQuery] = useState("");
  const [query2, setQuery2] = useState("");
  const [order, setOrder] = useState("ASC");

  const sorting = (col) => {
    if (order === "ASC") {
      const sorted = [...riwayatJabatans].sort((a, b) =>
        a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
      );
      setRiwayatJabatan(sorted);
      setOrder("DSC");
    }
    if (order === "DSC") {
      const sorted = [...riwayatJabatans].sort((a, b) =>
        a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
      );
      setRiwayatJabatan(sorted);
      setOrder("ASC");
    }
  };

  useEffect(() => {
    getRiwayatJabatan();
  }, []);

  const getRiwayatJabatan = async () => {
    const response = await axios.get(`${Url}/riwayat_jabatans`);
    setRiwayatJabatan(response.data);
  };

  const deleteRiwayatJabatan = async (riwayatJabatanId) => {
    await axios.delete(`${Url}/riwayat_jabatans/${riwayatJabatanId}`, {
      credentials: "include",
    });
    getRiwayatJabatan();
  };

  const download = (e) => {
    console.log(e.target.href);
    fetch(e.target.href, {
      method: "GET",
      headers: {},
    })
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
          const blob = new Blob([buffer]);
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Sertifikat Jabatan.pdf"); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <h1 className="title">Riwayat Jabatan</h1>
      <h2 className="subtitle">List Of Riwayat Jabatan</h2>

      {pegawai &&
        (pegawai.strRole === "user" ||
          pegawai.strRole === "kepsek" ||
          pegawai.strRole === "staff TU" ||
          pegawai.strRole === "kabid kepegawaian" ||
          pegawai.strRole === "admin") && (
          <Link to="/riwayat-jabatan/add" className="button is-primary mb-2">
            Add New Riwayat Jabatan
          </Link>
        )}

      <ReactHTMLTableToExcel
        id="test-table-xls-button"
        className="download-table-xls-button"
        table="table-to-xls"
        filename="Table Riwayat Jabatan"
        sheet="tablexls"
        buttonText="Download as XLS"
      />
      <br />
      {pegawai &&
        (pegawai.strRole === "admin" ||
          pegawai.strRole === "kepsek" ||
          pegawai.strRole === "staff TU") && <label> Masukan Nama </label>}
      {pegawai &&
        (pegawai.strRole === "admin" ||
          pegawai.strRole === "kepsek" ||
          pegawai.strRole === "kabid kepegawaian" ||
          pegawai.strRole === "staff TU") && (
          <input
            className="search"
            placeholder="Cari Nama Individu"
            onChange={(e) => setQuery(e.target.value.toLowerCase())}
          />
        )}
      {pegawai &&
        (pegawai.strRole === "admin" ||
          pegawai.strRole === "kepsek" ||
          pegawai.strRole === "kabid kepegawaian" ||
          pegawai.strRole === "staff TU") && (
          <label className="label-unit">Masukan Unit </label>
        )}

      {pegawai &&
        (pegawai.strRole === "admin" ||
          pegawai.strRole === "kepsek" ||
          pegawai.strRole === "kabid kepegawaian" ||
          pegawai.strRole === "staff TU") && (
          <input
            className="search"
            placeholder="Cari Berdasarkan Unit"
            onChange={(e) => setQuery2(e.target.value.toLowerCase())}
          />
        )}

      <div className="table__wrapper_Scroll_Log">
        <table
          className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth"
          id="table-to-xls"
        >
          <thead>
            <tr>
              <th className="has-text-centered">No</th>
              <th className="has-text-centered">Jabatan</th>
              <th className="has-text-centered">Tanggal SK</th>
              {/* <th className="has-text-centered">Masa Kerja</th> */}
              <th
                className="has-text-centered"
                onClick={() => sorting("strStatus")}
              >
                Status <FaIcons.FaSort />
              </th>
              <th className="has-text-centered">Sertifikat Jabatan</th>
              <th className="has-text-centered">komentar</th>
              <th className="has-text-centered">Created by </th>
              <th className="has-text-centered">Unit</th>
              <th className="has-text-centered">Action</th>
            </tr>
          </thead>

          {pegawai &&
            (pegawai.strRole === "admin" ||
              pegawai.strRole === "kabid kepegawaian") && (
              <tbody>
                {riwayatJabatans
                  .filter(
                    (riwayatJabatan) =>
                      riwayatJabatan.pegawai.strNama
                        .toLowerCase()
                        .includes(query) &&
                      riwayatJabatan.pegawai.strUnit
                        .toLowerCase()
                        .includes(query2)
                  )
                  .map((riwayatJabatan, index) => (
                    <tr key={[riwayatJabatan.uuid]}>
                      {index === 0}
                      <td>{index + 1}</td>
                      <td>{riwayatJabatan.strJabatan}</td>
                      <td>
                        {moment(riwayatJabatan.dateTglSK).format("DD/MM/YYYY")}
                      </td>
                      {/* <td>{riwayatJabatan.strMasaKerja}</td> */}
                      <td>{riwayatJabatan.strStatus}</td>
                      <td>
                        <a
                          href={riwayatJabatan.url}
                          download
                          onClick={(e) => download(e)}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i className="fa fa-download" />
                          Sertifikat Jabatan
                        </a>
                      </td>
                      <td>{riwayatJabatan.strKomentar}</td>
                      <td>{riwayatJabatan.pegawai.strNama}</td>
                      <td>{riwayatJabatan.pegawai.strUnit}</td>

                      <td>
                        <Link
                          to={`/riwayat-jabatan/edit/${riwayatJabatan.uuid}`}
                          className="button is-small is-info"
                        >
                          Edit
                        </Link>
                        <button
                          onClick={() =>
                            deleteRiwayatJabatan(riwayatJabatan.uuid)
                          }
                          className="button is-small is-danger"
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            )}

          {pegawai && pegawai.strRole === "user" && (
            <tbody>
              {riwayatJabatans.map((riwayatJabatan, index) =>
                riwayatJabatan.strStatus === "Pengajuan Sertifikat Jabatan" ||
                riwayatJabatan.strStatus === "Ditolak Kabid Kepegawaian" ? (
                  <tr key={[riwayatJabatan.uuid]}>
                    {index === 0}
                    <td>{index + 1}</td>
                    <td>{riwayatJabatan.strJabatan}</td>
                    <td>
                      {moment(riwayatJabatan.dateTglSK).format("DD/MM/YYYY")}
                    </td>
                    {/* <td>{riwayatJabatan.strMasaKerja}</td> */}
                    <td>{riwayatJabatan.strStatus}</td>
                    <td>
                      <a
                        href={riwayatJabatan.url}
                        download
                        onClick={(e) => download(e)}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fa fa-download" />
                        Sertifikat Jabatan
                      </a>
                    </td>
                    <td>{riwayatJabatan.strKomentar}</td>
                    <td>{riwayatJabatan.pegawai.strNama}</td>
                    <td>{riwayatJabatan.pegawai.strUnit}</td>

                    <td>
                      <Link
                        to={`/riwayat-jabatan/edit/${riwayatJabatan.uuid}`}
                        className="button is-small is-info"
                      >
                        Edit
                      </Link>
                      <button
                        onClick={() =>
                          deleteRiwayatJabatan(riwayatJabatan.uuid)
                        }
                        className="button is-small is-danger"
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ) : (
                  <tr key={[riwayatJabatan.uuid]}>
                    {index === 0}
                    <td>{index + 1}</td>
                    <td>{riwayatJabatan.strJabatan}</td>
                    <td>
                      {moment(riwayatJabatan.dateTglSK).format("DD/MM/YYYY")}
                    </td>
                    {/* <td>{riwayatJabatan.strMasaKerja}</td> */}
                    <td>{riwayatJabatan.strStatus}</td>
                    <td>
                      <a
                        href={riwayatJabatan.url}
                        download
                        onClick={(e) => download(e)}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fa fa-download" />
                        Sertifikat Jabatan
                      </a>
                    </td>
                    <td>{riwayatJabatan.strKomentar}</td>
                    <td>{riwayatJabatan.pegawai.strNama}</td>
                    <td>{riwayatJabatan.pegawai.strUnit}</td>
                    <td>-</td>
                  </tr>
                )
              )}
            </tbody>
          )}
          {pegawai &&
            (pegawai.strRole === "kepsek" || pegawai.strRole === "staff TU") &&
            pegawai.strUnit === "PAUD IT Al Hasanah 1" && (
              <tbody>
                {riwayatJabatans
                  .filter(
                    (riwayatJabatan) =>
                      riwayatJabatan.pegawai.strNama
                        .toLowerCase()
                        .includes(query) &&
                      riwayatJabatan.pegawai.strUnit
                        .toLowerCase()
                        .includes(query2)
                  )
                  .map((riwayatJabatan, index) =>
                    riwayatJabatan.pegawai.strUnit ===
                    "PAUD IT Al Hasanah 1" ? (
                      <tr key={[riwayatJabatan.uuid]}>
                        <td>{index}</td>
                        <td>{riwayatJabatan.strJabatan}</td>
                        <td>
                          {moment(riwayatJabatan.dateTglSK).format(
                            "DD/MM/YYYY"
                          )}
                        </td>
                        {/* <td>{riwayatJabatan.strMasaKerja}</td> */}
                        <td>{riwayatJabatan.strStatus}</td>
                        <td>
                          {/* <a
                            href={riwayatJabatan.url}
                            download
                            onClick={(e) => download(e)}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <i className="fa fa-download" />
                            Sertifikat Jabatan
                          </a> */} -
                        </td>
                        <td>{riwayatJabatan.strKomentar}</td>
                        <td>{riwayatJabatan.pegawai.strNama}</td>
                        <td>{riwayatJabatan.pegawai.strUnit}</td>

                        <td>
                          {/* <Link
                            to={`/riwayat-jabatan/edit/${riwayatJabatan.uuid}`}
                            className="button is-small is-info"
                          >
                            Edit
                          </Link> */}
                          <button
                            onClick={() =>
                              deleteRiwayatJabatan(riwayatJabatan.uuid)
                            }
                            className="button is-small is-danger"
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ) : null
                  )}
              </tbody>
            )}

          {pegawai &&
            (pegawai.strRole === "kepsek" || pegawai.strRole === "staff TU") &&
            pegawai.strUnit === "PAUD IT Al Hasanah 2" && (
              <tbody>
                {riwayatJabatans
                  .filter(
                    (riwayatJabatan) =>
                      riwayatJabatan.pegawai.strNama
                        .toLowerCase()
                        .includes(query) &&
                      riwayatJabatan.pegawai.strUnit
                        .toLowerCase()
                        .includes(query2)
                  )
                  .map((riwayatJabatan, index) =>
                    riwayatJabatan.pegawai.strUnit ===
                    "PAUD IT Al Hasanah 2" ? (
                      <tr key={[riwayatJabatan.uuid]}>
                        {index === 0}
                        <td>{index}</td>
                        <td>{riwayatJabatan.strJabatan}</td>
                        <td>
                          {moment(riwayatJabatan.dateTglSK).format(
                            "DD/MM/YYYY"
                          )}
                        </td>
                        {/* <td>{riwayatJabatan.strMasaKerja}</td> */}
                        <td>{riwayatJabatan.strStatus}</td>
                        <td>
                          {/* <a
                            href={riwayatJabatan.url}
                            download
                            onClick={(e) => download(e)}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <i className="fa fa-download" />
                            Sertifikat Jabatan
                          </a> */} -
                        </td>
                        <td>{riwayatJabatan.strKomentar}</td>
                        <td>{riwayatJabatan.pegawai.strNama}</td>
                        <td>{riwayatJabatan.pegawai.strUnit}</td>

                        <td>
                          {/* <Link
                            to={`/riwayat-jabatan/edit/${riwayatJabatan.uuid}`}
                            className="button is-small is-info"
                          >
                            Edit
                          </Link> */}
                          <button
                            onClick={() =>
                              deleteRiwayatJabatan(riwayatJabatan.uuid)
                            }
                            className="button is-small is-danger"
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ) : null
                  )}
              </tbody>
            )}
          {pegawai &&
            (pegawai.strRole === "kepsek" || pegawai.strRole === "staff TU") &&
            pegawai.strUnit === "SD IT Al Hasanah 1" && (
              <tbody>
                {riwayatJabatans
                  .filter(
                    (riwayatJabatan) =>
                      riwayatJabatan.pegawai.strNama
                        .toLowerCase()
                        .includes(query) &&
                      riwayatJabatan.pegawai.strUnit
                        .toLowerCase()
                        .includes(query2)
                  )
                  .map((riwayatJabatan, index) =>
                    riwayatJabatan.pegawai.strUnit === "SD IT Al Hasanah 1" ? (
                      <tr key={[riwayatJabatan.uuid]}>
                        {index === 0}
                        <td>{index}</td>
                        <td>{riwayatJabatan.strJabatan}</td>
                        <td>
                          {moment(riwayatJabatan.dateTglSK).format(
                            "DD/MM/YYYY"
                          )}
                        </td>
                        {/* <td>{riwayatJabatan.strMasaKerja}</td> */}
                        <td>{riwayatJabatan.strStatus}</td>
                        <td>
                          {/* <a
                            href={riwayatJabatan.url}
                            download
                            onClick={(e) => download(e)}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <i className="fa fa-download" />
                            Sertifikat Jabatan
                          </a> */} -
                        </td>
                        <td>{riwayatJabatan.strKomentar}</td>
                        <td>{riwayatJabatan.pegawai.strNama}</td>
                        <td>{riwayatJabatan.pegawai.strUnit}</td>

                        <td>
                          {/* <Link
                            to={`/riwayat-jabatan/edit/${riwayatJabatan.uuid}`}
                            className="button is-small is-info"
                          >
                            Edit
                          </Link> */}
                          <button
                            onClick={() =>
                              deleteRiwayatJabatan(riwayatJabatan.uuid)
                            }
                            className="button is-small is-danger"
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ) : null
                  )}
              </tbody>
            )}
          {pegawai &&
            (pegawai.strRole === "kepsek" || pegawai.strRole === "staff TU") &&
            pegawai.strUnit === "SDIT Al Hasanah 1" && (
              <tbody>
                {riwayatJabatans
                  .filter(
                    (riwayatJabatan) =>
                      riwayatJabatan.pegawai.strNama
                        .toLowerCase()
                        .includes(query) &&
                      riwayatJabatan.pegawai.strUnit
                        .toLowerCase()
                        .includes(query2)
                  )
                  .map((riwayatJabatan, index) =>
                    riwayatJabatan.pegawai.strUnit === "SDIT Al Hasanah 1" ? (
                      <tr key={[riwayatJabatan.uuid]}>
                        {index === 0}
                        <td>{index}</td>
                        <td>{riwayatJabatan.strJabatan}</td>
                        <td>
                          {moment(riwayatJabatan.dateTglSK).format(
                            "DD/MM/YYYY"
                          )}
                        </td>
                        {/* <td>{riwayatJabatan.strMasaKerja}</td> */}
                        <td>{riwayatJabatan.strStatus}</td>
                        <td>
                          {/* <a
                            href={riwayatJabatan.url}
                            download
                            onClick={(e) => download(e)}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <i className="fa fa-download" />
                            Sertifikat Jabatan
                          </a> */} -
                        </td>
                        <td>{riwayatJabatan.strKomentar}</td>
                        <td>{riwayatJabatan.pegawai.strNama}</td>
                        <td>{riwayatJabatan.pegawai.strUnit}</td>

                        <td>
                          {/* <Link
                            to={`/riwayat-jabatan/edit/${riwayatJabatan.uuid}`}
                            className="button is-small is-info"
                          >
                            Edit
                          </Link> */}
                          <button
                            onClick={() =>
                              deleteRiwayatJabatan(riwayatJabatan.uuid)
                            }
                            className="button is-small is-danger"
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ) : null
                  )}
              </tbody>
            )}
          {pegawai &&
            (pegawai.strRole === "kepsek" || pegawai.strRole === "staff TU") &&
            pegawai.strUnit === "SDIT Al Hasanah 2" && (
              <tbody>
                {riwayatJabatans
                  .filter(
                    (riwayatJabatan) =>
                      riwayatJabatan.pegawai.strNama
                        .toLowerCase()
                        .includes(query) &&
                      riwayatJabatan.pegawai.strUnit
                        .toLowerCase()
                        .includes(query2)
                  )
                  .map((riwayatJabatan, index) =>
                    riwayatJabatan.pegawai.strUnit === "SDIT Al Hasanah 2" ? (
                      <tr key={[riwayatJabatan.uuid]}>
                        {index === 0}
                        <td>{index}</td>
                        <td>{riwayatJabatan.strJabatan}</td>
                        <td>
                          {moment(riwayatJabatan.dateTglSK).format(
                            "DD/MM/YYYY"
                          )}
                        </td>
                        {/* <td>{riwayatJabatan.strMasaKerja}</td> */}
                        <td>{riwayatJabatan.strStatus}</td>
                        <td>
                          {/* <a
                            href={riwayatJabatan.url}
                            download
                            onClick={(e) => download(e)}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <i className="fa fa-download" />
                            Sertifikat Jabatan
                          </a> */} -
                        </td>
                        <td>{riwayatJabatan.strKomentar}</td>
                        <td>{riwayatJabatan.pegawai.strNama}</td>
                        <td>{riwayatJabatan.pegawai.strUnit}</td>

                        <td>
                          {/* <Link
                            to={`/riwayat-jabatan/edit/${riwayatJabatan.uuid}`}
                            className="button is-small is-info"
                          >
                            Edit
                          </Link> */}
                          <button
                            onClick={() =>
                              deleteRiwayatJabatan(riwayatJabatan.uuid)
                            }
                            className="button is-small is-danger"
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ) : null
                  )}
              </tbody>
            )}

          {pegawai &&
            (pegawai.strRole === "kepsek" || pegawai.strRole === "staff TU") &&
            pegawai.strUnit === "SMPI Al Hasanah" && (
              <tbody>
                {riwayatJabatans
                  .filter(
                    (riwayatJabatan) =>
                      riwayatJabatan.pegawai.strNama
                        .toLowerCase()
                        .includes(query) &&
                      riwayatJabatan.pegawai.strUnit
                        .toLowerCase()
                        .includes(query2)
                  )
                  .map((riwayatJabatan, index) =>
                    riwayatJabatan.pegawai.strUnit === "SMPI Al Hasanah" ? (
                      <tr key={[riwayatJabatan.uuid]}>
                        {index === 0}
                        <td>{index}</td>
                        <td>{riwayatJabatan.strJabatan}</td>
                        <td>
                          {moment(riwayatJabatan.dateTglSK).format(
                            "DD/MM/YYYY"
                          )}
                        </td>
                        {/* <td>{riwayatJabatan.strMasaKerja}</td> */}
                        <td>{riwayatJabatan.strStatus}</td>
                        <td>
                          {/* <a
                            href={riwayatJabatan.url}
                            download
                            onClick={(e) => download(e)}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <i className="fa fa-download" />
                            Sertifikat Jabatan
                          </a> */} -
                        </td>
                        <td>{riwayatJabatan.strKomentar}</td>
                        <td>{riwayatJabatan.pegawai.strNama}</td>
                        <td>{riwayatJabatan.pegawai.strUnit}</td>

                        <td>
                          {/* <Link
                            to={`/riwayat-jabatan/edit/${riwayatJabatan.uuid}`}
                            className="button is-small is-info"
                          >
                            Edit
                          </Link> */}
                          <button
                            onClick={() =>
                              deleteRiwayatJabatan(riwayatJabatan.uuid)
                            }
                            className="button is-small is-danger"
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ) : null
                  )}
              </tbody>
            )}
          {pegawai &&
            (pegawai.strRole === "kepsek" || pegawai.strRole === "staff TU") &&
            pegawai.strUnit === "SMAIT Al Hasanah" && (
              <tbody>
                {riwayatJabatans
                  .filter(
                    (riwayatJabatan) =>
                      riwayatJabatan.pegawai.strNama
                        .toLowerCase()
                        .includes(query) &&
                      riwayatJabatan.pegawai.strUnit
                        .toLowerCase()
                        .includes(query2)
                  )
                  .map((riwayatJabatan, index) =>
                    riwayatJabatan.pegawai.strUnit === "SMAIT Al Hasanah" ? (
                      <tr key={[riwayatJabatan.uuid]}>
                        {index === 0}
                        <td>{index}</td>
                        <td>{riwayatJabatan.strJabatan}</td>
                        <td>
                          {moment(riwayatJabatan.dateTglSK).format(
                            "DD/MM/YYYY"
                          )}
                        </td>
                        {/* <td>{riwayatJabatan.strMasaKerja}</td> */}
                        <td>{riwayatJabatan.strStatus}</td>
                        <td>
                          {/* <a
                            href={riwayatJabatan.url}
                            download
                            onClick={(e) => download(e)}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <i className="fa fa-download" />
                            Sertifikat Jabatan
                          </a> */} -
                        </td>
                        <td>{riwayatJabatan.strKomentar}</td>
                        <td>{riwayatJabatan.pegawai.strNama}</td>
                        <td>{riwayatJabatan.pegawai.strUnit}</td>

                        <td>
                          {/* <Link
                            to={`/riwayat-jabatan/edit/${riwayatJabatan.uuid}`}
                            className="button is-small is-info"
                          >
                            Edit
                          </Link> */}
                          <button
                            onClick={() =>
                              deleteRiwayatJabatan(riwayatJabatan.uuid)
                            }
                            className="button is-small is-danger"
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ) : null
                  )}
              </tbody>
            )}
          {pegawai &&
            (pegawai.strRole === "kepsek" || pegawai.strRole === "staff TU") &&
            pegawai.strUnit === "Pondok Pesantren" && (
              <tbody>
                {riwayatJabatans
                  .filter(
                    (riwayatJabatan) =>
                      riwayatJabatan.pegawai.strNama
                        .toLowerCase()
                        .includes(query) &&
                      riwayatJabatan.pegawai.strUnit
                        .toLowerCase()
                        .includes(query2)
                  )
                  .map((riwayatJabatan, index) =>
                    riwayatJabatan.pegawai.strUnit === "Pondok Pesantren" ? (
                      <tr key={[riwayatJabatan.uuid]}>
                        {index === 0}
                        <td>{index}</td>
                        <td>{riwayatJabatan.strJabatan}</td>
                        <td>
                          {moment(riwayatJabatan.dateTglSK).format(
                            "DD/MM/YYYY"
                          )}
                        </td>
                        {/* <td>{riwayatJabatan.strMasaKerja}</td> */}
                        <td>{riwayatJabatan.strStatus}</td>
                        <td>
                          {/* <a
                            href={riwayatJabatan.url}
                            download
                            onClick={(e) => download(e)}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <i className="fa fa-download" />
                            Sertifikat Jabatan
                          </a> */} -
                        </td>
                        <td>{riwayatJabatan.strKomentar}</td>
                        <td>{riwayatJabatan.pegawai.strNama}</td>
                        <td>{riwayatJabatan.pegawai.strUnit}</td>

                        <td>
                          {/* <Link
                            to={`/riwayat-jabatan/edit/${riwayatJabatan.uuid}`}
                            className="button is-small is-info"
                          >
                            Edit
                          </Link> */}
                          <button
                            onClick={() =>
                              deleteRiwayatJabatan(riwayatJabatan.uuid)
                            }
                            className="button is-small is-danger"
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ) : null
                  )}
              </tbody>
            )}
        </table>
      </div>
    </div>
  );
};

export default RiwayatJabatanList;
